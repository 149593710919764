import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";

const BASE = "/services/payments";
const QUOTE = BASE + "/quote";
const PAYMENT_RESOURCE = BASE + '/${paymentProcessor}' + '/${resource}';

/**
 * Returns neuron quotes.
 */
export function quoteAPI(callback, fallback) {
	request(QUOTE, GET, null, callback, fallback);
}

/**
 * @param {Object} pathVariables = {
 * 	paymentProcessor: String
 *  resource: String
 * }
 */
export function paymentsAPI(pathVariables, payload, callback) {
	const api = $replace(PAYMENT_RESOURCE, pathVariables);
	request(api, POST, payload, callback);
}