import React, {Fragment} from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";

export default class ChangeableShape extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			shapeKey: this.props.domkey,
			shapeName: this.props.shapeName,
			shapeImg: this.props.img,
			shapeImgAlt: this.props.imgAlt,
			description: this.props.description,
			staticPropertiesMap: this.props.staticProperties,
			alterablePropertiesMap: this.props.alterableProperties,
			shapePropertiesDOM: null,
			alterableShapePropertiesDOM: null
		};

		const properties = this.state.staticPropertiesMap[this.state.shapeKey];
		for (const [key, value] of Object.entries(properties)) {
			this[key] = React.createRef();
		}
		this.changeableInputValueRef = React.createRef();

		this.renderShapeProperties = this.renderShapeProperties.bind(this);
		this.renderModifiableProperties = this.renderModifiableProperties.bind(this);
		this.changeShapeMetadata = this.changeShapeMetadata.bind(this);
	}

	componentDidMount() {
		this.renderShapeProperties();
		this.renderModifiableProperties();
	}

	renderShapeProperties() {
		const properties = this.state.staticPropertiesMap[this.state.shapeKey];
		let propertyDOMs = [];
		for (const [key, value] of Object.entries(properties)) {
			propertyDOMs.push(
				<div key={key} className="shape-prop-count">
					<div className="prop-name">
						{messageMap(`shapeProperties.${key}`, "math")}
					</div>
					<div className="prop-value" ref={this[key]}>
						{value}
					</div>
				</div>
			);
		}

		this.setState({
			shapePropertiesDOM: propertyDOMs
		});
	}

	renderModifiableProperties() {
		if (this.state.alterablePropertiesMap) {
			let alterableProp = [];
			for (const [key, value] of Object.entries(this.state.alterablePropertiesMap)) {
				const minRange = value.range[0];
				const maxRange = value.range[1];

				alterableProp.push(
					<div key={key} className="changeable-property">
						{`Change ${key} count between ${minRange} and ${maxRange}: `}
						<input className="prop-change" type="number" min={minRange} max={maxRange} placeholder={minRange}
							onChange={e => this.changeShapeMetadata(e, key)} ref={this.changeableInputValueRef}></input>
						<div className="change-prop-container">
							<button className="chevron-up"
								onClick={e => this.changeShapeMetadata(null, key, value.range, "increase")} onKeyPress={e => this.changeShapeMetadata(null, key, value.range, "increase")}></button>
							<button className="chevron-down"
								onClick={e => this.changeShapeMetadata(null, key, value.range, "decrease")} onKeyPress={e =>this.changeShapeMetadata(null, key, value.range, "decrease")}></button>
						</div>
					</div>
				)
			}

			this.setState({
				alterableShapePropertiesDOM: alterableProp
			});
		}
	}

	changeShapeMetadata(e, propName, range, increaseOrDecrease) {
		let incrementedPropertyCount;
		if (e) {
			incrementedPropertyCount = e.target.value;
		}
		else {
			incrementedPropertyCount = this.changeableInputValueRef.current.value;
			incrementedPropertyCount = incrementedPropertyCount !== ""
				? (increaseOrDecrease === "increase" ? String(Number(incrementedPropertyCount) + 1) : String(Number(incrementedPropertyCount) - 1))
				: "3";
			if (incrementedPropertyCount > range[1] || incrementedPropertyCount < range[0]) {
				return;
			}
			this.changeableInputValueRef.current.value = incrementedPropertyCount;
		}
		const propMaps = this.state.alterablePropertiesMap[propName];
		const shapeKey = propMaps.keysMap[incrementedPropertyCount];

		this.setState({
			shapeName: propMaps.nameMap[shapeKey],
			shapeImg: propMaps.imgMap[shapeKey],
			shapeImgAlt: propMaps.imAltMap[shapeKey],
			description: propMaps.tipMap[incrementedPropertyCount]
		});

		const properties = this.state.staticPropertiesMap[shapeKey];
		for (const [key, value] of Object.entries(properties)) {
			this[key].current.innerText = value;
		}
	}

	render() {
		return (
			<Fragment>
				<div className="changeable-shape">
					<div className="shape">
						<img src={this.state.shapeImg} alt={this.state.shapeImgAlt}></img>
					</div>
					<div className="shape-information">
						<div className="shape-name">
							{this.state.shapeName}
						</div>
						<hr></hr>

						<div className="shape-prop-container">
							{this.state.shapePropertiesDOM}
						</div>

						{
							this.state.description
							? (
								<div className="description-container">
									<div className="description-label">
										{messageMap("descriptions.text", "math")}
									</div>
									{this.state.description}
								</div>
							)
							: null
						}
					</div>
				</div>
	
				{this.state.alterableShapePropertiesDOM}
			</Fragment>
		)
	}
}

ChangeableShape.propTypes = {
	domkey: PropTypes.string.isRequired,
	img: PropTypes.string.isRequired,
	imgAlt: PropTypes.string.isRequired,
	shapeName: PropTypes.string.isRequired,
	description: PropTypes.string,
	staticProperties: PropTypes.object,
	alterableProperties: PropTypes.object,
};

