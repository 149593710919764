export const headerTagMessageMap = {
	aboutUs: {
		title: "About Us | Exer Institute",
		description: "Discover what sets Exer Institute apart from the competition. Learn how we're revolutionizing online learning as we know. Become part of our movement that empowers educators around the world to create high quality learning content that touches and improves learner's lives. Based on the latest research in psychology, neuroscience, sociology, and pedagogy, we are ensuring that every learner gets the best chance to become whoever they want to be."
	},
	checkout: {
		title: "Checkout | Exer Institute",
		description: "Start the checkout process to purchase shiny neurons."
	},
	confirmation: {
		title: "Confirmation | Exer Institute",
		description: "Confirm the status of the checkout process."
	},
	editVideos: {
		title: "Edit Videos | Exer Institute",
		description: "Update your videos to ensure you reach your avid learners."
	},
	fourOfour: {
		title: "404 Not Found | Exer Institute",
		description: "Url not found. Please go back to the root page."
	},
	groups: {
		title: "Groups | Exer Institute",
		description: "Groups that you're a member of."
	},
	privacyPolicy: {
		title: "Privacy Policy | Exer Institute",
		description: "Exer Institute's user privacy policies and rights, including our content information."
	},
	profile: {
		title: "Profile | Exer Institute",
		description: "Update your username, email, password, and additional settings."
	},
	quotes: {
		title: "Purchase Shiny Neurons | Exer Institute",
		description: "Purchase shiny neurons to supercharge your journey."
	},
	termsNConditions: {
		title: "Terms & Conditions | Exer Institute",
		description: "Exer Institute's terms and conditions for platform use, including our contact information."
	},
	uploadVideo: {
		title: "Upload Video | Exer Institute",
		description: "Share your fact, insights, knowledge, and/or expertise with the world by uploading a video today."
	},
	videoList: {
		title: "Video List | Exer Institute",
		description: "Grow and expand your horizon by acquiring skills and knowledge from some of the best teachers and coaches in the world. Learn to your heart's content."
	},
	knowledgeQuests: {
		title: "Knowledge Quests | Exer Institute",
		description: "Go on exciting knowledge quests that expands your understanding of the world."
	},
	topics: {
		title: "Practice Category | Exer Institute",
		description: "Choose from our wide selection of exercises to grow those brain muscles. We're growing our practice questions for math, science, history, english, foreign language, economics, art, psychology, health & fitness, and much more."
	},
	topicsInfoList: {
		title: "Topics List | Exer Institute",
		description: "Choose from a growing selection of interactive articles demonstrating complex concepts in an easy-to-follow format."
	},
	practice: {
		title: "Practice Room | Exer Institute",
		description: "Grow those brain muscles while having fun with our gamified questions."
	}
};