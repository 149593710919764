import React, { Fragment } from 'react';
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";

import brainColorAsset from "assets/images/home/brain-color.png";
import coloredLettersAsset from "assets/images/home/coloredLetters.svg";

/**
 * @description branding component
 * @param {String} cta? custom call-to-action text
 * @param {Function} clickHandler action taken when user presses cta button
 */
export default function BrandIntroFull(props) {
	let actionDisplay = "action";
	const cta = props.cta ? props.cta : messageMap("home.action", "generic");

	if (localStorage.jwt && localStorage.ownerId) {
		actionDisplay = "action hidden";
	}

	return (
		<Fragment>
			<div className="introduction">
				<h1 className="title">
					EXER
				</h1>
				<h1 className="title-sub">
					INSTITUTE
				</h1>
				<div className="description description1">
					Learning
				</div>
				<div className="description colored-letters">
					<img src={coloredLettersAsset}></img>
				</div>
				{/* TODO: Only uncomment once we have classes */}
				{/* <div className="cta">
					Take your learning to the next level with our time flexible classes, which are 
					all created and taught using the latest research in education! Get involved in a
					growing community that is catered to provide a personalized approach to your learning.
				</div> */}
				<div className="cta">
					{messageMap("home.generalMarketing", "generic")}
				</div>

				<button className={actionDisplay} onClick={props.clickHandler} onKeyPress={props.clickHandler}>
					{cta}
				</button>
			</div>

			<div className="img-container">
					<img src={brainColorAsset} alt={messageMap("marketing.brain", "image")}></img>
			</div>
		</Fragment>
	);
}

BrandIntroFull.propTypes = {
	clickHandler: PropTypes.func.isRequired,
	cta: PropTypes.string
};