// Will be mainly used for the 'alt' attribute
export const imageMessageMap = {
	account: {
		profile: "User icon",
		profileView: "User account icon for viewing user's profile. Click to display a dropdown of additional user actions.",
		passwordLock: "Lock icon. Click to enter password.",
		username: "User icon. Click to enter username.",
		email: "User icon for email.",
		settings: "A settings icon. Click to go to user profile page.",
		logout: "Door with left-pointing arrow."
	},
	bookmark: {
		notSaved: "Bookmark icon. Click to add to list of bookmarked videos."
	},
	groups: {
		addMember: "Icon showing a plus character. Click to show a pop-up with the group invite code.",
		leaveGroup: "Icon showing an arrow next to a door. Click to leave group.",
		page: "Icon showing multiple people in a circle. Click to visit the groups page."
	},
	ellipsisVertical: "Vertical Ellipsis icon. Click to open up a menu.",
	information: "Information icon",
	moreInfo: "Information icon. Click for more information",
	mentalModelExample: "Mental model of the components of procedural system",
	presentation: "Presentation icon",
	viewers: "An icon of a monitor with the play button on the center",
	accessibility: "A handicap icon",
	miscellaneous: "An icon of a box containing different things to represent miscellaneous",
	alerts: {
		check: "Check alert icon",
		warning: "Warning alert icon",
		cancel: "Cancel alert icon",
		information: "Information alert icon",
		close: "Close icon. Click to close alert"
	},
	rowActions: {
		delete: "Delete icon showing a garbage can. Click to delete selected videos.",
		edit: "Edit icon showing a pencil on a paper. Click to edit a single video.",
		module: "Icon of a book showing a page  with a video icon. Click to group videos into a chapter playlist.",
		class: ""
	},
	closeThin: "Cross icon. Click to cancel",
	learn: {
		details: "Details icon. Three lines, with the bottom line half the length of the previous lines. Click to display video details.",
		editVideos: "Icon of a pencil on a paper. Click to go to your edit videos page.",
		videoList: "Company's logo inside a house icon. Click to go to video list page.",
		practice: "Game controller icon. Click to go to practice pages.",
		topicsInfoList: "Textbook icon. Click to go to textbook page.",
		networks: "Circles connected to each other. Click to go journeys page.",
		mentalModel: {
			text: "An interconnected node icon. Click to show video's mental model.",
			alt: "A mental model of the concepts discussed in the video"
		},
		play: "Icon for the play button of a video.",
		upload: "Icon of an arrow pointing upwards. Click to go to upload page.",
	},
	hamburger: "Hamburger icon for opening and closing side navigation.",
	xIcon: "Ex icon for closing side navigation",
	companyLogo: "Company logo of flower of life containing a gradient of all colors. Click to go back to homepage.",
	marketing: {
		brain: "Picture of a 2d brain made of circuits."
	},
	checkUsername: {
		check: "Check icon indicating that the username is available",
		cross: "Cross icon indicating that the username is not available"
	},
	knowledge: {
		locate: "Crosshair icon. Click to locate a node.",
		tree: "Nodes icon. Click to display a node's tree.",
		depth: "Double-sided vertical arrows. Click to set tree depth.",
		hamburger: "Hamburger icon. Click to show hidden menu.",
		view: {
			root: "Reset icon. Click to go back to the root view.",
			previous: "Left chevron icon. Click to go back to the previous view",
			deeper: "Plus icon. Click to go 1 level deeper into the current view.",
			higher: "Minus icon. Click to go 1 level higher from the current view."
		}
	},
	navigation: {
		first: "Icon for going to first page.",
		previous: "Icon for page left arrow (previous).",
		next: "Icon for page right arrow (previous).",
		last: "Icon for going to last page.",
		tableOfContents: "List icon. Click to show page's table of contents.",
		videoList: "Company's logo inside a house icon. Click to go to video list page.",
		practice: "Game controller icon. Click to go to practice pages.",
		topicsInfoList: "Textbook icon. Click to go to textbook page.",
		networks: "Circles connected to each other. Click to go journeys page."
	},
	subPages: {
		profile: {
			account: "Stick figure inside circle for account icon. Click to navigate to sub-page.",
			groups: "Three stick figures. Click to navigate to groups sub-page.",
			financials: "Bank icon. Click to navigate to financials sub-page."
		}
	},
	pageSwitching: {
		video: "Play icon. Click to go to corresponding video page on the same topic.",
		practice: "Game controller icon. Click to go to corresponding practice page on the same topic.",
		info: "Opened book icon. Click to go to corresponding information article on the same topic."
	},
	notifications: {
		bell: "Icon of a bell. Click to show notifications.",
		star: "A star icon used when announcing company promotions."
	},
	tbd: "Website under construction coming soon page. Toy robot looks at a set of hand wrenches for maintenance repairs and service works. Blue background.",
	search: "Magnifying glass icon. Click to search for videos and classes.",
	shinyNeurons: { 
		hexagonEnclosedShinyNeurons: " shiny neuron(s) enclosed in a hexagon" 
	},
	practice: {
		bag: "Bag",
		tutorial: "Red textbook.",
		answer: "A text bubble with the letter A at the center",
		hint: "A lightbulb",
		level: "Three stars with different colors"
	},
	videoUpload: {
		information: "Information icon. Click or hover to show tooltip."
	}
};