import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import CountableObjects from "assets/topicArticles/Math/components/templates/CountableObjects";

import thumbnailAsset from "assets/topicArticles/Math/Level 05 Math/I- Numbers and Ordering/thumbnailMedals.png";


export const orderingWithOrdinalsArticle = {
	id: "I: Numbers and Ordering_D. How Ordering Works and What are Ordinals",
	title: messageMap("orderingWithOrdinals.article.title", "math"),
	description: messageMap("orderingWithOrdinals.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("orderingWithOrdinals.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "html1", "html2", "html3", "html4", "html5", "html6", "html7", "html8", "html9", "html10"
	],
	datePublished: "2024-06-20",
	dateModified: "2024-06-20"
};

export const orderingWithOrdinalsArticleTexts = {
	text1: {
		text: messageMap("orderingWithOrdinals.orderingWithOrdinalsArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("orderingWithOrdinals.orderingWithOrdinalsArticleTexts.text2", "math"),
		type: DESCRIPTION
	},
};

export const orderingWithOrdinalsArticleHTMLs = {
	html1: (
		<CountableObjects key={1} objectCount={1} useOrdinals={true} />
	),
	html2: (
		<CountableObjects key={2} objectCount={2} useOrdinals={true} />
	),
	html3: (
		<CountableObjects key={3} objectCount={3} useOrdinals={true} />
	),
	html4: (
		<CountableObjects key={4} objectCount={4} useOrdinals={true} />
	),
	html5: (
		<CountableObjects key={5} objectCount={5} useOrdinals={true} />
	),
	html6: (
		<CountableObjects key={6} objectCount={6} useOrdinals={true} />
	),
	html7: (
		<CountableObjects key={7} objectCount={7} useOrdinals={true} />
	),
	html8: (
		<CountableObjects key={8} objectCount={8} useOrdinals={true} />
	),
	html9: (
		<CountableObjects key={9} objectCount={9} useOrdinals={true} />
	),
	html10: (
		<CountableObjects key={10} objectCount={10} useOrdinals={true} />
	)
};


