import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";

import SizableObjects from "assets/topicArticles/Math/components/templates/SizableObjects";


function ComparingSizableObjects(props) {

	const [leftValue, setLeftValue] = useState(48),
		[rightValue, setRightValue] = useState(48),
		[comparisonText, setComparisonText] = useState();

	useEffect(() => {
		setComparisonDOMText();
	}, [leftValue, rightValue]);

	function setComparisonDOMText() {
		setComparisonText(
			leftValue === rightValue
			? (props.sizing ? messageMap("comparisons.length.equal", "math") : messageMap("comparisons.size.equal", "math"))
			: (leftValue > rightValue || !rightValue
				? (props.sizing ? messageMap("comparisons.length.longer", "math") : messageMap("comparisons.size.bigger", "math"))
				: (props.sizing ? messageMap("comparisons.length.shorter", "math") :messageMap("comparisons.size.smaller", "math"))
			)
		);
	}

	function updateComparisonPhrase(newSize, position) {
		if (position === "left") {
			setLeftValue(newSize);
		}
		else if (position === "right") {
			setRightValue(newSize);
		}
	}

	return (
		<div className="comparing-countable-objects">
			<div className="sizable-objects-container">
				<SizableObjects changeHandler={updateComparisonPhrase} position="left"
					img={props.imgToResize} imgAlt={props.imgAlt} sizing={props.sizing} />
				<SizableObjects changeHandler={updateComparisonPhrase} position="right"
					img={props.imgToResize} imgAlt={props.imgAlt} sizing={props.sizing} />
			</div>
			<div className="comparison-text">
				{messageMap("comparisons.size.left", "math")}
				<div className="underline bold">
					{comparisonText}
				</div>
				{messageMap("comparisons.size.right", "math")}
			</div>
		</div>
	)
}

ComparingSizableObjects.propTypes = {
	imgToResize: PropTypes.string,
	imgAlt: PropTypes.string,
	sizing: PropTypes.string
};

export default ComparingSizableObjects;

