import messageMap from "Utilities/MessageMaps";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/environment/thumbnailSound.jpg";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";


export const environmentSound = {
	id: "I. Environment_0_1 How sounds affect our learning",
	title: messageMap("sound.article.title", "learningOptimization"),
	description: messageMap("sound.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("sound.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3",
		"text4", "text5",
		"text6", "text7", "text8", "text9", "text10",
		"text11", "text12",
		"text13", "text14", "text15",
		"text16", "text17", "text18",
	],
	datePublished: "2023-11-30",
	dateModified: "2024-06-21"
};

export const environmentSoundTexts = {
	text1: {
		text: messageMap("sound.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("sound.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("sound.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("sound.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text5: {
		text: messageMap("sound.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("sound.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text7: {
		text: messageMap("sound.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("sound.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("sound.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("sound.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("sound.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text12: {
		text: messageMap("sound.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("sound.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("sound.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text15: {
		text: messageMap("sound.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text17: {
		text: messageMap("sound.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text18: {
		text: messageMap("sound.sources.s2", "learningOptimization"),
		type: SOURCE
	}
};



