import React from 'react';
import PropTypes from "prop-types";

import Modal from "templates/Modal";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";


/**
 * @description custom modal for privacy policy
 * @param {Function} closeHandler close handler for modal
 */
export default function PrivacyPolicyModal(props) {
	const closeButtonStyle = {
		"position": "absolute",
		"marginLeft": "195px",
		"marginTop": "15px",
		"filter": "invert(1)"
	},
	modalContainerStyle = {
		"padding": "0px"
	};

	return (
		<Modal closeHandler={props.closeHandler} closeType={"xButton"}
					closeButtonStyle={closeButtonStyle} modalContainerStyle={modalContainerStyle}>
			<PrivacyPolicy contentType="modal"/>
		</Modal>
	);
}

PrivacyPolicyModal.propTypes = {
	closeHandler: PropTypes.func.isRequired
}