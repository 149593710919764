import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

import {useChangeDomByWindowWidth} from "Utilities/CustomHooks";
import messageMap from 'Utilities/MessageMaps';

import facebookLogoAsset from "assets/images/home/facebook.svg";
import linkedInLogoAsset from "assets/images/home/linkedIn.svg";
import twitterLogoAsset from "assets/images/home/twitter.svg";


function Footer () {
	let bottomFooter = useChangeDomByWindowWidth(
		1100, 
		<Fragment>
			<div className="copyright">
				&#169; {messageMap("footer.rights", "generic")}
			</div>

			<div className="legal">
				<Link className="terms" to="/terms-and-conditions">
					{messageMap("links.termsAndConditions", "button")}
				</Link>
				<Link className="terms" to="/privacy-policy">
					{messageMap("links.privacy", "button")}
				</Link>
			</div>
		</Fragment>,
		<Fragment>
			<div className="copyright">
				&#169; {messageMap("footer.rights", "generic")}
			</div>

			<div className="legal">
				<Link className="terms" to="/terms-and-conditions">
					{messageMap("links.termsAndConditions", "button")}
				</Link>
				<Link className="terms" to="/privacy-policy">
					{messageMap("links.privacy", "button")}
				</Link>
			</div>
		</Fragment>
	);

	let linkArr = makeFooterLinks({
		"About Us": "/about-us",
		// TODO: uncomment once these pages are fleshed out
		// "Careers": "careers",
		// "Contact Us": "contact-us",
		// "Feedback": "feedback",
		// "Help Center": "help-center"
	});

	return (
		<footer className="footer-container">
			<div className="info-links">
				{linkArr}
				
				<div className="social-media-links">
					<a target="_blank" href="https://www.facebook.com/ExerInstitute">
						<img src={facebookLogoAsset} alt="Facebook logo"></img>
					</a>
					<a target="_blank" href="https://www.linkedin.com/company/exerinstitute">
						<img src={linkedInLogoAsset} alt="LinkedIn logo"></img>
					</a>
					<a target="_blank" href="https://twitter.com/ExerInstitute">
						<img src={twitterLogoAsset} alt="Twitter logo"></img>
					</a>
				</div>
			</div>

			<div className="divider"></div>

			<div className="bottom-footer">
				<div className="copyright">
					&#169; {messageMap("footer.rights", "generic")}
				</div>

				<div className="legal">
					<Link className="terms" to="/terms-and-conditions">
						{messageMap("links.termsAndConditions", "button")}
					</Link>
					<Link className="terms" to="/privacy-policy">
						{messageMap("links.privacy", "button")}
					</Link>
				</div>
			</div>
		</footer>
	);
}

function makeFooterLinks (linkMap) {
	let linkArr = [],
			objKeys = Object.keys(linkMap);

	objKeys.forEach(key => {
		linkArr.push(
			<div key={key}>
				<Link className="top-footer-links" to={linkMap[key]}>
					{key}
				</Link>
			</div>
		);
	});

	return linkArr;
}

export default Footer;