import React, {useEffect, useState, useRef, Fragment} from 'react';
import PropTypes from "prop-types";

import CountableObjects from 'assets/topicArticles/Math/components/templates/CountableObjects';

import getMathJS, {getRandomNumberBetween, getMinMaxRangeFromDigitCount,
				flipCoinForPositiveOrNegativeNumber, rollNSidedDice,
				convertIntToSymbol} from "pages/Classes/Practice/utilities/MathUtilities";

import {CORRECT, WRONG} from "pages/Classes/Practice/constants/PracticeConstants";

/**
 * @param {Function} setAnswerCorrectness // function to pass if user's answer is right/wrong
 * @param {Function} setGenerateNewQuestionFunc // function to generate the next question
 * 
 * @param {String} optionsType // can be true-false, multiple choice, or fill-in-the-blank
 * @param {Boolean} usePictures? // default is false. If true, use pictures to display quantity
 * @param {Boolean} orientation? // default is horizontal. Only possible values: ["horizontal", "vertical"]
 * @param {Number} operandCount? // default is 2. If set, where the value can only be greater than 2,
 * automatically generate expressions with the specified number of operands
* @param {Number} operandDigitCount? // default is 1. If set, generates numbers with the specific digit count.
 * NOTE*: this doesn't include decimal digit count
 * @param {Number} decimalDigitCount? // default is 0. If set, includes additional decimal digits.
 * @param {Boolean} includeNegative? // default is false. If set, include negative integers
 * @param {Boolean} mixOperandDigitCount? // default is false. If true, expressions would include
 * operands with varying digit count. NOTE*: this doesn't include decimals
 * @param {Boolean} mixDecimalDigitCount? // default is false. If true, expressions would include
 * decimal operands with varying digit count
 * @param {Number} operatorSet? // default is 0. If set, 0 = ['+'], 1 = ['+', '-'], 2 = ['+', '-', '*'], 3 = ['+', '-', '*', '/']
 * @param {Number} operatorType? // default is 0. If set, 0 = '+', 1 = '-', 2 = '*', 3 = '/'
 * @param {Number} blankCount? // default is 1. If set, where the 1 <= value < operandCount, automatically
 * generate expressions where there is/are blank(s) to be filled by user. Value of 1, would always have a
 * blank after '=' sign, i.e. 1 + 1 = _
 * @param {Boolean} showExpressionSolution? // if true, expression includes answer
 * @param {Boolean} countable? // if true, allow number is modifiable
 */
function Arithmetic(props) {

	const math = getMathJS();

	const [expression, setExpression] = useState(),
		[updateCount, setUpdateCount] = useState(0);

	const userAnswerInputRef = useRef(),
				stringExpressionRef = useRef(),
				solvedProblemsRef = useRef([]),
				expressionOperandsRef = useRef([]);

	useEffect(() => {
		attachParentProps();
		const expressionElements = expressionOperandsRef.current.length ? expressionOperandsRef.current : generateArithmeticElements();
		const extractedExpression = extractExpression(...expressionElements);
		stringExpressionRef.current = extractedExpression;
		solvedProblemsRef.current.push(extractedExpression);

		if (props.showExpressionSolution) {
			const expressionAns = math.evaluate(extractedExpression);
			const operandArr = expressionElements[0];
			expressionElements[0][operandArr.length - 1] = expressionAns;
		}

		expressionOperandsRef.current = expressionElements;
		generateExpression(...expressionElements);
	}, [updateCount]);


	function updateOperands(newCount, position) {
		expressionOperandsRef.current[0][position] = Number(newCount);
		setUpdateCount(updateCount + 1);
	}

	function attachParentProps() {
		props.setGenerateNewQuestionFunc && props.setGenerateNewQuestionFunc(generateNewQuestion);
	}

	function generateNewQuestion() {
		userAnswerInputRef.current.value = null;

		const expressionElements = generateArithmeticElements();
		const extractedExpression = extractExpression(...expressionElements);
		stringExpressionRef.current = extractedExpression;
		solvedProblemsRef.current.push(extractedExpression);

		generateExpression(...expressionElements);
	}

	function generateArithmeticElements() {
		let operands = [];
		for (let i = 0; i < props.operandCount; ++i) {
			let numSign = props.includeNegative ? flipCoinForPositiveOrNegativeNumber() : 1;

			const integerRange = getMinMaxRangeFromDigitCount(props.operandDigitCount);
			const integerMin = props.mixOperandDigitCount ? 0 : integerRange[0];
			const randomWholeInteger = getRandomNumberBetween(integerMin, integerRange[1]);
			let operandNum = numSign === 0 ? -randomWholeInteger : randomWholeInteger;

			if (props.decimalDigitCount) {
				let decimalRange = getMinMaxRangeFromDigitCount(props.decimalDigitCount);
				const decimalMin = props.mixDecimalDigitCount ? 0 : decimalRange[0];
				const randomDecimalInteger = getRandomNumberBetween(decimalMin, decimalRange[1]);
				operandNum = Number(`${randomWholeInteger}.${randomDecimalInteger}`);
				operandNum = numSign === 0 ? -operandNum : operandNum;
			}

			operands.push(operandNum);
		}

		const operatorSet = props.operatorSet;
		let operators = [];
		if (props.operatorType !== -1) {
			operators.push(props.operatorType);
		}
		else {
			for (let i = 0; i < props.operandCount - 1; ++i) {
				operators.push(rollNSidedDice(operatorSet));
			}
		}
		// -1 = ['=']
		operators.push(-1);

		let finalOperands = [...operands];
		// if greater than 1, randomly replace operands with a blank space
		if (props.blankCount > 1 && props.blankCount < operands.length) {
			let removedIndexOperands = [];
			for (let i = 0; i < props.blankCount - 1; ++i) {
				let removedIndex = rollNSidedDice(finalOperands.length);

				while (removedIndexOperands.includes(removedIndex)) {
					removedIndex = rollNSidedDice(finalOperands.length);
				}
				
				removedIndexOperands.push(removedIndex);
				finalOperands[removedIndex] = "blank";
			}
		}
		finalOperands.push("blank");

		return [finalOperands, operators];
	}

	function extractExpression(operands, operators) {
		let expression = "";
		operands.forEach((el, ind) => {
			if (operators[ind] !== -1 && operators[ind] != null) {
				expression = expression + String(el) + convertIntToSymbol(operators[ind]);
			}
			else if (operators[ind] === -1) {
				expression = expression + String(el);
			}
		});

		return expression;
	}

	function generateExpression(operands, operators) {
		let problemDOM = [];

		if (props.orientation === "horizontal") {
			operands.forEach((el, ind) => {
				const isLastOperand = operands.length - 1 === ind ? true : false;
				const key = `${el}_${ind}`;
				const operatorDom = (
					typeof operators[ind] === "number"
					? (
						<div className="expression-element">
							{convertIntToSymbol(operators[ind])}
						</div>
					)
					: ""
				);

				problemDOM.push(
					<Fragment key={key}>
						<div className="expression-element">
							{
								typeof el === "string"
								? <input type="number" className="number-input" ref={userAnswerInputRef}
												onClick={solveExpression}
												onKeyPress={solveExpression}></input>
								: (
									props.usePictures ?
									(
										<CountableObjects key={el} objectCount={el} countable={isLastOperand ? false : props.countable}
											changeHandler={updateOperands} position={ind} widthClass="whole-width" />
									)
									: el
								)
							}
						</div>
						{operatorDom}
					</Fragment>
				);
			});
		}
		else if (props.orientation === "vertical") {
			const barWidth = props.operandDigitCount * 36 + 72;

			operands.forEach((el, ind) => {
				const key = `${el}_${ind}`;
				const prevOperator = operators[ind - 1];
				const convertedIntSymbol = convertIntToSymbol(prevOperator);
				const isEqualsSign = convertedIntSymbol === "=";
				const equalsSymbol = (
					isEqualsSign
					? <hr style={{width: `${barWidth}px`}}></hr>
					: convertedIntSymbol
				);
				const operatorDom = (
					typeof prevOperator === "number"
					? (
						<div className={isEqualsSign ? "expression-element bar" : "expression-element space"}>
							{equalsSymbol}
						</div>
					)
					: null
				);

				problemDOM.push(
					<Fragment key={key}>
						{operatorDom}
						<div className={
									operatorDom ?
									(typeof el !== "string" ? "expression-element inline space" : "expression-element inline")
									: (typeof el !== "string" ? "expression-element bar space" : "expression-element bar")
								}>
							{
								typeof el === "string"
								? <input type="number" className="number-input bordered" style={{width: `${barWidth + 36}px`}}
													onChange={solveExpression} ref={userAnswerInputRef}></input>
								: el
							}
						</div>
					</Fragment>
				);
			});
		}

		setExpression(
			<Fragment>
				{problemDOM}
			</Fragment>
		);
	}

	function solveExpression(event) {
		const actualAns = math.evaluate(stringExpressionRef.current);
		const userAns = Number(event.target.value);

		if (actualAns === userAns) {
			props.setAnswerCorrectness && props.setAnswerCorrectness(CORRECT);
		}
		else {
			props.setAnswerCorrectness && props.setAnswerCorrectness(WRONG);
		}
	}

	return (
		<div className={props.orientation === "vertical" ? "arithmetic-container vertical" : "arithmetic-container"}>
			{expression}
		</div>
	);
}


Arithmetic.defaultProps = {
	orientation: "horizontal",
	operandCount: 2,
	operandDigitCount: 1,
	decimalDigitCount: 0,
	mixOperandDigitCount: false,
	mixDecimalDigitCount: false,
	operatorSet: 0,
	operatorType: -1,
	blankCount: 1,
	showExpressionSolution: false,
	usePictures: false,
	countable: false
};

Arithmetic.propTypes = {
	orientation: PropTypes.string,
	operandCount: PropTypes.number,
	operandDigitCount: PropTypes.number,
	decimalDigitCount: PropTypes.number,
	mixOperandDigitCount: PropTypes.bool,
	mixDecimalDigitCount: PropTypes.bool,
	includeNegative: PropTypes.bool,
	operatorSet: PropTypes.number,
	operatorType: PropTypes.number,
	blankCount: PropTypes.number,
	showExpressionSolution: PropTypes.bool,
	usePictures: PropTypes.bool,
	countable: PropTypes.bool,

	// ref props to pass to parent
	setAnswerCorrectness: PropTypes.func,
	setGenerateNewQuestionFunc: PropTypes.func
};

export default Arithmetic;