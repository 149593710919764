import { environmentMessageMap } from "./environment/environmentMessageMap";
import { mentalityAndAttitudeMessageMap } from "./mentalityAndAttitude/mentalityAndAttitudeMessageMap";
import { wellBeingMessageMap } from "./wellBeing/wellBeingMessageMap";
import { learningMaterialsMessageMap } from "./learningMaterials.js/learningMaterialsMessageMap";
import { physiologyMessageMap } from "./physiology/physiologyMessageMap";

export const learningOptimizationMessageMap = {
	...environmentMessageMap,
	...mentalityAndAttitudeMessageMap,
	...wellBeingMessageMap,
	...learningMaterialsMessageMap,
	...physiologyMessageMap,
	sourceHeader: "Sources"
};