export const tooltipMessageMap = {
	editVideos: {
		edit: "Edit",
		delete: "Delete Permanently",
		module: "Bundle into a module",
		class: "Bundle into a class"
	},
	uploadVideos: {
		mentalModel: "A mental model (a picture of how your video's concepts tie together) helps viewers accelerate their learning by as much as 50%.",
		topic: `Assign a topic to your video to dynamically associate it with 
						practice problems, informational text, and a node in our knowledge quests
						in the future.`,
	}
};