/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";
import {modelValidator} from "Utilities/Validators/APIValidators";

const BASE = "/services/access";

const RELEASE_GET = BASE + "/release/get";
const BETA_KEY_GET = BASE + "/release/key/get/${email}";



/**
 * 
 * @param {Object} payload = ACCESS_VO
 */
export function getReleaseAPI(payload, callback) {
	request(RELEASE_GET, POST, payload, callback);
}

/**
 * @param {Object} payload = 
 */
export function getBetaKeyAPI(pathVariables, callback) {
	const api = $replace(BETA_KEY_GET, pathVariables);
	request(api, GET, null, callback);
}