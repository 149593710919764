import React, {useRef, Fragment} from 'react';

export function createOverviews(overviewArr) {
	let overviews = [];

	Array.isArray(overviewArr) &&	overviewArr.forEach(element => {
		const imgRef = useRef(null);
		const handler = element.clickHandler;

		overviews.push(
			<div key={element.icon} className="briefing-item" 
					onClick={() => handler(element.icon)} onKeyPress={() => handler(element.icon)}
					tabIndex={0}>
				<div className="hover-item">
					<img ref={imgRef} className="briefing-img" src={element.iconPath} alt={element.alt}></img>
					<div className="briefing-description">
						{element.description}
					</div>
				</div>
			</div>
		);
	});

	return overviews;
}

export function createOverviewActions(clickHandler, videoInfo, overviewActionsArr) {
	let overviewActions = [];

	Array.isArray(overviewActionsArr) && overviewActionsArr.forEach(el => {
		let imgDom;
		if (el.icon) {
			imgDom = (
				<img className="briefing-img"
						src={"/" + process.env.PUBLIC_URL + "icons/class/overview/" + el.icon + ".svg"} 
						alt={el.alt}></img>
			);
		}
		el.link ?
			overviewActions.push(
				<div key={el.label} tabIndex={0} onClick={() => clickHandler(el.link, el.id)}
																				onKeyPress={() => clickHandler(el.link, el.id)}>
					{imgDom}
					{el.label}
				</div>
			)
		:
		overviewActions.push(
			<button key={el.label}>
				{imgDom}
				{el.label}
			</button>
		);
	});

	return overviewActions;
}

export function createCourseDetails(clickHandler, popupHandler, classCat, detailArr) {
	const valueToPopupMap = {
		conceptMap: "node"
	};

	let details = [];

	Array.isArray(detailArr) &&	detailArr.forEach(element => {
		const elementDetails = element.details;
		let descriptions = [],
				firstDetail = elementDetails ? elementDetails[0] : null;

		if (firstDetail) {
			if (typeof firstDetail === "string") {
				elementDetails.forEach((value, index) => {
					descriptions.push(
						<div key={`${element.icon}-${index}`} className="detail-subheader">
							{value}
						</div>
					);
				});
			}
			else if (typeof firstDetail === "object") {
				elementDetails.forEach((value, index) => {
					let descriptionList = [];
					value.description.forEach((val, ind) => {
						if (val.includes("$")) {
							const extractedKey = val.substring(
								val.lastIndexOf("{") + 1, val.lastIndexOf("}")
							);
							val = (
								<span onClick={() => popupHandler(valueToPopupMap[extractedKey], classCat)} onKeyPress={() => popupHandler(valueToPopupMap[extractedKey], classCat)}
											className="popup-link" tabIndex={0} role="button">
									{value.subHeader.toLowerCase()}
								</span>
							)
						}
	
						descriptionList.push(
							<li key={`${element.icon}-description-${ind}`}>
								{val}
							</li>
						);
					});
	
					descriptions.push(
						<div key={`${element.icon}-${index}`}>
							<div className="detail-subheader">
								{value.subHeader}
							</div>
							<ul>
								{descriptionList}
							</ul>
						</div>
					);
				});
			}
		}
		// this means that we're parsing the instructor details
		else if (firstDetail == null) {
			let descriptionList = [];
			let description = [];

			if (element.icon === "instructor") {
				Array.isArray(elementDetails.credentials) && elementDetails.credentials.forEach((val, ind) => {
					val.description.forEach((val, index) => {
						descriptionList.push(
							<li key={`description-${index}`}>
								{val}
							</li>
						);
					});

					description.push(
						<Fragment key={`${val.subHeader}-${ind}`}>
							<div className="detail-subheader">
								{val.subHeader}
							</div>
							<div className="detail-explanation">
								<ul>
									{descriptionList}
								</ul>
							</div>
						</Fragment>
					);
				});
				descriptions.push(
					<div key={`${element.icon}`}>
						<div className="instructor-block">
							{elementDetails.name}
							<img className="instructor-img" src={"/" + process.env.PUBLIC_URL + "images/instructors/" + elementDetails.img} alt={`${elementDetails.name}'s picture`}></img>
						</div>
						{description}
					</div>
				);
			}
		}

		details.push(
			<div key={element.icon} className="detail-container">
				<div className="detail-header" role="button" tabIndex={0}
							onClick={() => clickHandler(element.ref, element.toggleRef)} onKeyPress={() => clickHandler(element.ref, element.toggleRef)}>
					<div className="detail-item">
						<div className="detail-img-container">
							<img className="detail-img" src={element.iconPath} alt={element.alt}></img>
						</div>
						<div className="detail-description">
							{element.description}
						</div>
						<div className="chevron-down" ref={element.toggleRef}></div>
					</div>
				</div>
				<div ref={element.ref} className="details">
					{element.children}
					{descriptions}
				</div>
			</div>
		);
	});

	return details;
}

export function sortResponse(objectArr) {
	for (var i = 0; i < objectArr.length; ++i) {
		let ts = objectArr[i].timestamp;
		ts = ts.substr(0, ts.indexOf("."));
		objectArr[i].timestamp = new Date(ts).getTime();
	}

	objectArr.sort(function(x, y) {
		return y.timestamp - x.timestamp;
	});

	return objectArr;
}