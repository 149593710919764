import React, { Fragment } from 'react';

import { ConnectedPayToWatchButton } from './PayToWatchButton';
import Card from "templates/cards/Card";

import messageMap from "Utilities/MessageMaps";

export function createVideoBlocks(videoList, clickHandler) {
	let videoDomUploadToDOMMap = {};
	let keys = [];
	let highestViews = 0;
	// Need to create a back-end job to find, among the million of videos, which has the highest views
	let videoWHighestViews = {};

	function clickHandlerWrapper(event, data, type) {
		if (data.price) {
			event.preventDefault();

			if ((event.target.className || "").startsWith("purchase-button")) {
				event.target.focus();
			}
			else {
				clickHandler(data, type);
			}
		}
	}

	Array.isArray(videoList) && videoList.forEach((videoData, index) => {
		if (videoData.downloadStatus === null && videoData.downloadTypeToFiles && videoData.downloadTypeToFiles["thumbnail"]) {
			const fileObj = videoData.downloadTypeToFiles["thumbnail"][0];
			let dataUrl = fileObj.file;
			let thumbNailDescription = videoData.thumbnailDescription ? videoData.thumbnailDescription : "";
			let localDate = new Date(videoData.uploadDate * 1000);
			localDate = localDate.toLocaleString(
				'en-US',
				{
					dateStyle: "short"
				}
			);

			const userName = videoData.uploaderUsername ? videoData.uploaderUsername : "unknown";
			const originalKey = videoData.title.replaceAll(" ", "_") + userName;
			let key = originalKey;
			let keyCounter = 0;

			while (keys.includes(key)) {
				key += "_" + keyCounter;
				++keyCounter;
			}
			keys.push(key);

			const viewCount = videoData.viewCount;
			if (viewCount > highestViews) {
				highestViews = viewCount;
				videoWHighestViews = (
					<div className="featured-video-container"
						onClick={(e) => clickHandlerWrapper(e, videoData, "video")}
						onKeyDown={(e) => clickHandlerWrapper(e, videoData, "video")}
						tabIndex={0} role="button">
						<div className="video-description">
							<div className="featured-label">
								{messageMap("video.listPage.featured", "generic")}
							</div>
							<div className="title-container">
								<div className="user-initial">
									{userName[0].toUpperCase()}
								</div>
								<div className="video-title">
									{videoData.title}
								</div>
							</div>
							<div className="video-uploader">
								{userName}
							</div>
							<div className="count-publish-date-container">
								<div className="video-view-count">
									{getViewCount(viewCount)}
								</div>
								<div className="video-since-published">
									{localDate}
								</div>
							</div>
						</div>
						<div className="thumbnail">
							<img className="image" role="button"
								alt={thumbNailDescription}
								src={dataUrl}></img>
							<div className="video-duration">
								{videoData.duration}
							</div>
						</div>
					</div>
				);
			}

			const href = "/learn?id=" + videoData.videoId + "&channel=" + videoData.uploaderUsername + "&title=" + videoData.title.replaceAll(" ", "_");
			const linkState = {
				videoId: videoData.videoId
			};

			const dom = (
				<Card key={key} domKey={key} href={href} linkState={linkState}
							clickHandler={(e) => clickHandlerWrapper(e, videoData, "video")}
							thumbnailSrc={dataUrl} thumbnailAlt={thumbNailDescription}
							duration={videoData.duration} title={videoData.title}
							creatorInitial={userName[0].toUpperCase()} creatorUsername={userName}
							viewCount={getViewCount(viewCount)} uploadTimestamp={localDate}>
					<ConnectedPayToWatchButton channel={userName} price={videoData.price}
						thumbnail={dataUrl} thumbnailDescription={thumbNailDescription}
						videoId={videoData.videoId} videoTitle={videoData.title}/>
				</Card>
			);

			if (videoDomUploadToDOMMap[videoData.uploadDate]) {
				let placeholderTimestamp = videoData.uploadDate + 1;
				while (videoDomUploadToDOMMap[placeholderTimestamp]) {
					++placeholderTimestamp;
				}
				videoDomUploadToDOMMap[placeholderTimestamp] = dom;
			}
			else {
				videoDomUploadToDOMMap[videoData.uploadDate] = dom;
			}
		}
	});

	let timestampKeys = Object.keys(videoDomUploadToDOMMap).sort().reverse();
	let first8Blocks = [];
	let remainingBlocks = [];
	for (var i = 0; i < timestampKeys.length; ++i) {
		if (i < 8) {
			first8Blocks.push(videoDomUploadToDOMMap[timestampKeys[i]]);
		}
		else {
			remainingBlocks.push(videoDomUploadToDOMMap[timestampKeys[i]]);
		}
	}

	return [
		(<Fragment>

			{/* Uncommenting for now. We'll require significant back-end work to make this performant*/}
			{/* {videoWHighestViews}
			<hr className="divider"></hr> */}
			{first8Blocks}
		</Fragment>),
		(<Fragment>
			{remainingBlocks}
		</Fragment>)
	];
}

export function createModuleBlocks(moduleList, clickHandler) {
	let moduleDomList = [];

	Array.isArray(moduleList) && moduleList.forEach(moduleData => {
		const userName = moduleData.creatorUsername ? moduleData.creatorUsername : "unknown";
		const originalKey = moduleData.playlistId + moduleData.creatorUsername;
		let localDate = new Date(moduleData.creationDate * 1000);
		localDate = localDate.toLocaleString(
			'en-US',
			{
				dateStyle: "short"
			}
		);

		const href = "/learn?id=" + moduleData.playlistId + "&channel=" + moduleData.creatorUsername + "&title=" + moduleData.playlistName.replaceAll(" ", "_") + "&module=true";
		const linkState = {
			moduleId: moduleData.playlistId,
			videoIds: moduleData.itemIds
		};

		moduleDomList.push(
			<Card key={originalKey} domKey={originalKey} isASet={true}
						href={href} linkState={linkState}
						clickHandler={() => clickHandler(moduleData, "module")}
						thumbnailSrc={moduleData.thumbnailFile} thumbnailAlt={moduleData.thumbnailDescription ? moduleData.thumbnailDescription : ""}
						duration={moduleData.totalDuration} title={moduleData.playlistName}
						creatorInitial={userName[0].toUpperCase()} creatorUsername={userName}
						uploadTimestamp={localDate}>
			</Card>
		);
	});

	return (
		<Fragment>
			{moduleDomList}
		</Fragment>
	);
}

export function getViewCount(viewCount) {
	if (viewCount === 0) {
		viewCount = null;
	}
	else if (viewCount < 1000) {
		viewCount = viewCount + ` ${messageMap("video.listPage.views", "generic")}`;
	}
	else if (viewCount < 1000000) {
		viewCount = (Math.round((viewCount / 1000) * 10) / 10) + `K ${messageMap("video.listPage.views", "generic")}`;
	}
	else if (viewCount < 1000000000) {
		viewCount = (Math.round((viewCount / 1000000) * 10) / 10) + `M ${messageMap("video.listPage.views", "generic")}`;
	}

	return viewCount;
}

export function getPriceInShinyNeurons(price, appendText) {
	return price != 0 ? Math.round(price * 50) + (appendText || 0) : "Free";
}

export function goToPageHandler(pageNumber, currentPage, totalPageCount, setCurrentPage) {
	if ((currentPage === 0 && pageNumber === "previous") || (currentPage === totalPageCount - 1 && pageNumber === "next")) {
		return;
	}

	let pageNumberToGet = 0;
	if (pageNumber === "previous" || pageNumber === "next") {
		if (pageNumber === "previous") {
			pageNumberToGet = currentPage - 1;
		}
		else {
			pageNumberToGet = currentPage + 1;
		}
	}
	else if (pageNumber === "first") {
		pageNumberToGet = 0;
	}
	else if (pageNumber === "last") {
		pageNumberToGet = totalPageCount - 1;
	}
	else {
		pageNumberToGet = pageNumber - 1;
	}
	setCurrentPage(pageNumberToGet);
}

/**
 * @description References:
 * 1.) https://developers.google.com/search/docs/appearance/structured-data/course#guidelines
 * 2.) https://schema.org/ItemList
 * @param {Object} apiResponse 
 * @param {String} itemType // can only be 'video' or 'module' for now
 * @returns 
 */
export function generateStructuredData(apiResponse, itemType) {
	let itemListElement = [];

	// Leaving this commented, since we're eventually going to support classes
	// Array.isArray(apiResponse) && apiResponse.forEach((data, index) => {
	// 	const entityTitle = itemType === "videos" ? data.title : data.playlistName;
	// 	const uploaderUsername = itemType === "videos" ? data.uploaderUsername : data.creatorUsername;
	// 	const entityId = itemType === "videos" ? data.videoId : data.playlistId;

	// 	itemListElement.push(
	// 		{
	// 			"@type": "ListItem",
	// 			"position": index + 1,
	// 			"item": {
	// 				"@type": "Course",
	// 				"url": "https://exerinstitute.com/learn?id=" + entityId + "&channel=" + uploaderUsername + "&title=" + entityTitle.replaceAll(" ", "_"),
	// 				"name": entityTitle,
	// 				"description": data.description,
	// 				"provider": {
	// 					"@type": "Independent",
	// 					"name": uploaderUsername
	// 				}
	// 			}
	// 		}
	// 	);
	// });

	return (
		JSON.stringify(
			{
				// "@context": "https://schema.org",
				// "@type": "ItemList",
				// "numberOfItems": apiResponse.length,
				// "itemListOrder": "Unordered",
				// "itemListElement": itemListElement
				"@context" : "https://schema.org",
				"@type" : "WebSite",
				"name" : "Exer Institute",
				"alternateName": "EI",
				"url" : "https://exerinstitute.com"
			}
		)
	);
}
