import React, { useState, useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';

import { paymentsAPI } from 'apis/controllers/PaymentsController';

import { PAYMENT_PROCESSORS } from 'Utilities/Constants/PaymentConstants';
import messageMap from 'Utilities/MessageMaps';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function StripeForm(props) {
  const [clientSecret, setClientSecret] = useState(""),
    [baseUrl, setBaseUrl] = useState(""),
    [isPromiseLoading, setIsPromiseLoading] = useState(false),
    [stripePromise, setStripePromise] = useState(null);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    layout: "accordion",
    clientSecret,
    appearance,
  };

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    setClientSecret(null);
    let payment = {
      paymentAmount: props.price,
      shinyNeuronsPurchased: props.quantity,
      identity: props.identityPayload
    };
    if (!isPromiseLoading) {
      setIsPromiseLoading(true);
      let pathVariables = {
        paymentProcessor: PAYMENT_PROCESSORS.STRIPE,
        resource: 'promise'
      };
      paymentsAPI(pathVariables, payment, (resp) => {
        setStripePromise(loadStripe(resp));
      });
    }
    let pathVariables = {
      paymentProcessor: PAYMENT_PROCESSORS.STRIPE,
      resource: 'intent'
    };
    paymentsAPI(pathVariables, payment,
      (resp) => {
        if (resp["clientSecret"]) {
          setClientSecret(resp["clientSecret"]);
        }
        if (resp["baseUrl"]) {
          setBaseUrl(resp["baseUrl"]);
        }
      }
    )
  }, [props.price, props.quantity]);

  return (
    <div className="stripe-form-container">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <StripeFormActual {...props} baseUrl={baseUrl} />
        </Elements>)
      }
    </div>
  );
};

function StripeFormActual(props) {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    async function confirmPaymentAsync() {
      try {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // TO-DO: Update this: Make sure to change this to your payment completion page
            return_url: props.baseUrl,
          },
        });
        return error;
      } catch (error) {
        // Handle errors appropriately
        console.error('Error confirming payment:', error);
        return error;
      }
    }

    const { error } = await confirmPaymentAsync();

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error && (error.type === "card_error" || error.type === "validation_error")) {
      setMessage(error.message);
    } else {
      setMessage(messageMap("payments.checkout.unexpectedError", "generic"));
    }

    setIsLoading(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="pay-button" disabled={isLoading}>
        <span id="button-text">
          {isLoading ? <div className="spinner" /> : messageMap("checkout.pay", "button")}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}