import React, {useState, useRef} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logInUser } from "redux/actions/actionTypes";
import account from "redux/selectors/accountSelector";

import Alert from "templates/Alert";
import Tooltip from "templates/Tooltip";
import ConfirmUserModal from "templates/customModals/ConfirmUserModal";

import messageMap from "Utilities/MessageMaps";
import { getUserBrowser, getUserPlatform } from "Utilities/UserMetadata";
import { emailValidator, userNameValidator, passwordValidator } from "Utilities/Validators/InputValidators";
import { checkHasExplicitWords } from "Utilities/Validators/ContentValidator.js";

import {saveAccountSettingsAPI,usernameAvailabilityAPI} from "apis/controllers/person/AccountsController";
import { getIP } from "apis/controllers/IPsController";

import hidePass from "assets/icons/navigation/hide_pass.svg";
import revealPass from "assets/icons/navigation/reveal_pass.svg";
import checkAsset from "assets/icons/home/check.svg";
import exAsset from "assets/icons/home/ex.svg";


function AccountsSubPage(props) {

	const [username, setUsername] = useState(props.username),
		[emailAddress, setEmailAddress] = useState(props.emailAddress),
		[password, setPassword] = useState("password"),
		[newUsername, setNewUsername] = useState(""),
		[newEmail, setNewEmail] = useState(""),
		[newPassword, setNewPassword] = useState(""),
		[usernameValid, setUsernameValid] = useState(true),
		[emailValid, setEmailValid] = useState(true),
		[emailConfirmValid, setEmailConfirmValid] = useState(false),
		[passwordValid, setPasswordValid] = useState(true),
		[passwordConfirmValid, setPasswordConfirmValid] = useState(false),
		[usernameTooltip, setUsernameTooltip] = useState(null),
		[passwordTooltip, setPasswordTooltip] = useState(null);

	const [usernameAvailability, setUsernameAvailability] = useState(null);

	const usernameInputRef = useRef(),
		usernameAvailabilityRef = useRef(),
		emailInputRef = useRef(),
		emailConfirmRef = useRef(),
		passwordInputRef = useRef(),
		passwordConfirmRef = useRef(),
		passwordLockIconRef = useRef(),
		passwordRevealIconRef = useRef(),
		passwordConfirmLockIconRef = useRef(),
		passwordConfirmRevealIconRef = useRef(),
		updateUsernameButtonRef = useRef(),
		updateEmailButtonRef = useRef(),
		updatePasswordButtonRef = useRef(),
		updateAllButtonRef = useRef();

	const onChangeMap = {
		"username": setNewUsername,
		"email": setNewEmail,
		"password": setNewPassword
	};

	const validationMap = {
		"username": usernameValid,
		"email": emailValid,
		"emailConfirm": emailConfirmValid,
		"password": passwordValid,
		"passwordConfirm": passwordConfirmValid
	};

	const validMap = {
		"username": setUsernameValid,
		"email": setEmailValid,
		"emailConfirm": setEmailConfirmValid,
		"password": setPasswordValid,
		"passwordConfirm": setPasswordConfirmValid
	};

	const newValueMap = {
		"username": newUsername,
		"email": newEmail,
		"password": newPassword
	};

	const originalValueMap = {
		"username": username,
		"email": emailAddress,
		"password": password
	};

	const originalSetValueMap = {
		"username": setUsername,
		"email": setEmailAddress,
		"password": setPassword
	};

	function updateAllAccountFields(e) {
		if (e.target.className === "page-action not-allowed") {
			return;
		}

		props.setConfirmUpdate(
			<ConfirmUserModal title={messageMap("account.update", "validation")} closeModal={updateUserAccount}
				closeArgs={"confirmed"} confirmType="verify" />
		);
	}

	function updateUserAccount(e, confirmation) {
		if (e == null || (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className))) {
			props.setConfirmUpdate(null);

			if (confirmation === "confirmed") {
				let payload = {
					account: {
						username: username,
						email: emailAddress,
						hashedPassword: password
					},
					identity: {
						ownerId: localStorage.getItem("ownerId"),
						jwt: localStorage.getItem("jwt")
					},
					metadata: {
						ip: "",
						browser: "",
						platform: ""
					},
					field: "all"
				};
				let invalidMsgKeys = [];
	
				// username check
				if (newUsername === "") {
					payload.account["username"] = username;
				}
				else if (!usernameValid) {
					invalidMsgKeys.push("account.username.invalid");
				}
				else if (newUsername === username) {
					invalidMsgKeys.push("account.username.sameAsOld");
				}
				else if (usernameValid) {
					payload.account["username"] = newUsername;
				}
				// email
				if (newEmail === "") {
					payload.account["email"] = emailAddress;
				}
				else if (!emailValid) {
					invalidMsgKeys.push("account.email.invalid");
				}
				else if (!emailConfirmValid) {
					invalidMsgKeys.push("account.emailConfirm.noMatch");
				}
				else if (newEmail === emailAddress) {
					invalidMsgKeys.push("account.email.sameAsOld");
				}
				else if (emailValid && emailConfirmValid) {
					payload.account["email"] = newEmail;
				}
				// password
				if (newPassword === "") {
					payload.account["hashedPassword"] = password;
				}
				else if (!passwordValid) {
					invalidMsgKeys.push("account.password.invalid");
				}
				else if (!passwordConfirmValid) {
					invalidMsgKeys.push("account.password.noMatch");
				}
				else if (newPassword === password) {
					invalidMsgKeys.push("account.password.sameAsOld");
				}
				else if (passwordValid && passwordConfirmValid) {
					payload.account["hashedPassword"] = newPassword;
				}
	
				if (invalidMsgKeys.length) {
					let errorMsg = "";
	
					invalidMsgKeys.forEach(errorKey => {
						errorMsg += messageMap(errorKey, "validation") + " ";
					});
					props.setAlert(
						<Alert closeHandler={props.closeAlert} type="error" msg={errorMsg} />
					);
	
					return;
				}
	
				getIP(ip => {
					payload["metadata"] = {
						ip: ip,
						browser: getUserBrowser(),
						platform: getUserPlatform()
					};
	
					saveAccountSettingsAPI(payload, response => {
						props.setAlert(
							<Alert closeHandler={props.closeAlert} type="information" msg={messageMap(response.responseCode, "api")} />
						);
	
						props.logInUser(response.sessionVo);
						["username", "email", "password"].forEach(el => {
							clearAccountFields(el);
						});
						updateAllButtonRef.current.className = "page-action not-allowed";
					});
				});
			}
		}
	}

	function closeModal(e, inputType) {
		if (e == null || (e != null && ["modal-block", "cancel", "fullRegistration", "icon", "close-button"].includes(e.target.className))) {
			props.setConfirmUpdate(null);

			if (inputType !== undefined) {
				getIP(ip => {
					let payload = {
						account: {
							username: newUsername,
							email: newEmail,
							hashedPassword: newPassword
						},
						identity: {
							ownerId: localStorage.getItem("ownerId"),
							jwt: localStorage.getItem("jwt")
						},
						metadata: {
							ip: ip,
							browser: getUserBrowser(),
							platform: getUserPlatform()
						},
						field: inputType
					};

					saveAccountSettingsAPI(payload, response => {
						let alertType = "information";
						if (response.responseCode === "accounts.update.password.failed") {
							alertType = "error";
						}
						else {
							clearAccountFields(inputType);
						}

						onChangeMap[inputType]("");
						if (["username", "email"].includes(inputType)) {
							originalSetValueMap[inputType](payload.account[inputType]);
						}
						else {
							originalSetValueMap[inputType](payload.account["password"]);
						}
						props.setAlert(
							<Alert closeHandler={props.closeAlert} type={alertType} msg={messageMap(response.responseCode, "api")} />
						);

						props.logInUser(response.sessionVo);
					});
				});
			}
		}
	}

	function onChangeInput(inputType, e) {
		const target = e.target,
			text = target.value;

		if (["username", "email", "password"].includes(inputType)) {
			onChangeMap[inputType](text);
		}

		if (text === "") {
			target.className = "page-field-value";
			return;
		}

		let usernameValidationResults,
			passwordValidationResults;
		if (inputType === "username") {
			usernameValidationResults = userNameValidator(text);
		}
		else if (inputType === "password") {
			passwordValidationResults = passwordValidator(text);
		}

		let localUsernameValid = validationMap["username"],
			localEmailValid = validationMap["email"],
			localEmailConfirmValid = validationMap["emailConfirm"],
			localPasswordValid = validationMap["password"],
			localPasswordConfirmValid = validationMap["passwordConfirm"];

		if (inputType === "username") {
			updateUsernameButtonRef.current.className = "page-field username-check not-allowed";
			if (usernameValidationResults.passed && !checkHasExplicitWords(text)) {
				validMap[inputType](true);
				localUsernameValid = true;
				target.className = "page-field-value";
				usernameAvailabilityRef.current.className = "page-field username-check";
			}
			else {
				validMap[inputType](false);
				localUsernameValid = false;
				target.className = "page-field-value warning";
				usernameAvailabilityRef.current.className = "page-field username-check not-allowed";
				updateAllButtonRef.current.className = "page-action not-allowed";
			}

			showUsernameTooltip(usernameValidationResults);
		}
		else if (inputType === "email") {
			updateEmailButtonRef.current.className = "page-field update-field not-allowed";
			emailConfirmRef.current.value = "";

			if (emailValidator(text)) {
				validMap[inputType](true);
				localEmailValid = true;
				target.className = "page-field-value";
				emailConfirmRef.current.readOnly = false;
				emailConfirmRef.current.className = "page-field-value";
			}
			else {
				validMap[inputType](false);
				localEmailValid = false;
				target.className = "page-field-value warning";
				emailConfirmRef.current.readOnly = true;
				emailConfirmRef.current.className = "page-field-value not-allowed";
			}
		}
		else if (inputType === "emailConfirm") {
			updateEmailButtonRef.current.className = "page-field update-field not-allowed";
			if (text === newEmail) {
				validMap[inputType](true);
				localEmailConfirmValid = true;
				target.className = "page-field-value";
				updateEmailButtonRef.current.className = "page-field update-field";
			}
			else {
				validMap[inputType](false);
				localEmailConfirmValid = false;
				target.className = "page-field-value warning";
				updateAllButtonRef.current.className = "page-action not-allowed";
			}
		}
		else if (inputType === "password") {
			updatePasswordButtonRef.current.className = "page-field update-field not-allowed";
			passwordConfirmRef.current.value = "";

			if (passwordValidationResults.passed) {
				validMap[inputType](true);
				localPasswordValid = true;
				target.className = "page-field-value";
				passwordConfirmRef.current.readOnly = false;
				passwordConfirmRef.current.className = "page-field-value";
			}
			else {
				validMap[inputType](false);
				localPasswordValid = false;
				target.className = "page-field-value warning";
				passwordConfirmRef.current.readOnly = true;
				passwordConfirmRef.current.className = "page-field-value not-allowed";
			}

			showPasswordTooltip(passwordValidationResults);
		}
		else if (inputType === "passwordConfirm") {
			updatePasswordButtonRef.current.className = "page-field update-field not-allowed";
			if (text === newPassword) {
				validMap[inputType](true);
				localPasswordConfirmValid = true;
				target.className = "page-field-value";
				updatePasswordButtonRef.current.className = "page-field update-field";
			}
			else {
				validMap[inputType](false);
				localPasswordConfirmValid = false;
				target.className = "page-field-value warning";
				updateAllButtonRef.current.className = "page-action not-allowed";
			}
		}

		// No real time update for valid states
		if (localUsernameValid && localEmailValid && localEmailConfirmValid && localPasswordValid && localPasswordConfirmValid) {
			updateAllButtonRef.current.className = "page-action";
		}
	}

	function updateField(e, inputType) {
		if (newValueMap[inputType] === "" || e.target.className === "page-field update-field not-allowed" || e.target.className === "page-field username-check not-allowed") {
			return;
		}

		if (newValueMap[inputType] === originalValueMap[inputType]) {
			props.setAlert(
				<Alert closeHandler={props.closeAlert} type="error" msg={messageMap(`account.${inputType}.sameAsOld`, "validation")} />
			);
		}
		else {
			props.setConfirmUpdate(
				<ConfirmUserModal title={messageMap("account.update", "validation")} closeModal={closeModal}
					closeArgs={inputType} confirmType="verify" />
			);
		}
	}

	function showUsernameTooltip(validationObject) {
		const containerStyle = {
			"marginTop": "-90px"
		};
		const list = [{
			key: "hasLength",
			text: messageMap("account.username.text", "validation")
		}];

		if (validationObject.target != null) {
			const usernameValidationResults = userNameValidator(newUsername);
			if (usernameValidationResults.passed) {
				validationObject = usernameValidationResults;
			}
		}

		setUsernameTooltip(
			<Tooltip classStr="tooltip-bottom-left"
				containerStyle={containerStyle}
				subheader={messageMap("account.username.title", "validation")}
				ariaReference="usernameTooltip"
				list={list} type="username"
				passValidProgress={validationObject}>
			</Tooltip>
		);
	}

	function showPasswordTooltip(validationObject) {
		const containerStyle = {
			"marginTop": "-235px"
		};
		const list = [
			{
				key: "hasLength",
				text: messageMap("account.password.text1", "validation")
			}, {
				key: "hasUpper",
				text: messageMap("account.password.text2", "validation")
			}, {
				key: "hasLower",
				text: messageMap("account.password.text3", "validation")
			}, {
				key: "hasNumber",
				text: messageMap("account.password.text4", "validation")
			}, {
				key: "hasSpecial",
				text: messageMap("account.password.text5", "validation")
			}
		];

		if (validationObject.target != null) {
			const passwordValidationResults = passwordValidator(newPassword);
			if (passwordValidationResults.passed) {
				validationObject = passwordValidationResults;
			}
		}

		setPasswordTooltip(
			<Tooltip classStr="tooltip-bottom-left"
				containerStyle={containerStyle}
				subheader={messageMap("account.password.title", "validation")}
				ariaReference="passwordTooltip"
				list={list} type="password" passValidProgress={validationObject}>
			</Tooltip>
		);
	}

	function checkUsernameAvailability(e) {
		if (e.target.className === "page-field username-check not-allowed" || !!!newUsername) {
			return;
		}

		usernameAvailabilityAPI({ username: newUsername }, resp => {
			let isUsed;

			if (resp) {
				isUsed = exAsset;
				setUsernameValid(false);
				usernameInputRef.current.className = "page-field-value invalid";
				updateUsernameButtonRef.current.className = "page-field username-check not-allowed";
			}
			else {
				isUsed = checkAsset;
				setUsernameValid(true);
				updateUsernameButtonRef.current.className = "page-field username-check";
			}

			const style = {
				"display": "inline-block",
				"marginLeft": "5px",
				"height": "25px"
			};

			setUsernameAvailability(
				<img src={isUsed} alt={messageMap("checkUsername.check", "image")} style={style}></img>
			);
		});
	}

	function clearAccountFields(inputType) {
		const inputsRefMap = {
			username: usernameInputRef,
			email: emailInputRef,
			password: passwordInputRef
		};
		const updateButtonsMap = {
			username: updateUsernameButtonRef,
			email: updateEmailButtonRef,
			password: updatePasswordButtonRef
		};
		const confirmInputsMap = {
			email: emailConfirmRef,
			password: passwordConfirmRef
		};

		// input fields
		inputsRefMap[inputType].current.value = "";
		if (inputType === "password") {
			inputsRefMap[inputType].current.placeholder = "**************";
		}
		else {
			inputsRefMap[inputType].current.placeholder = newValueMap[inputType];
		}
		// confirm fields
		if (inputType === "username") {
			usernameAvailabilityRef.current.className = "page-field username-check not-allowed";
			setUsernameAvailability(null);
		}
		else {
			confirmInputsMap[inputType].current.value = "";
			confirmInputsMap[inputType].current.readOnly = true;
			confirmInputsMap[inputType].current.className = "page-field-value not-allowed";
		}
		// update buttons
		updateButtonsMap[inputType].current.className = inputType === "username" ? "page-field username-check not-allowed" : "page-field update-field not-allowed";

		if (inputType === "password") {
			passwordLockIconRef.current.className = "password-icon";
			passwordRevealIconRef.current.className = "password-icon hide";
			passwordConfirmLockIconRef.current.className = "password-icon";
			passwordConfirmRevealIconRef.current.className = "password-icon hide";
		}
	}

	function togglePasswordDisplay(inputRef, iconToHideRef, iconToShowRef) {
		if (inputRef.current.getAttribute("type") === "password") {
			inputRef.current.setAttribute("type", "text");
		}
		else {
			inputRef.current.setAttribute("type", "password");
		}

		iconToHideRef.current.className = "password-icon hide";
		iconToShowRef.current.className = "password-icon";
	}

	function validateInput(inputType, e) {
		if (newValueMap[inputType] === "" || (newValueMap[inputType] !== "" && validationMap[inputType])) {
			e.target.className = "page-field-value";
		}
		else if (newValueMap[inputType] !== "" && !validationMap[inputType] && e.target.className !== "page-field-value not-allowed") {
			e.target.className = "page-field-value invalid";
		}

		if (["username", "password"].includes(inputType)) {
			const tooltipMap = {
				"username": setUsernameTooltip,
				"password": setPasswordTooltip
			};

			tooltipMap[inputType](null);
		}
	}


	return (
		<div>
			<h1 className="page-title">
				{messageMap("profile.account", "subPageNavigation")}
			</h1>

			<div className="page-field username-label">
				<label htmlFor="username" className="page-field-label">
					{messageMap("account.fields.username", "generic")}
				</label>

				<div className="inline-fields inline-username">
					{usernameTooltip}
					<input id="username" placeholder={username} className="page-field-value"
						tabIndex={0} maxLength={20} aria-describedby="usernameTooltip"
						onChange={e => onChangeInput("username", e)}
						onBlur={e => validateInput("username", e)}
						onFocus={showUsernameTooltip}
						ref={usernameInputRef}>
					</input>

					<div className="inline-buttons">
						<button className="page-field username-check not-allowed"
							onClick={checkUsernameAvailability}
							onKeyDown={checkUsernameAvailability} ref={usernameAvailabilityRef}>
							{messageMap("account.fields.usernameAvailability", "generic")}
						</button>
						{usernameAvailability}
						<button className="page-field username-check not-allowed"
							onClick={e => updateField(e, "username")}
							onKeyPress={e => updateField(e, "username")}
							ref={updateUsernameButtonRef}>
							{messageMap("update.username", "button")}
						</button>
					</div>
				</div>
			</div>

			<div className="page-field">
				<label htmlFor="email" className="page-field-label">
					{messageMap("account.fields.email", "generic")}
				</label>
				<div className="inline-fields">
					<input id="email" placeholder={emailAddress} className="page-field-value"
						tabIndex={0}
						onChange={e => onChangeInput("email", e)}
						onBlur={e => validateInput("email", e)}
						ref={emailInputRef}></input>
				</div>
			</div>

			<div className="page-field">
				<label htmlFor="emailConfirm" className="page-field-label">
					{messageMap("account.fields.emailConfirm", "generic")}
				</label>

				<div className="inline-fields">
					<input id="emailConfirm" className="page-field-value not-allowed"
						tabIndex={0} readOnly={true}
						onChange={e => onChangeInput("emailConfirm", e)}
						onBlur={e => validateInput("emailConfirm", e)}
						ref={emailConfirmRef}></input>

					<div className="inline-buttons">
						<button className="page-field update-field not-allowed"
							onClick={e => updateField(e, "email")}
							onKeyPress={e => updateField(e, "email")}
							ref={updateEmailButtonRef}>
							{messageMap("update.email", "button")}
						</button>
					</div>
				</div>
			</div>

			<div className="page-field">
				<label htmlFor="password" className="page-field-label">
					{messageMap("account.fields.password", "generic")}
				</label>

				<div className="inline-elements">
					{passwordTooltip}
					<input type="password" id="password" placeholder="**************"
						className="page-field-value" tabIndex={0}
						aria-describedby="passwordTooltip"
						onChange={e => onChangeInput("password", e)}
						onBlur={e => validateInput("password", e)}
						onFocus={showPasswordTooltip}
						ref={passwordInputRef}>
					</input>
					<img className="password-icon" src={hidePass} alt={messageMap("account.passwordLock", "image")}
						onClick={(e) => togglePasswordDisplay(passwordInputRef, passwordLockIconRef, passwordRevealIconRef)}
						onKeyPress={(e) => togglePasswordDisplay(passwordInputRef, passwordLockIconRef, passwordRevealIconRef)}
						role="button" tabIndex={0} ref={passwordLockIconRef}></img>
					<img className="password-icon hide" src={revealPass} alt={messageMap("account.passwordLock", "image")}
						onClick={(e) => togglePasswordDisplay(passwordInputRef, passwordRevealIconRef, passwordLockIconRef)}
						onKeyPress={(e) => togglePasswordDisplay(passwordInputRef, passwordRevealIconRef, passwordLockIconRef)}
						role="button" tabIndex={0} ref={passwordRevealIconRef}></img>
				</div>
			</div>

			<div className="page-field">
				<label htmlFor="passwordConfirm" className="page-field-label">
					{messageMap("account.fields.passwordConfirm", "generic")}
				</label>

				<div className="inline-fields">
					<div className="inline-elements">
						<input type="password" id="passwordConfirm"
							className="page-field-value not-allowed"
							tabIndex={0} readOnly={true}
							onChange={e => onChangeInput("passwordConfirm", e)}
							onBlur={e => validateInput("passwordConfirm", e)}
							ref={passwordConfirmRef}></input>
						<img className="password-icon" src={hidePass} alt={messageMap("account.passwordLock", "image")}
							onClick={(e) => togglePasswordDisplay(passwordConfirmRef, passwordConfirmLockIconRef, passwordConfirmRevealIconRef)}
							onKeyPress={(e) => togglePasswordDisplay(passwordConfirmRef, passwordConfirmLockIconRef, passwordConfirmRevealIconRef)}
							role="button" tabIndex={0} ref={passwordConfirmLockIconRef}></img>
						<img className="password-icon hide" src={revealPass} alt={messageMap("account.passwordLock", "image")}
							onClick={(e) => togglePasswordDisplay(passwordConfirmRef, passwordConfirmRevealIconRef, passwordConfirmLockIconRef)}
							onKeyPress={(e) => togglePasswordDisplay(passwordConfirmRef, passwordConfirmRevealIconRef, passwordConfirmLockIconRef)}
							role="button" tabIndex={0} ref={passwordConfirmRevealIconRef}></img>
					</div>
					<div className="inline-buttons">
						<button className="page-field update-field not-allowed"
							onClick={e => updateField(e, "password")}
							onKeyPress={e => updateField(e, "password")}
							ref={updatePasswordButtonRef}>
							{messageMap("update.password", "button")}
						</button>
					</div>
				</div>
			</div>

			<button className="page-action not-allowed" tabIndex={0}
				onClick={updateAllAccountFields}
				onKeyPress={updateAllAccountFields}
				ref={updateAllButtonRef}>
				{messageMap("update.all", "button")}
			</button>
		</div>
	);
}


AccountsSubPage.propTypes = {
	username: PropTypes.string.isRequired,
	emailAddress: PropTypes.string.isRequired,

	// click handlers
	closeAlert: PropTypes.func.isRequired,
	setConfirmUpdate: PropTypes.func.isRequired,
	reRenderAccounts: PropTypes.func.isRequired,

	// parent state setters
	setAlert: PropTypes.func.isRequired,

	// Redux props
	logInUser: PropTypes.func.isRequired
};

export default connect(
	account,
	{ logInUser }
)(AccountsSubPage);

