import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/physiology/thumbnailProcedural.jpg";
import proceduralActivitiesAsset from "assets/topicArticles/LearningOptimizations/physiology/proceduralActivities.svg";
import basalGangliaAsset from "assets/topicArticles/LearningOptimizations/physiology/basalGanglia.png";
import neocortexAsset from "assets/topicArticles/LearningOptimizations/physiology/neocortex.png";
import thalamusAsset from "assets/topicArticles/LearningOptimizations/physiology/thalamus.png";
import proceduralSystemAsset from "assets/topicArticles/LearningOptimizations/physiology/proceduralSystem.svg";


export const physiologyProceduralSystemArticle = {
	id: "V. Physiology_4_1 How information travels in our brain: the procedural system (Part 2)",
	title: messageMap("proceduralSystem.article.title", "learningOptimization"),
	description: messageMap("proceduralSystem.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("proceduralSystem.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "text4", "html1",
		"text5", "text6", "text7", "text8",
		"text9", "text10",
		"text11", "text12", "html2", "text13", "html3", "text14", "html4", "text15",
		"text16", "text17", "html5", "text18", "text19",
		"text20", "text21",
		"text22", "text23", "text24", "text25", "text26"
	],
	datePublished: "2023-12-29",
	dateModified: "2024-06-26"
};

export const physiologyProceduralSystemArticleTexts = {
	text1: {
		text: messageMap("proceduralSystem.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("proceduralSystem.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("proceduralSystem.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("proceduralSystem.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("proceduralSystem.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text6: {
		text: messageMap("proceduralSystem.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("proceduralSystem.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("proceduralSystem.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("proceduralSystem.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text10: {
		text: messageMap("proceduralSystem.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("proceduralSystem.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text12: {
		text: messageMap("proceduralSystem.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("proceduralSystem.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("proceduralSystem.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("proceduralSystem.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("proceduralSystem.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text17: {
		text: messageMap("proceduralSystem.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text18: {
		text: messageMap("proceduralSystem.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("proceduralSystem.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text20: {
		text: messageMap("proceduralSystem.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text21: {
		text: messageMap("proceduralSystem.texts.p15", "learningOptimization"),
		type: DESCRIPTION
	},
	text22: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text23: {
		text: messageMap("proceduralSystem.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text24: {
		text: messageMap("proceduralSystem.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text25: {
		text: messageMap("proceduralSystem.sources.s3", "learningOptimization"),
		type: SOURCE
	},
	text26: {
		text: messageMap("proceduralSystem.sources.s4", "learningOptimization"),
		type: SOURCE
	}
};

export const physiologyProceduralSystemArticleHTMLs = {
	html1: (
		<img key="proceduralActivitiesAsset" src={proceduralActivitiesAsset} alt="the two types of procedural activities: goal-oriented and habitual" style={{width: "-webkit-fill-available"}} />
	),
	html2: (
		<img key="basalGangliaAsset" src={basalGangliaAsset} alt="the components of basal ganglia: the substantia nigra, the subthalamic nucleus, the globus pallidus, the putamen, and the caudate nucleus" style={{width: "350px"}} />
	),
	html3: (
		<img key="neocortexAsset" src={neocortexAsset} alt="neocortex region inside the cortex" style={{width: "350px"}} />
	),
	html4: (
		<img key="thalamusAsset" src={thalamusAsset} alt="thalamus inside the basal ganglia" style={{width: "350px"}} />
	),
	html5: (
		<img key="proceduralSystemAsset" src={proceduralSystemAsset} alt="components of procedural system, containing working memory, basal ganglia, neocortex, the thalamus, and motor neurons" style={{width: "-webkit-fill-available"}} />
	)
};

