export const FEEDBACK_VO = {
	feedback: {
		type: "string"
	},
	// available types: general, clazz, topic
	type: {
		type: "string"
	},
	ownerId: {
		type: "string"
	},
	classId: {
		type: "string",
		required: false
	},
	videoId: {
		type: "string",
		required: false
	}
}