import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";

import chevronAsset from "assets/icons/common/chevron.svg";


function Dropdown(props) {

	const [dropdownOptions, setDropdownOptions] = useState(),
		[selectedOption, setSelectedOption] = useState(),
		[selectedOptionAttribute, setSelectedOptionAttribute] = useState();

	const chevronRef = useRef(),
		dropdownOptionsRef = useRef();

	useEffect(() => {
		createDropdownOptions();
	}, []);

	function createDropdownOptions() {
		let dropdownItems = [];

		props.dropdownOptionsKeys.forEach(element => {
			let customAttributes = {};
			if (props.customDropdownItemAttribute && !["key", "tabIndex", "role", "onClick", "onKeyDown"].includes(props.customDropdownItemAttribute)) {
				customAttributes = {
					[props.customDropdownItemAttribute]: element
				};
			}

			dropdownItems.push(
				<div key={element} {...customAttributes} tabIndex={0} role="button"
					onClick={dropdownItemClickHandler}
					onKeyDown={dropdownItemClickHandler}>
					{props.dropdownOptionsLabels[element]}
				</div>
			);
		});

		let selectedAttribute = {};
		if (props.customDropdownItemAttribute && !["key", "tabIndex", "role", "onClick", "onKeyDown"].includes(props.customDropdownItemAttribute)) {
			selectedAttribute = {
				[props.customDropdownItemAttribute]: props.dropdownOptionsKeys[0]
			};
		}
		setSelectedOptionAttribute(selectedAttribute);
		setSelectedOption(props.dropdownOptionsLabels[props.dropdownOptionsKeys[0]]);
		setDropdownOptions(dropdownItems);
	}

	function dropdownItemClickHandler(e) {
		let selectedOption;
		let selectedAttribute = {};
		if (props.customDropdownItemAttribute && !["key", "tabIndex", "role", "onClick", "onKeyDown"].includes(props.customDropdownItemAttribute)) {
			const customKey = e.target.getAttribute(props.customDropdownItemAttribute);
			selectedOption = props.dropdownOptionsLabels[customKey];

			selectedAttribute = {
				[props.customDropdownItemAttribute]: customKey
			};
		}
		else {
			selectedOption = e.target.innerText;
		}

		setSelectedOptionAttribute(selectedAttribute);
		setSelectedOption(selectedOption);
		props.dropdownItemClickHandler && props.dropdownItemClickHandler(e);
	}

	function toggleDropdownDisplay() {
		if (dropdownOptionsRef.current.className === "dropdown-options hide") {
			dropdownOptionsRef.current.className = "dropdown-options";
			chevronRef.current.className = "chevron-icon-up";
		}
		else {
			dropdownOptionsRef.current.className = "dropdown-options hide";
			chevronRef.current.className = "chevron-icon-down";
		}
	}

	return (
		<div className={`dropdown-container ${props.customContainerClass}`}
			onClick={toggleDropdownDisplay} onKeyDown={toggleDropdownDisplay}>
			<div className="dropdown-selector">
				<div ref={props.selectedOptionParentRef}  {...selectedOptionAttribute} >
					{selectedOption}
				</div>
				<img src={chevronAsset} alt="chevron" className="chevron-icon-down" ref={chevronRef}></img>
			</div>
			<div className="dropdown-options hide" ref={dropdownOptionsRef}>
				{dropdownOptions}
			</div>
		</div>
	);
}

Dropdown.defaultProps = {
	customContainerClass: ""
};

Dropdown.propTypes = {
	customContainerClass: PropTypes.string,
	dropdownOptionsKeys: PropTypes.array.isRequired,
	dropdownOptionsLabels: PropTypes.object.isRequired,
	dropdownItemClickHandler: PropTypes.func,
	customDropdownItemAttribute: PropTypes.string,

	// parent ref
	selectedOptionParentRef: PropTypes.object
};

export default Dropdown;


