export const CATEGORIES = {
	TEACH: "teach",
	QUESTION: "question",
	ACCOUNT: "account"
};

export const PURPOSE = {
	// for instructional videos
	TOPIC: "topic",
	THUMBNAIL: "thumbnail",
	MENTAL_MODEL: "mental_model",
	ADDITIONAL_RESOURCE: "additional_resources",
	// for questions: there's a good chance this part will change
	QUESTION: "question",
	ANSWER: "answer",
	// for account
	PROFILE_PICTURE: "profile_picture",
	VIDEO_INTRODUCTION: "video_introduction",
	PERSONAL_MISC: "personal_misc"
};

export const FORM_DATA = {
	VIDEO_TITLE: "videoTitle",
	VIDEO_DURATION: "videoDuration",
	VIDEO: "video",
	THUMBNAIL: "thumbnail",
	THUMBNAIL_DESCRIPTION: "thumbnailDescription",
	SUBTITLES: "subtitles",
	MENTAL_MODELS: "mentalModels",
	PRICE: "price",
	TOPIC_ID: "topicID",
	TOPIC_TYPE: "topicType",
	DESCRIPTION: "description",
	CATEGORY: "category"
};

export const AUTO_SAVE_TIMEOUT_PERIOD = 2000; // (milliseconds)
export const AUTO_REDIRECT_TIMEOUT_PERIOD = 5000; // (milliseconds)