import React, {useState} from "react";
import PropTypes from "prop-types";

import Alert from "templates/Alert";

import messageMap from "Utilities/MessageMaps";

import { withdrawEarningsAPI } from "apis/controllers/person/AccountsController";


function FinancialsSubPage(props) {

	const [earnings, setEarnings] = useState(props.earnings);

	function withdrawEarnings() {
		const identity = {
			ownerId: localStorage.getItem("ownerId"),
			jwt: localStorage.getItem("jwt")
		};

		withdrawEarningsAPI(identity, (resp) => {
			const msg = resp["MESSAGES"][0];
			const alertType = msg === "accounts.withdrawal.withdrawalPending" ? "success" : "error";

			props.setAlert(
				<Alert msg={messageMap(msg, "api")} closeHandler={props.closeAlert}
					type={alertType} />
			);
			setEarnings(resp["EARNINGS"]);
		});
	}


	return (
		<div>
			<h1 className="page-title">
				{messageMap("profile.financials", "subPageNavigation")}
			</h1>

			<div className="page-field">
				<div className="inline-elements earnings">
					<div className="earnings-label">
						{messageMap("profilePage.earnings.fields.earnings", "generic")}
					</div>
					<label htmlFor="earningsWithdrawal" className="page-field-label earnings">
						{props.currencySymbol}{earnings}
					</label>
				</div>
				<button className={"page-field update-field earnings" + (earnings != null && earnings < props.minimumCurrencyWithdrawal ? " not-allowed" : "")}
					onClick={withdrawEarnings} onKeyDown={withdrawEarnings}>
					{messageMap("earnings.withdrawEarnings", "button")}
				</button>
			</div>
		</div>
	);
}

FinancialsSubPage.defaultProps = {
	currencySymbol: "$"
};

FinancialsSubPage.propTypes = {
	currencySymbol: PropTypes.string.isRequired,
	minimumCurrencyWithdrawal: PropTypes.number.isRequired,
	earnings: PropTypes.number,

	// click handlers
	closeAlert: PropTypes.func.isRequired,

	// parent state setters
	setAlert: PropTypes.func.isRequired
};

export default FinancialsSubPage;

