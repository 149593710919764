import {INCREMENT_LOGIN_ATTEMPT_COUNT, CLEAR_LOGIN_ATTEMPT_COUNT,
				OPEN_SIGNUP_MODAL, CLOSE_SIGNUP_MODAL,
				OPEN_LOGIN_MODAL, CLOSE_LOGIN_MODAL,
				CLEAR_NOTIFICATIONS, UNCLEAR_NOTIFICATIONS} from "redux/actions/genericConstants";

const initialState = {
	loginAttemptCount: 0,
	signUpModal: "closed",
	loginModal: "closed",
	clearNotifications: localStorage.getItem("clearNotifications") === null ? "0" : localStorage.getItem("clearNotifications")
};

export default function(state = initialState, action) {
	if (action.type === INCREMENT_LOGIN_ATTEMPT_COUNT) {
		return {
			...state,
			loginAttemptCount: state.loginAttemptCount + 1
		};
	}
	else if (action.type === CLEAR_LOGIN_ATTEMPT_COUNT) {
		return {
			...state,
			loginAttemptCount: 0
		};
	}
	else if (action.type === OPEN_SIGNUP_MODAL) {
		return {
			...state,
			signUpModal: "opened"
		};
	}
	else if (action.type === CLOSE_SIGNUP_MODAL) {
		return {
			...state,
			signUpModal: "closed"
		};
	}
	else if (action.type === OPEN_LOGIN_MODAL) {
		return {
			...state,
			loginModal: "opened"
		};
	}
	else if (action.type === CLOSE_LOGIN_MODAL) {
		return {
			...state,
			loginModal: "closed"
		};
	}
	else if (action.type === CLEAR_NOTIFICATIONS) {
		localStorage.setItem("clearNotifications", "1");

		return {
			...state,
			clearNotifications: "1"
		};
	}
	else if (action.type === UNCLEAR_NOTIFICATIONS) {
		localStorage.removeItem("clearNotifications");

		return {
			...state,
			clearNotifications: "0"
		};
	}
	else {
		return state;
	}

}