// KEEP FIRST-LEVEL KEYS IN ALPHABETIC ORDER
// will be used as a catch-all for any remaining text that doesn't belong to the other message map types
export const genericMessageMap = {
	account: {
		pageTitle: "User Profile",
		fields: {
			username: "Username",
			usernameAvailability: "Check availability",
			email: "Email Address",
			emailConfirm: "Confirm Email Address",
			password: "Password",
			passwordConfirm: "Confirm password",
			accessKey: "Access Key"
		},
		access: {
			attempts: "You've already attempted to login 3x. Please try again later.",
			betaKey: "Get Beta Key",
			earlyAccess: {
				header: "Early Access",
				get: "Get Early Access"
			},
			futureAndSharingAccess: {
				subHeader: "If you're interested in receiving early access for future releases, and if you would like to share early access to others, please ",
				later: "You can also do this later by creating an account, going to your profile page, and checking the appropriate boxes."
			}
		},
		login: {
			attempts: "You've already attempted to login 3x. Please try again later.",
			confirm: "You've already attempted to confirm 3x. Please try again later.",
			header: "Login",
			noAccount: "Create account:"
		},
		signUp: {
			header: "Sign Up",
			errorMsg: {
				msg: "Please correct the fields in ",
				highlight: "red *"
			},
			agreement: {
				text: "By signing up, you are indicating that you have read and agreed to the",
				termOfUse: "Terms & Conditions ",
				and: "and ",
				privacyPolicy: "Privacy Policy"
			},
			privacy: {
				data: "Our company strongly believes in giving you control of your data, but that can be compromised if we integrate 3rd party sign on. We hope you understand."
			},
			signUpFirst: "Let's Get You Signed Up First.",
			creditSignUp: {
				text: "You can complete the entire sign up process later to get school credit, or you can do it \t",
				now: "now"
			},
			existingAccount: "Already have an account?"
		}
	},
	footer: {
		rights: "2024 EXER INST. All rights reserved."
	},
	company: {
		careers: {
			title: "Careers"
		},
		contact: {
			title: "Contact Us"
		},
		help: {
			title: "Help Center"
		},
		feedback: {
			title: "Feedback"
		}
	},
	groups: {
		copyCode: "Join group code successfully copied to clipboard.",
		pageTitle: "Group Accounts",
		ellipsis: {
			removeMember: "Remove member",
			assignRole: "Assign role"
		},
		joinGroupPopUp: {
			title: "Invite people to your group"
		},
		removeMemberPopUp: {
			subtextPrefix: "Are you sure you want to remove",
			subtextMiddle: "from",
			titlePrefix: "Remove"
		},
		tableHeaders: {
			username: "Account",
			currentLevel: "Current Level",
			subject: "Latest Quest",
			topic: "Latest Topic",
			totalPoints: "Total Points"
		}
	},
	home: {
		generalMarketing: `Supercharge your learning using the latest research in education!`,
		action: "Let's do it!",
		learners: {
			title: "For Learners",
			category1: {
				title: "Growing subject list",
				description: "Choose from a growing number of topics created, maintained, and improved by a community of educators and learners."
			},
			category2: {
				title: "Research-backed",
				description: "Accelerate your growth and learning by using our effective and comprehensive methods, backed by psychological, neurological, social, and pedagogical research."
			}
		},
		educators: {
			title: "For Educators",
			category1: {
				title: "Create premium content",
				description: "Collaborate with our talented and experienced educators to produce high-quality content that many would enjoy."
			},
			category2: {
				title: "Help educate the world!",
				description: "Be part of a growing community that will redefine what it means to learn online!"
			}
		},
		team: {
			meet: "Meet the team"
		},
		cta: {
			message: "Start your learning journey today"
		}
	},
	class: {
		request: {
			title: "Class Request",
			subheader: "You need to be registered to request for classes"
		}
	},
	classPage: {
		tooltips: {
			checklist: {
				text: "Requirements",
				alt: "A checklist icon. Click to show course requirements."
			},
			relevance: {
				text: "Relevance",
				alt: "An icon of a person with the exclamation mark. Click to show course relevance."
			},
			coverage: {
				text: "Conceptual Coverage",
				alt: "A bar chart icon. Click to see how well the course covers all related concepts."
			},
			structure: {
				text: "Structure",
				alt: "A structure icon. Click to show course structure."
			},
			pacing: {
				text: "Recommended Pacing",
				alt: "An icon of a person running. Click to show recommended course pacing."
			},
			goals: {
				text: "Goals",
				alt: "A treasure chest icon. Click to show what you'll understand and receive after course completion."
			},
			guidepost: {
				text: "Things to Keep in Mind",
				alt: "A guidepost icon. Click to show course assistance."
			},
			instructor: {
				text: "Instructor",
				alt: "An instructor icon. Click to show instructor's credentials."
			},
			updates: {
				text: "Latest Class Updates",
				alt: "An update icon. Click t show course's latest updates."
			},
			reviews: {
				text: "Reviews",
				alt1: "An image of 2 people talking to each other. Click to leave us a feedback.",
				alt2: "A star icon to symbolize reviews."
			},
			videoIntro: {
				text: "Introduction",
				alt: "A video icon to show the video introduction."
			},
			node: {
				text: "Concept Map",
				alt: "A node icon to signify the course concept map."
			}
		},
		feedback: {
			question: "Care to leave a review of your experience so far?",
			placeholder: "Tell us what you think of the course."
		},
		details: {
			text: "Course Details"
		}
	},
	knowledgeTree: {
		uploadMissingVideoTopic: "No videos found for this topic. Upload video about '",
		learn: "Learn '",
		itemType: {
			subjects: "Missing Subjects for ",
			topics: "Missing Topics for ",
			classes: "Missing Classes for ",
			degrees: "Missing Degrees for ",
			jobs: "Missing Jobs for ",
			profileItems: "Missing Profile Items for ",
			skillsAndKnowledgeItems: "Missing Skills and Knowledge Items for ",
			skillsKnowledgeSubtree: "Missing",
			interviewItems: "Missing Interview Items for "
		},
		assistance: {
			grades: {
				subject: "We're still compiling classes for this subject. Have some suggestions? Let us know.",
				class: "We're still compiling modules for this class. Have some suggestions? Let us know.",
				topic: "We're still compiling topics for this class. Have some suggestions? Let us know."
			},
			degrees: {
				degree: "We're still compiling classes for this degree. Have some suggestions? Let us know.",
				class: "We're still compiling modules for this class. Have some suggestions? Let us know.",
				module: "We're still compiling topics for this module. Have some suggestions? Let us know."
			},
			jobs: {
				profile: "We're still compiling types of online profiles for this job. Have some suggestions? Let us know.",
				skillsKnowledge: "We're still compiling background skills and knowledge for this job. Have some suggestions? Let us know.",
				skillsKnowledgeSubtree: "We're still compiling classes for this skill. Have some suggestions? Let us know.",
				interview: "We're still compiling interview preparations for this job. Have some suggestions? Let us know."
			}
		},
		feedback: {
			grades: {
				module1: "Missing topic(s) for '",
				module2: "'? Let us know.'",
				subtree1: "Missing classes(s) for '",
				subtree2: "'? Let us know.",
				root: "Missing subject(s)? Let us know"
			},
			degrees: {
				class1: "Missing topic(s) for '",
				class2: "'? Let us know.",
				subtree1: "Missing class(es) for '",
				subtree2: "'? Let us know.",
				root: "Missing degree(s)? Let us know"
			},
			jobs: {
				profile: "Missing profile requirement(s)? Let us know.",
				skillsKnowledge: "Missing skills and knowledge requirement(s)? Let us know.",
				skillsKnowledgeSubtree1: "Missing class(es) for '",
				skillsKnowledgeSubtree2: "'? Let us know.",
				interview: "Missing interview preparations? Let us know.",
				root: "Missing job(s)? Let us know"
			}
		},
		placeholders: {
			saveView: "View name"
		},
		quest: {
			construction: "Under Construction"
		},
		workbook: {
			gradeSchool: "Grade School",
			degrees: "Degrees",
			jobs: "Jobs",
			setTreeType: "Set network"
		}
	},
	learnPage: {
		tooltips: {
			comments: {
				alt: "Comments icon. Small textbox containing ellipses. Click to display questions, answers, and general comments."
			},
			nodes: {
				alt: "Node icon. Five nodes connecting to a central node in the center. Click to display how this topic relates to other topics within the subject."
			},
			notes: {
				alt: "Notes icon. Sheet of paper with pencil signifying that it's writing something. Click to display your notes on the video."
			},
			videoList: {
				alt: "Video search icon. Magnifying glass on top of video icon. Click to display subject's list of videos."
			},
			transcript: {
				alt: "Transcript icon. Sheet of paper with a microphone icon next to it. Click to display video's transcript."
			},
			bookmark: {
				alt: "Bookmark icon that looks like a flag. Click to add to list of bookmarked videos."
			},
			bookmarkList: {
				alt: "Bookmarks icon. Click to display list of bookmarked videos."
			}
		},
		relatedVideos: "Related videos",
		mentalModel: {
			title: "Mental Model"
		},
		courseContent: {
			text: "Course Content"
		},
		navigation: {
			previousTopic: "Previous",
			nextTopic: "Next"
		},
		notes: {
			modalTitle: "New note at"
		},
		prompt: {
			ending: "What did you learn?",
			tooltip1: "This helps solidify your understanding and you can refer to this later when reviewing.",
			tooltip2: "Answering prompts helps to mentally engage you in the topic and improves conceptual retention."
		},
		transcript: {
			text: "Transcript"
		}
	},
	payments: {
		checkout: {
			shinyNeuronPack: "Shiny Neuron Pack",
			or: "OR",
			subtotal: "Subtotal",
			total: "Total",
			usd: "USD ",
			unexpectedError: "An unexpected error occurred."
		},
		confirmation: {
			checkLater: "We could not confirm the status of your payment. Please check back later."
		},
		quotes: {
			bonusString: "% bonus!",
			continue: "Continue",
			quotesHeader: "Let's Supercharge Your Journey"
		}
	},
	profilePage: {
		subHeader: {
			groupCode: "Group code",
		},
		groups: {
			code: "Code",
			createGroup: {
				groupName: "Group name",
				name: "Name",
				subtext: "Users are allowed to be in one group. Once you create or join a group, you can no longer join any other groups.",
				title: "Create group"
			}
		},
		earnings: {
			fields: {
				earnings: "Earnings"
			}
		},
		tutoring: {
			modal: {
				becomeATutor: "Become a tutor",
				application: {
					header: "Profile"
				},
				requirements: {
					header: "Requirements",
					p1: `Be a licensed teacher, professor, adjunct, has a master's degree in field of expertise, or has experience in relevant industry.`,
					p2: `Strong oral and writing fluency in English`,
					p3: `Can provide evidence of 4-year degree`,
					p4: `Be at least 18 years old.`,
					p5: `Have access to and are comfortable using your own computer - including working speakers, microphone, and webcam - and high-speed internet connection.`
				}
			}
		}
	},
	metadata: {
		browser: {
			notDetected: "Browser not detected."
		},
		platform: {
			notDetected: "Platform not detected."
		}
	},
	video: {
		browserSupport: "Your browser does not support HTML video.",
		processing: "Processing...",
		upload: {
			video: "Upload Video",
			thumbnail: "Upload Thumbnail",
			subtitles: "Upload Subtitles",
			mentalModel: "Upload Mental Model",
			topic: "Assign Topic",
			topicSuggestion: {
				none: "No Topics Yet Identified for ",
				suggest: "Suggest a Topic:"
			},
			pricing: "Pricing",
			topicExercises: {
				none: "No Exercise Yet Created for ",
				suggest: "Suggest an Exercise?:"
			}
		},
		placeholder: {
			title: "Video title",
			moduleTitle: "Module title",
			thumbnail: "Thumbnail description - for user accessibility & search engine crawlers",
			videoDescription: "Video description...",
			moduleDescription: "Module description...",
			files: "No file chosen",
			topic: "No associated topic",
			searchTopic: "Search topic"
		},
		uploadPage: {
			title: "Let's First See What Research Says About Making Awesome Videos",
			subheader: "The following are some suggestions and requirements to make high quality educational content",
			note: "Note*: These guidelines apply to learners age 12 and up.",
			uploadTitle: "Let's Upload Your Content",
			uploadSubheader: "Please fill the following form fields.",
			guidelines: {
				information: {
					title: "Gathering and Organizing Information"
				},
				delivery: {
					title: "Delivery"
				},
				viewers: {
					title: "Attracting Viewers"
				},
				accessibility: {
					title: "Accessibility Requirements & Suggestions"
				},
				miscellaneous: {
					title: "Miscellaneous"
				}
			}
		},
		editPage: {
			title: "Channel Content",
			videosTableHeaders: {
				title: "Title",
				mentalModel: "Mental Model",
				uploadDate: "Upload Date",
				viewCount: "View Count",
				pricing: "Pricing",
				earnings: "Earnings"
			},
			modulesTableHeader: {
				title: "Title",
				uploadDate: "Upload Date"
			},
			dropdown: {
				videos: "Videos",
				modules: "Modules",
				classes: "Classes"
			},
			totalEarnings: "Total Earnings"
		},
		listPage: {
			featured: "Featured Video",
			title: "What Do You Want to Learn Today?",
			views: "Views",
			ownTheVideo: "Want to own this Video?",
			placeholder: {
				search: "Search videos"
			}
		}
	},
	topicsInfoListPage: {
		title: "Read On"
	},
	practicePage: {
		pageTitle: "Practice Categories",
		subHeader: "Choose a category to start practicing",
		solvedAll: "You solved all the problems for this topic! Let's go to the next one.",
		topicOptions: {
			resumeQuest: "Resume Quest",
			startQuest: "Start Quest",
			practice: "Practice"
		},
		options: {
			trueFalse: "True - False",
			multipleChoice: "Multiple Choice",
			fillInTheBlank: "Fill-In-The-Blank"
		},
		answerComponent: {
			true: "True",
			false: "False"
		},
		stats: {
			startQuest: "Start quest for stats",
			resumeQuest: "Resume quest for stats",
			signUpQuest: "Sign up for quest stats",
			totalPoints: "total points",
			level: "Level",
			bag: "Bag",
			experience: "Experience",
			topicStats: "Topic Stats",
			pointsToLevelUp: " points needed to level up"
		},
		shop: {
			text: "Shop",
			title: "Purchase Items"
		},
		bag: {
			text: "Bag",
			tutorials: "Tutorials",
			answers: "Answers",
			hints: "Hints",
			levels: "Levels",
			shinyNeurons: " shiny neurons"
		},
		useResources: {
			answer: "Get the answer",
			hint: "Get the hint",
			level: "Level up",
			or: "or",
			using: "using"
		},
		resourceResp: {
			hint: "Hint",
			answer: "Answer"
		}
	}
};