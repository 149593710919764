import messageMap from "Utilities/MessageMaps";

import {SUB_HEADER, DESCRIPTION, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/wellBeing/thumbnailSleep.jpg";


export const wellBeingRestSleepArticle = {
	id: "III. Well Being_2_4 How to get good quality rest: sleep",
	title: messageMap("restSleep.article.title", "learningOptimization"),
	description: messageMap("restSleep.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("restSleep.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2",
		"text3", "text4", "text5", "text6", "text7", "text8", "text9", "text10", "text11", "text12", "text13", "text14", "text15", "text16", "text17", "text18", "text19", "text20", "text21", "text22", "text23", "text24", "text25",
		"text26", "text27", "text28", "text29", "text30", "text31", "text32", "text33", "text43", "text35", "text36", "text37", "text83", "text39", "text40", "text41", "text42", "text43", "text44", "text45", "text46", "text47", "text48", "text49", "text50", "text51", "text52", "text53", "text54", "text55", "text56", "text57", "text58", "text59", "text60",
		"text61", "text62",
		"text63", "text64", "text65", "text66", "text67", "text68", "text69", "text70", "text71", "text72"
	],
	datePublished: "2022-03-06",
	dateModified: "2024-06-23"
};

export const wellBeingRestSleepArticleTexts = {
	text1: {
		text: messageMap("restSleep.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("restSleep.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("restSleep.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("restSleep.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("restSleep.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("restSleep.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("restSleep.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("restSleep.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("restSleep.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("restSleep.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("restSleep.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("restSleep.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("restSleep.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("restSleep.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("restSleep.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("restSleep.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text17: {
		text: messageMap("restSleep.texts.p15", "learningOptimization"),
		type: DESCRIPTION
	},
	text18: {
		text: messageMap("restSleep.texts.p16", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("restSleep.texts.p17", "learningOptimization"),
		type: DESCRIPTION
	},
	text20: {
		text: messageMap("restSleep.texts.p18", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("restSleep.texts.p19", "learningOptimization"),
		type: DESCRIPTION
	},
	text22: {
		text: messageMap("restSleep.texts.p20", "learningOptimization"),
		type: DESCRIPTION
	},
	text23: {
		text: messageMap("restSleep.texts.p21", "learningOptimization"),
		type: DESCRIPTION
	},
	text24: {
		text: messageMap("restSleep.texts.p22", "learningOptimization"),
		type: DESCRIPTION
	},
	text25: {
		text: messageMap("restSleep.texts.p23", "learningOptimization"),
		type: DESCRIPTION
	},
	text26: {
		text: messageMap("restSleep.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text27: {
		text: messageMap("restSleep.texts.p24", "learningOptimization"),
		type: DESCRIPTION
	},
	text28: {
		text: messageMap("restSleep.texts.p25", "learningOptimization"),
		type: DESCRIPTION
	},
	text29: {
		text: messageMap("restSleep.texts.p26", "learningOptimization"),
		type: DESCRIPTION
	},
	text30: {
		text: messageMap("restSleep.texts.p27", "learningOptimization"),
		type: DESCRIPTION
	},
	text31: {
		text: messageMap("restSleep.texts.p28", "learningOptimization"),
		type: DESCRIPTION
	},
	text32: {
		text: messageMap("restSleep.texts.p29", "learningOptimization"),
		type: DESCRIPTION
	},
	text33: {
		text: messageMap("restSleep.texts.p30", "learningOptimization"),
		type: DESCRIPTION
	},
	text34: {
		text: messageMap("restSleep.texts.p31", "learningOptimization"),
		type: DESCRIPTION
	},
	text35: {
		text: messageMap("restSleep.texts.p32", "learningOptimization"),
		type: DESCRIPTION
	},
	text36: {
		text: messageMap("restSleep.texts.p33", "learningOptimization"),
		type: DESCRIPTION
	},
	text37: {
		text: messageMap("restSleep.texts.p34", "learningOptimization"),
		type: DESCRIPTION
	},
	text38: {
		text: messageMap("restSleep.texts.p35", "learningOptimization"),
		type: DESCRIPTION
	},
	text39: {
		text: messageMap("restSleep.texts.p36", "learningOptimization"),
		type: DESCRIPTION
	},
	text40: {
		text: messageMap("restSleep.texts.p37", "learningOptimization"),
		type: DESCRIPTION
	},
	text41: {
		text: messageMap("restSleep.texts.p38", "learningOptimization"),
		type: DESCRIPTION
	},
	text42: {
		text: messageMap("restSleep.texts.p39", "learningOptimization"),
		type: DESCRIPTION
	},
	text43: {
		text: messageMap("restSleep.texts.p40", "learningOptimization"),
		type: DESCRIPTION
	},
	text44: {
		text: messageMap("restSleep.texts.p41", "learningOptimization"),
		type: DESCRIPTION
	},
	text45: {
		text: messageMap("restSleep.texts.p42", "learningOptimization"),
		type: DESCRIPTION
	},
	text46: {
		text: messageMap("restSleep.texts.p43", "learningOptimization"),
		type: DESCRIPTION
	},
	text47: {
		text: messageMap("restSleep.texts.p44", "learningOptimization"),
		type: DESCRIPTION
	},
	text48: {
		text: messageMap("restSleep.texts.p45", "learningOptimization"),
		type: DESCRIPTION
	},
	text49: {
		text: messageMap("restSleep.texts.p46", "learningOptimization"),
		type: DESCRIPTION
	},
	text50: {
		text: messageMap("restSleep.texts.p47", "learningOptimization"),
		type: DESCRIPTION
	},
	text51: {
		text: messageMap("restSleep.texts.p48", "learningOptimization"),
		type: DESCRIPTION
	},
	text52: {
		text: messageMap("restSleep.texts.p49", "learningOptimization"),
		type: DESCRIPTION
	},
	text53: {
		text: messageMap("restSleep.texts.p50", "learningOptimization"),
		type: DESCRIPTION
	},
	text54: {
		text: messageMap("restSleep.texts.p51", "learningOptimization"),
		type: DESCRIPTION
	},
	text55: {
		text: messageMap("restSleep.texts.p52", "learningOptimization"),
		type: DESCRIPTION
	},
	text56: {
		text: messageMap("restSleep.texts.p53", "learningOptimization"),
		type: DESCRIPTION
	},
	text57: {
		text: messageMap("restSleep.texts.p54", "learningOptimization"),
		type: DESCRIPTION
	},
	text58: {
		text: messageMap("restSleep.texts.p55", "learningOptimization"),
		type: DESCRIPTION
	},
	text59: {
		text: messageMap("restSleep.texts.p56", "learningOptimization"),
		type: DESCRIPTION
	},
	text60: {
		text: messageMap("restSleep.texts.p57", "learningOptimization"),
		type: DESCRIPTION
	},
	text61: {
		text: messageMap("restSleep.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text62: {
		text: messageMap("restSleep.texts.p58", "learningOptimization"),
		type: DESCRIPTION
	},
	text63: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text64: {
		text: messageMap("restSleep.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text65: {
		text: messageMap("restSleep.sources.s2", "learningOptimization"),
		type: SOURCE
	},
	text66: {
		text: messageMap("restSleep.sources.s3", "learningOptimization"),
		type: SOURCE
	},
	text67: {
		text: messageMap("restSleep.sources.s4", "learningOptimization"),
		type: SOURCE
	},
	text68: {
		text: messageMap("restSleep.sources.s5", "learningOptimization"),
		type: SOURCE
	},
	text69: {
		text: messageMap("restSleep.sources.s6", "learningOptimization"),
		type: SOURCE
	},
	text70: {
		text: messageMap("restSleep.sources.s7", "learningOptimization"),
		type: SOURCE
	},
	text71: {
		text: messageMap("restSleep.sources.s8", "learningOptimization"),
		type: SOURCE
	},
	text72: {
		text: messageMap("restSleep.sources.s9", "learningOptimization"),
		type: SOURCE
	},
};



