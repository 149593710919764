import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import { ConnectedPurchasePopUp } from './PurchasePopUp';
import { getPriceInShinyNeurons } from './VideoUtilitiesList';

import messageMap from "Utilities/MessageMaps";

function PayToWatchButton(props) {
  const [showPopUp, setShowPopUp] = useState(false);
  const history = useHistory();

  function openBuyPopUp() {
    setShowPopUp(true);
  }

  return (
    <div className="purchase-button-container">
      {
        props.price
        ? <button className="purchase-button" onClick={openBuyPopUp}
            onKeyDown={openBuyPopUp}>
            {messageMap("buy.text", "button")}
          </button>
        : null
      }

      {showPopUp && <ConnectedPurchasePopUp {...props} setShowPopUp={setShowPopUp} />}
    </div>
  )
}

export const ConnectedPayToWatchButton = connect(
  account,
  null
)(PayToWatchButton);