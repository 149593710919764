import React from "react";

import {SUB_MENU_TYPES_IMAGES, SUB_MENU_TYPES_ALTS, SUB_MENU_TYPES_LABELS} from "templates/utilities/PageSubMenu";


export function generateSubMenu(menuType, menuClickHandler) {
	let subMenus = [];

	const subMenuOptions = SUB_MENU_TYPES_IMAGES[menuType];
	const subMenuOptionAlts = SUB_MENU_TYPES_ALTS[menuType];
	const subMenuOptionsLabels = SUB_MENU_TYPES_LABELS[menuType];

	for (const [key, value] of Object.entries(subMenuOptions)) {
		subMenus.push(
			<button key={key} onClick={e => menuClickHandler(key)} onKeyDown={e => menuClickHandler(key)}>
				<img src={value} alt={subMenuOptionAlts[key]}></img>
				{subMenuOptionsLabels[key]}
			</button>
		);
	}

	return subMenus;
}



