import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/wellBeing/thumbnailHandlingStress.jpg";


export const wellBeingHandlingStressArticle = {
	id: "III. Well Being_2_3 Ways for handling stress",
	title: messageMap("handlingStress.article.title", "learningOptimization"),
	description: messageMap("handlingStress.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("handlingStress.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "text4",
		"text5", "text6",
		"text7", "text8", "text9", "text10", "text11",
		"text12", "text13", "text14", "text15", "text16",
		"text17", "text18", "text19", "text20", "text21", "text22", "text23",
		"text24", "text25", "text26", "text27", "text28",
		"text29", "text30", "text31", "text32", "text33", "text34",
		"text35", "text36"
	],
	datePublished: "2022-03-06",
	dateModified: "2024-06-23"
};

export const wellBeingHandlingStressArticleTexts = {
	text1: {
		text: messageMap("handlingStress.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("handlingStress.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("handlingStress.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("handlingStress.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("handlingStress.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text6: {
		text: messageMap("handlingStress.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("handlingStress.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text8: {
		text: messageMap("handlingStress.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("handlingStress.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("handlingStress.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("handlingStress.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("handlingStress.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text13: {
		text: messageMap("handlingStress.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("handlingStress.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("handlingStress.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("handlingStress.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text17: {
		text: messageMap("handlingStress.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text18: {
		text: messageMap("handlingStress.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("handlingStress.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text20: {
		text: messageMap("handlingStress.texts.p15", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("handlingStress.texts.p16", "learningOptimization"),
		type: DESCRIPTION
	},
	text22: {
		text: messageMap("handlingStress.texts.p17", "learningOptimization"),
		type: DESCRIPTION
	},
	text23: {
		text: messageMap("handlingStress.texts.p18", "learningOptimization"),
		type: DESCRIPTION
	},
	text24: {
		text: messageMap("handlingStress.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text25: {
		text: messageMap("handlingStress.texts.p19", "learningOptimization"),
		type: DESCRIPTION
	},
	text26: {
		text: messageMap("handlingStress.texts.p20", "learningOptimization"),
		type: DESCRIPTION
	},
	text27: {
		text: messageMap("handlingStress.texts.p21", "learningOptimization"),
		type: DESCRIPTION
	},
	text28: {
		text: messageMap("handlingStress.texts.p22", "learningOptimization"),
		type: DESCRIPTION
	},
	text29: {
		text: messageMap("handlingStress.texts.header7", "learningOptimization"),
		type: SUB_HEADER
	},
	text30: {
		text: messageMap("handlingStress.texts.p23", "learningOptimization"),
		type: DESCRIPTION
	},
	text31: {
		text: messageMap("handlingStress.texts.p24", "learningOptimization"),
		type: DESCRIPTION
	},
	text32: {
		text: messageMap("handlingStress.texts.p25", "learningOptimization"),
		type: DESCRIPTION
	},
	text33: {
		text: messageMap("handlingStress.texts.p26", "learningOptimization"),
		type: DESCRIPTION
	},
	text34: {
		text: messageMap("handlingStress.texts.p27", "learningOptimization"),
		type: DESCRIPTION
	},
	text35: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text36: {
		text: messageMap("handlingStress.sources.s1", "learningOptimization"),
		type: SOURCE
	}
};

