import React from 'react';
import PropTypes from "prop-types";

import Modal from "templates/Modal";
import TermsNConditions from "pages/TermsNConditions/TermsNConditions";


/**
 * @description custom modal for terms n conditions
 * @param {Function} closeHandler close handler for modal
 */
export default function TermsNConditionsModal(props) {
	const closeButtonStyle = {
		"position": "absolute",
		"marginLeft": "195px",
		"marginTop": "15px",
		"filter": "invert(1)"
	},
	modalContainerStyle = {
		"padding": "0px"
	};

	return (
		<Modal closeHandler={props.closeHandler} closeType={"xButton"}
					closeButtonStyle={closeButtonStyle} modalContainerStyle={modalContainerStyle}>
			<TermsNConditions contentType="modal"/>
		</Modal>
	);
}

TermsNConditionsModal.propTypes = {
	closeHandler: PropTypes.func.isRequired
}