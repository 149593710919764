import messageMap from "Utilities/MessageMaps";

import thumbnailAsset from "assets/topicArticles/Math/Level 01 Math/III- Numbers/thumbnailNumbers.jpg";

export const countingWithPicturesNNumbersArticle = {
	id: "III: Numbers_A. Counting with Pictures and Numbers",
	title: messageMap("countingWithPicturesNNumbers.article.title", "math"),
	description: messageMap("countingWithPicturesNNumbers.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("countingWithPicturesNNumbers.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "html1", "html2", "html3", "html4", "html5", "html6", "html7", "html8", "html9", "html10",
		"text3", "html11",
		"text4", "text5", "html12"
	],
	datePublished: "2024-06-05",
	dateModified: "2024-06-05"
};


