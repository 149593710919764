import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import messageMap from 'Utilities/MessageMaps';

import doubleArrowAsset from "assets/icons/navigation/double-arrow.svg";
import pageArrowAsset from "assets/icons/navigation/page-arrow.svg";

/**
 * @description Reusable pagination component
 * @param {Number} itemCount total item count to define pagination window size
 * @param {Number} pageSize number of item per page
 * @param {Number} currentPage current page within page window
 * @param {Number} totalPageCount total number of pages
 * @param {Function} pageHandler page click handler
 */
function Pagination(props) {
	const numberOfPages = props.itemCount / props.pageSize;
	const finalPageNumber = Math.ceil(numberOfPages);
	const currentPage = props.currentPage;
	let leftMostPage = currentPage - 2 > 0 ? currentPage - 2 : 1;
	let rightMostPage = (leftMostPage + 4) <= finalPageNumber ? (leftMostPage + 4) : finalPageNumber;

	if (rightMostPage === finalPageNumber) {
		leftMostPage = rightMostPage - 4 > 1 ? rightMostPage - 4 : 1;
	}

	let pageNumberNav = [];

	for (let i = leftMostPage; i <= rightMostPage; ++i) {
		const pageClassName = i - 1 === currentPage ? "page-number active" : "page-number";

		pageNumberNav.push(
			<Link to={`${props.baseUrl}?page=${i}`} className={pageClassName}
				onClick={e => scrollToTopOfPage(i - 0, props.totalPageCount)}
				key={`item-${i}-page-${currentPage}`}>
				{i}
			</Link>
		);
	}

	function scrollToTopOfPage(curPage, totalPageCount) {
		window.scrollTo(0, 0);
		props.pageHandler(curPage, totalPageCount);
	}

	return (
		<div className="pagination">
			<button onClick={e => props.pageHandler("first", props.totalPageCount)} className="first-last first">
				<img src={doubleArrowAsset} alt={messageMap("navigation.first", "image")}
						className="first-last-arrow-icon"></img>
			</button>
			<button onClick={e => props.pageHandler("previous", props.totalPageCount)} className="left">
				<img src={pageArrowAsset} alt={messageMap("navigation.previous", "image")}
						className="left-arrow-icon"></img>
			</button>
			{pageNumberNav}
			<button onClick={e => props.pageHandler("next", props.totalPageCount)} className="right">
				<img src={pageArrowAsset} alt={messageMap("navigation.next", "image")}
						className="right-arrow-icon"></img>
			</button>
			<button onClick={e => props.pageHandler("last", props.totalPageCount)} className="first-last last">
				<img src={doubleArrowAsset} alt={messageMap("navigation.last", "image")}
						className="first-last-arrow-icon rotate"></img>
			</button>
		</div>
	);
}

Pagination.propTypes = {
	baseUrl: PropTypes.string.isRequired,
	itemCount: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	totalPageCount: PropTypes.number.isRequired,
	pageHandler: PropTypes.func.isRequired
};

export default Pagination;