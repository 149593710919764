// KEEP FIRST-LEVEL KEYS IN ALPHABETIC ORDER
export const buttonMessageMap = {
	buy: {
		text: "Buy"
	},
	cancel: {
		text: "Cancel"
	},
	checkout: {
		buyNowWithPayPal: "Buy Now with Paypal",
		pay: "Pay"
	},
	clear: {
		text: "Clear",
		notifications: "Clear Notifications"
	},
	comment: {
		text: "Comment"
	},
	delete: {
		text: "Delete"
	},
	here: {
		text: "here"
	},
	navigate: {
		to: "Navigate To"
	},
	prompt: {
		skipAll: "Skip all prompts"
	},
	quotes: {
		continue: "Continue"
	},
	save: {
		notes: "Save Notes",
		tutoringProfile: "Save profile"
	},
	saved: {
		bookmarks: "Saved Bookmarks",
		notes: "Saved Notes"
	},
	submit: {
		text: "Submit"
	},
	next: {
		text: "Next"
	},
	home: {
		joinCta: "Join now"
	},
	update: {
		all: "Update all",
		text: "Update",
		username: "Update username",
		email: "Update email",
		password: "Update password"
	},
	earnings: {
		withdrawEarnings: "Withdraw money"
	},
	tutoring: {
		activeProfile: {
			editProfile: "Edit tutoring profile",
			showProfile: "Show tutoring profile",
			hideProfile: "Hide tutoring profile",
			deleteProfile: "Delete tutoring profile",
			qualifyForMoreSubjects: "Qualify for more subjects"
		},
		noProfile: {
			becomeTutor: "Become a tutor",
			scheduleInterview: "Schedule interview"
		}
	},
	groups: {
		addMember: "Add member",
		copyCode: "Copy code",
		createGroup: "Create group",
		join: "Join",
		leaveGroup: "Leave group",
		shinyNeurons: "Shiny Neurons",
		removeAccount: "Remove account"
	},
	knowledgeTree: {
		search: {
			placeholder: "Search topic"
		},
		view: {
			save: "Save view",
			show: "Show",
			delete: "Delete"
		},
		quest: {
			resume: "Resume Quest",
			start: "Start Quest"
		}
	},
	editVideoPage: {
		filter: "Filter",
		saveChanges: "Save Changes",
		videos: {
			edit: "Edit Video",
			delete: "Delete Video"
		},
		modules: {
			bundle: "Bundle Into Module",
			create: "Create Module",
			edit: "Edit Module",
			delete: "Delete Module",
			update: "Update Module"
		}
	},
	uploadVideoPage: {
		back: "< Back",
		grades: "Grade School",
		degrees: "Degrees",
		jobs: "Jobs"
	},
	learn: {
		details: "Details",
		mentalModel: {
			buttonLocked: "Mental Model (finish video to unlock)",
			buttonUnlocked: "Mental Model",
			download: "Download Model"
		},
		notes: "Notes",
	list: "View all notes"
	},
	practice: {
		text: "Practice",
		answer: "Answer",
		hint: "Hint",
		review: "Review"
	},
	links: {
		termsAndConditions: "Terms & Conditions",
		privacy: "Privacy Policy",
		learn: "Learn",
		aboutUs: "About Us",
		knowledgeTree: "Knowledge Quests",
		topicsInfoList: "Topics",
		practice: "Practice Room",
		profileSettings: "Profile Settings",
		upload: "Upload Knowledge",
		editVideos: "Edit Videos",
		exerShop: "Exer Shop",
		groups: "Groups",
		logOut: "Log Out",
		signUp: "Sign Up",
		login: "Login"
	},
	spinner: {
		action: {
			delete: "Deleting",
			upload: {
				text: "Uploading",
				duration: "This can take a few minutes..."
			},
			update: "Updating"
		},
		fetch: {
			loading: "Loading your video",
			getting: "Getting your video(s)"
		}
	},
	yes: {
		remove: "Yes, remove"
	}
};