import React from 'react';

import messageMap from "Utilities/MessageMaps";

function ClassRequest () {
	return (
		<div className="ClassRequest">
			
			<div className="request-container">
				<h1 className="title">
					{messageMap("class.request.title", "generic")}
				</h1>
				<h2 className="sub-heading">
					{messageMap("class.request.subheader", "generic")}
				</h2>
				<div className="request">
					some text
				</div>
			</div>
		</div>
	);
}

export default ClassRequest;