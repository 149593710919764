import {combineReducers} from "redux";
import account from "redux/reducers/accountReducer";
import apiKeys from "redux/reducers/apiReducer";
import generic from "redux/reducers/genericReducer";
import payments from "redux/reducers/paymentsReducer";
import knowledge from "redux/reducers/knowledgeReducer";
import subNavs from "redux/reducers/subNavReducer";

export default combineReducers({
	account, apiKeys, generic, payments, knowledge, subNavs
});