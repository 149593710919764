// KEEP FIRST-LEVEL KEYS IN ALPHABETIC ORDER
export const validationWordMap = {
	account: {
		email: {
			invalid: "Invalid email.",
			sameAsOld: "Email is the same as the old email.",
		},
		emailConfirm: {
			noMatch: "Email address doesn't match."
		},
		username: {
			invalid: "Invalid username.",
			sameAsOld: "Username is the same as the old username.",
			title: "Username must be",
			text: "At least 5 characters long, maximum 20 characters long. Only alphanumeric characters."
		},
		password: {
			invalid: "Invalid password.",
			sameAsOld: "Password is the same as the old password.",
			title: "Passwords must",
			text1: "Be at least 7 characters long",
			text2: "Contain at least 1 uppercase letter",
			text3: "Contain at least 1 lowercase letter",
			text4: "Contain at least 1 number",
			text5: "Contain at least 1 one of the following special characters: !@#$%^&*"
		},
		passwordConfirm: {
			noMatch: "Password doesn't match."
		},
		usernamePassword: "Incorrect username/password",
		update: "Confirm Update"
	},
	action: {
		needLogin: "Please login first."
	},
	img: {
		dimensions: {
			width: "Thumbnail width is under 640 pixels. Please find a bigger image."
		},
		fileSize: {
			over2MB: "Thumbnail file size is more than 2MB. Please consider compressing the file or reducing the dimensions to lower the file size."
		},
		fileType: {
			notAccepted: "Thumbnail file extension isn't png, jpg, jpeg, or webp."
		},
		filename: {
			noSpaces: "No spaces allowed in image filenames."
		}
	},
	input: {
		explicit: {
			text: "Detected explicit input",
			content: "Detected explicit content",
			textEditor: {
				videoDescription: "Detected explicit input in video description"
			},
			input: {
				username: "Detected explicit input in username"
			}
		},
		knowledge: {
			viewName: "Missing view name"
		},
		module: {
			missing: "Please indicate the module's name."
		}
	},
	video: {
		action: {
			delete: "Confirm Deletion",
			deleteSubheader: "Deleting: "
		},
		codec: {
			audio: {
				invalid: "Invalid audio codec. We only accept mp4.",
				missing: "Could not detect any audio tracks. Please make sure that your video has audio."
			},
			video: {
				invalid: "Invalid video codec. We only accept 'mp4v', 'avc1', 'avc2', 'vp9', 'vp09', and 'av1'.",
				missing: "Could not detect any video tracks. Please make sure that you video has video."
			}
		},
		fileType: {
			notAccepted: "Video file extension isn't mp4."
		},
		lengthLimit: {
			ten: "Video length is over 10 minutes. Please break your video into multiple parts.",
			fifteenSec: "Video length is under 15 seconds. Please include more content."
		},
		sizeLimit: {
			over128GB: "Video file size exceeded 128GB. Please consider compressing the video, or changing its framerate, resolution, or bitrate."
		},
		mentalModel: {
			over5Files: "No more than 5 files."
		}
	},
	module: {
		memberCount: "You need at least 2 videos per module."
	},
	practice: {
		bag: {
			notEnough: {
				hints: "Not enough hints.",
				answers: "Not enough answers.",
				levels: "Not enough levels.",
			}
		}
	}
};