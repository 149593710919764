import React, {useRef, useEffect, useState, Fragment} from 'react';
import {Helmet} from "react-helmet";

import TableOfContents from "templates/TableOfContents";

import {videoGuidelinesSchema, tableOfContentsSchema, tableOfContentsChevRefSchema} from "pages/Classes/Videos/schemas/uploadVideoSchemas";
import VideoUpload from "pages/Classes/Videos/components/VideoUpload";

import messageMap from "Utilities/MessageMaps";

export default function UploadVideo(props) {
	const [videoDetailsDom, setVideoDetailsDom] = useState(null);

				// DOM Refs for table of contents
	const informationRef = useRef(),
				deliveryRef = useRef(),
				attractionRef = useRef(),
				accessibilityRef = useRef(),
				miscellaneousRef = useRef(),
				createContentRef = useRef(),
				// chevron Refs for toggling
				infoChevRef = useRef(),
				deliveryChevRef = useRef(),
				attractionChevRef = useRef(),
				accessibilityChevRef = useRef(),
				miscChevRef = useRef();

	// static DOM-related effects, that will only need to be rendered once
	useEffect(() => {
		renderVideoDetails();
	}, []);

	function toggleDetails(ref, chevronRef) {
		const className = ref.current.className;

		if (className === "details") {
			ref.current.className = className.replace("details", "details-collapsed");
		}
		else if (className === "details-collapsed") {
			ref.current.className = className.replace("details-collapsed", "details");
		}

		chevronRef.current.className = chevronRef.current.className === "chevron-down" ? "chevron-up" : "chevron-down";
	}

	function renderVideoDetails() {
		const videoGuidelineMap = videoGuidelinesSchema({
			information: {
				chevRef: infoChevRef,
				detailsDomRef: informationRef
			},
			delivery: {
				chevRef: deliveryChevRef,
				detailsDomRef: deliveryRef
			},
			attraction: {
				chevRef: attractionChevRef,
				detailsDomRef: attractionRef
			},
			accessibility: {
				chevRef: accessibilityChevRef,
				detailsDomRef: accessibilityRef
			},
			miscellaneous: {
				chevRef: miscChevRef,
				detailsDomRef: miscellaneousRef
			}
		});

		let detailsDom = [];

		for (const map in videoGuidelineMap) {
			const elObj = videoGuidelineMap[map];
			const elObjBody = elObj.body;
			let subSections = [];
			let keyCount = 0;

			elObjBody.forEach(elObjs => {
				let domDetails = createDomDetails(elObjs.details, "A", map);

				subSections.push(
					<div key={`${map}-${++keyCount}`}>
						<h3 className="detail-subheader">
							{elObjs.header}
						</h3>
						{domDetails}
					</div>
				);
			});

			detailsDom.push(
				<div className="detail-container" key={elObj.title}>
					<div className="detail-header" role="button" tabIndex={0}
								onClick={() => toggleDetails(elObj.detailsDomRef, elObj.chevronRef)}
								onKeyPress={() => toggleDetails(elObj.detailsDomRef, elObj.chevronRef)}>
						<div className="detail-item">
							<div className="detail-img-container">
								<img className="detail-img" 
										src={elObj.img} alt={elObj.alt}></img>
							</div>
							<h2 className="detail-description">
								{elObj.title}
							</h2>
							<div className="chevron-up" ref={elObj.chevronRef}></div>
						</div>
					</div>

					<div ref={elObj.detailsDomRef} className="details">
						{subSections}
					</div>

					<div>

					</div>
				</div>
			);
		}

		setVideoDetailsDom(
			<Fragment>
				{detailsDom}
			</Fragment>
		);
	}

	// types order: A, 1, a, i, ul-circle, ul-square
	function createDomDetails(domSchema, type, key) {
		var domDetails = [];

		Array.isArray(domSchema) && domSchema.forEach(schemata => {
			let nextListType;

			switch (type) {
				case "A":
					nextListType = "1";
					break;
				case "1":
					nextListType = "a";
					break;
				case "a":
					nextListType = "i";
					break;
				case "i":
					nextListType = "ul-circle";
					break;
				case "ulCir":
				default:
					nextListType = "ul-square";
					break;
			}

			let recursedList;
			if (schemata.details) {
				recursedList = createDomDetails(schemata.details, nextListType, key);
			}

			let imgEx;
			if (schemata.img) {
				imgEx = (
					<img src={schemata.img} alt={schemata.alt}></img>
				);
			}

			if (!type.includes("ul")) {
				domDetails.push(
					<ol key={schemata.text}>
						<li>
							{schemata.text}
							{imgEx}
							{recursedList}
						</li>
					</ol>
				);
			}
			else {
				domDetails.push(
					<ul type={type.split("-")[1]} key={schemata.text}>
						<li>
							{schemata.text}
							{imgEx}
							{recursedList}
						</li>
					</ul>
				);
			}
		});

		return domDetails;
	}

	return (
		<div className="upload-video">
			<Helmet>
				<title>{messageMap("uploadVideo.title", "headerTag")}</title>
				<meta name="description" content={messageMap("uploadVideo.description", "headerTag")}></meta>
			</Helmet>


			<h1 className="title">
				{messageMap("video.uploadPage.title", "generic")}
			</h1>
			<div className="subheader">
				{messageMap("video.uploadPage.subheader", "generic")}
			</div>
			<div className="subheader-note">
				{messageMap("video.uploadPage.note", "generic")}
			</div>

			<div className="video-details">
				<TableOfContents list={tableOfContentsSchema} defaultVisibility="hide"
												iconNameToRefMap={tableOfContentsChevRefSchema(informationRef, deliveryRef, attractionRef, accessibilityRef, miscellaneousRef, createContentRef)}>
				</TableOfContents>
				{videoDetailsDom}
			</div>

			<h2 className="title" ref={createContentRef}>
				{messageMap("video.uploadPage.uploadTitle", "generic")}
			</h2>
			<div className="subheader">
				{messageMap("video.uploadPage.uploadSubheader", "generic")}
			</div>

			<VideoUpload />

		</div>
	);
}