import React, {Fragment} from "react";
import {Link} from "react-router-dom";

import messageMap from "Utilities/MessageMaps";

import userAsset from "assets/icons/navigation/user.svg";
import lockAsset from "assets/icons/navigation/lock.svg";
import emailAsset from "assets/icons/navigation/email.svg";
import keyAsset from "assets/icons/navigation/key.svg";

export const modalLawSchema = {
	closeButtonStyle: {
		"position": "absolute",
		"marginLeft": "195px",
		"marginTop": "15px",
		"filter": "invert(1)"
	},
	modalContainerStyle: {
		"padding": "0px"
	}
};

export function modalSchema(closeModal, openAnotherModal, checkUsername, miscArgs) {
	let signUpInputs = [
		{
			label: messageMap("account.fields.username", "generic"),
			maxLength: 20,
			required: true,
			value: "signUpUser",
			type: "username",
			icon: userAsset,
			onChangeValidationRef: "confirmUserRef",
			tooltip: {
				classStr: "tooltip-bottom-left",
				subheader: messageMap("account.username.title", "validation"),
				type: "username",
				list: [
					{
						key: "hasLength",
						text: messageMap("account.username.text", "validation")
					},
				]
			},
			additionalElems: [
				{
					type: "button",
					props: {
						className: "not-allowed",
						onClick: checkUsername,
						ref: "confirmUserRef",
						style: {
							"color": "white",
							"fontSize": "12px",
							"borderRadius": "5px",
							"border": "none",
							"backgroundColor": "#34B2E3",
							"padding": "2.5px 5px",
							"marginTop": "5px"
						}
					},
					children: [
						messageMap("account.fields.usernameAvailability", "generic")
					],
					onClickArgs: ["event", "signUpUser"]
				}
			]
		}, {
			label: messageMap("account.fields.email", "generic"),
			required: true,
			value: "signUpEmail",
			type: "email",
			icon: emailAsset
		}, {
			label: messageMap("account.fields.password", "generic"),
			required: true,
			value: "signUpPass",
			type: "password",
			togglePassText: true,
			icon: lockAsset,
			tooltip: {
				classStr: "tooltip-bottom-left",
				subheader: messageMap("account.password.title", "validation"),
				type: "password",
				list: [
					{
						key: "hasLength",
						text: messageMap("account.password.text1", "validation")
					},
					{
						key: "hasUpper",
						text: messageMap("account.password.text2", "validation")
					},
					{
						key: "hasLower",
						text: messageMap("account.password.text3", "validation")
					},
					{
						key: "hasNumber",
						text: messageMap("account.password.text4", "validation")
					},
					{
						key: "hasSpecial",
						text: messageMap("account.password.text5", "validation")
					}
				]
			}
		}, {
			label: messageMap("account.fields.passwordConfirm", "generic"),
			required: true,
			value: "signUpPassConfirm",
			type: "passwordConfirm",
			icon: lockAsset,
			onEnter: true
		}
	];
	let signUpStateProps = [
		{
			state: "signUpUser",
			defValue: {
				isValid: false,
				value: ""
			}
		}, {
			state: "signUpEmail",
			defValue: {
				isValid: false,
				value: ""
			}
		}, {
			state: "signUpPass",
			defValue: {
				isValid: false,
				value: ""
			}
		}, {
			state: "signUpPassConfirm",
			defValue: {
				isValid: false,
				value: ""
			}
		}, {
			state: "signUpPassTooltip"
		}, {
			state: "signUpUserTooltip"
		}
	];

	let signUpLoginModalOverlayCount = 1;

	if (miscArgs && miscArgs.signUp) {
		if (miscArgs.signUp.loginModalOverlayCount) {
			signUpLoginModalOverlayCount = miscArgs.signUp.loginModalOverlayCount;
		}
		if (miscArgs.signUp.accessCode) {
			signUpInputs.push(
				{
					label: messageMap("account.fields.accessKey", "generic"),
					required: true,
					value: "signUpAccessKey",
					icon: keyAsset,
					alt: messageMap("account.username", "image")
				}
			);
			signUpStateProps.push(
				{
					state: "signUpAccessKey",
					defValue: ""
				}
			);
		}
	}

	return {
		"signUp": {
			// TODO: use when people are applying for credits
			// subHeader: (
			// 	<div>
			// 		{messageMap("account.signUp.creditSignUp.text", "generic")}
			// 		{/* to="/signUp/createFullAccount" */}
			// 		<Link to="/learn" className="fullRegistration" onClick={closeModal} onKeyPress={closeModal}>
			// 			{messageMap("account.signUp.creditSignUp.now", "generic")}
			// 		</Link>
			// 	</div>
			// ),
			subHeader: (
				<div>
					{messageMap("account.signUp.existingAccount", "generic")}
					<button onClick={() => openAnotherModal("login", signUpLoginModalOverlayCount)} onKeyPress={() => openAnotherModal("login", signUpLoginModalOverlayCount)}
						style={{border: "none", color: "#007bff", backgroundColor: "white"}}>
						{messageMap("links.login", "button")}
					</button>
				</div>
			),
			disclaimer: (
				<Fragment>
					<div>
						{messageMap("account.signUp.agreement.text", "generic")}
						<button className="terms-policy" onClick={() => openAnotherModal("terms", 1)} onKeyPress={() => openAnotherModal("terms", 1)}>
							{messageMap("account.signUp.agreement.termOfUse", "generic")}
						</button>
						{messageMap("account.signUp.agreement.and", "generic")}
						<button className="terms-policy" onClick={() => openAnotherModal("privacy", 1)} onKeyPress={() => openAnotherModal("privacy", 1)}>
							{messageMap("account.signUp.agreement.privacyPolicy", "generic")}
						</button>
					</div>
				</Fragment>
			),
			inputs: signUpInputs,
			stateProps: signUpStateProps
		},
		"login": {
			subHeader: (
				<div>
					{messageMap("account.login.noAccount", "generic")}
					<button onClick={() => openAnotherModal("signUp", signUpLoginModalOverlayCount)} onKeyPress={() => openAnotherModal("login", signUpLoginModalOverlayCount)}
						style={{border: "none", color: "#007bff", backgroundColor: "white"}}>
						{messageMap("links.signUp", "button")}
					</button>
				</div>
			),
			inputs: [
				{
					label: messageMap("account.fields.username", "generic"),
					value: "loginUser",
					icon: userAsset,
					alt: messageMap("account.username", "image")
				},
				{
					label: messageMap("account.fields.password", "generic"),
					value: "loginPass",
					type: "passwordLogin",
					icon: lockAsset,
					alt: messageMap("account.passwordLock", "image"),
					onEnter: true
				}
			],
			stateProps: [
				{
					state: "loginUser",
					defValue: ""
				}, {
					state: "loginPass",
					defValue: ""
				}
			]
		},
		"earlyAccess": {
			subHeader: (
				<Fragment>
					<div>
						{messageMap("account.signUp.existingAccount", "generic")}
						<button onClick={() => openAnotherModal("login", 1)} onKeyPress={() => openAnotherModal("login", 1)}
							style={{border: "none", color: "#007bff", backgroundColor: "white"}}>
							{messageMap("links.login", "button")}
						</button>
					</div>
					<br></br>
					<div>
						{messageMap("account.access.futureAndSharingAccess.subHeader", "generic")}
						<button onClick={() => openAnotherModal("signUp", 1)} onKeyPress={() => openAnotherModal("signUp", 1)}
							style={{border: "none", color: "#007bff", backgroundColor: "white"}}>
							{messageMap("links.signUp", "button")}.
						</button>
						<br/>
						{messageMap("account.access.futureAndSharingAccess.later", "generic")}
					</div>
				</Fragment>
			),
			inputs: [
				{
					label: messageMap("account.fields.accessKey", "generic"),
					required: true,
					value: "accessKey",
					icon: keyAsset,
					alt: messageMap("account.username", "image")
				}
			],
			stateProps: [
				{
					state: "accessKey",
					defValue: ""
				}
			]
		}
	}
}