import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/physiology/thumbnailAcquiringAndRefining1.jpg";
import sensoryKnowledgeAsset from "assets/topicArticles/LearningOptimizations/physiology/sensoryKnowledge.png";
import implicitExplicitLearningAsset from "assets/topicArticles/LearningOptimizations/physiology/implicitExplicitLearning.png";
import biologicallyPrimarySecondaryMaterialAsset from "assets/topicArticles/LearningOptimizations/physiology/biologicallyPrimarySecondaryMaterial.png";
import implicitExplicitLearningConnectionAsset from "assets/topicArticles/LearningOptimizations/physiology/implicitExplicitLearningConnection.png";


export const physiologyAcquiringAndRefining1Article = {
	id: "V. Physiology_4_4 How knowledge is acquired and refined (Part 1)",
	title: messageMap("acquiringAndRefining1.article.title", "learningOptimization"),
	description: messageMap("acquiringAndRefining1.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("acquiringAndRefining1.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2",
		"text3", "text4", "text5", "text6", "text7", "text8", "html1",
		"text9", "text10", "html2", "text11",
		"text12", "text13", "text14",
		"text15", "text16", "text17", "text18",
		"text19", "text20", "text21", "text22", "text23", "html3", "text24", "text25", "html4",
		"text26", "text27", "text28", "text29", "text30", "text31",
		"text32", "text33",  "text34",
		"text35", "text36"
	],
	datePublished: "2023-12-29",
	dateModified: "2024-06-26"
};


export const physiologyAcquiringAndRefining1ArticleTexts = {
	text1: {
		text: messageMap("efficientStorage.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("efficientStorage.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("efficientStorage.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("efficientStorage.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("efficientStorage.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text6: {
		text: messageMap("efficientStorage.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("efficientStorage.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("efficientStorage.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text9: {
		text: messageMap("efficientStorage.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text10: {
		text: messageMap("efficientStorage.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text11: {
		text: messageMap("efficientStorage.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("efficientStorage.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text13: {
		text: messageMap("efficientStorage.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("efficientStorage.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("efficientStorage.texts.header5", "learningOptimization"),
		type: SUB_HEADER
	},
	text16: {
		text: messageMap("efficientStorage.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text17: {
		text: messageMap("efficientStorage.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text18: {
		text: messageMap("efficientStorage.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("efficientStorage.texts.header6", "learningOptimization"),
		type: SUB_HEADER
	},
	text20: {
		text: messageMap("efficientStorage.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("efficientStorage.texts.p15", "learningOptimization"),
		type: DESCRIPTION
	},
	text22: {
		text: messageMap("efficientStorage.texts.p16", "learningOptimization"),
		type: DESCRIPTION
	},
	text23: {
		text: messageMap("efficientStorage.texts.p17", "learningOptimization"),
		type: DESCRIPTION
	},
	text24: {
		text: messageMap("efficientStorage.texts.p18", "learningOptimization"),
		type: DESCRIPTION
	},
	text25: {
		text: messageMap("efficientStorage.texts.p19", "learningOptimization"),
		type: DESCRIPTION
	},
	text26: {
		text: messageMap("efficientStorage.texts.header7", "learningOptimization"),
		type: SUB_HEADER
	},
	text27: {
		text: messageMap("efficientStorage.texts.p20", "learningOptimization"),
		type: DESCRIPTION
	},
	text28: {
		text: messageMap("efficientStorage.texts.p21", "learningOptimization"),
		type: DESCRIPTION
	},
	text29: {
		text: messageMap("efficientStorage.texts.p22", "learningOptimization"),
		type: DESCRIPTION
	},
	text30: {
		text: messageMap("efficientStorage.texts.p23", "learningOptimization"),
		type: DESCRIPTION
	},
	text31: {
		text: messageMap("efficientStorage.texts.p24", "learningOptimization"),
		type: DESCRIPTION
	},
	text32: {
		text: messageMap("efficientStorage.texts.header8", "learningOptimization"),
		type: SUB_HEADER
	},
	text33: {
		text: messageMap("efficientStorage.texts.p25", "learningOptimization"),
		type: DESCRIPTION
	},
	text34: {
		text: messageMap("efficientStorage.texts.p26", "learningOptimization"),
		type: DESCRIPTION
	},
	text35: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text36: {
		text: messageMap("efficientStorage.sources.s1", "learningOptimization"),
		type: SOURCE
	}
};

export const physiologyAcquiringAndRefining1ArticleHTMls = {
	html1: (
		<img key="sensoryKnowledgeAsset" src={sensoryKnowledgeAsset} alt="information from sensory knowledge can go to either implicit or explicit learning" style={{width: "-webkit-fill-available"}} />
	),
	html2: (
		<img key="implicitExplicitLearningAsset" src={implicitExplicitLearningAsset} alt="implicit learning leading to tacit knowledge and explicit learning leading to strings, ideas, or schema" style={{width: "-webkit-fill-available"}} />
	),
	html3: (
		<img key="biologicallyPrimarySecondaryMaterialAsset" src={biologicallyPrimarySecondaryMaterialAsset} alt="biologically primary material going to implicit learning and biologically secondary material going to explicit learning" style={{width: "-webkit-fill-available"}} />
	),
	html4: (
		<img key="implicitExplicitLearningConnectionAsset" src={implicitExplicitLearningConnectionAsset} alt="both implicit and explicit learning draw from long-term memory" style={{width: "-webkit-fill-available"}} />
	),
};


