import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";

function TermsNConditions(props) {
	window.scrollTo(0, 0);
	const classStr = props.contentType === "modal" ? "modal-container-terms" : "agreement-container";

	return (
		<div className="TermsNConditions">
			<Helmet>
				<title>{messageMap("termsNConditions.title", "headerTag")}</title>
				<meta name="description" content={messageMap("termsNConditions.description", "headerTag")}></meta>
			</Helmet>

			<div className={classStr}>
				<h1 className="title">
					{messageMap("termsNConditions.title", "document")}
				</h1>
				<div className="agreement">
					<p className="margin-bottom-spacing">
						{messageMap("termsNConditions.header.date", "document")}
					</p>
					<p className="margin-bottom-spacing">
						{messageMap("termsNConditions.header.tNC", "document")}
					</p>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section1.title", "document")}
						</h2>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section1.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section1.point1.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section1.point1.point2.text", "document")}
							</p>
						</div>
						
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section1.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section1.point2.point1.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point1.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point1.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point2.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point2.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point3.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point3.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point4.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point4.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point5.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point5.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point6.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point6.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point7.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point7.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point8.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point8.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point9.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point9.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point10.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point10.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point11.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point11.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point12.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point12.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point13.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point13.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point14.term", "document")}
								</span> 
								{messageMap("termsNConditions.section1.point2.point1.point14.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point15.term", "document")}
								</span> 
								{messageMap("termsNConditions.section1.point2.point1.point15.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point16.term", "document")}
								</span>
								{messageMap("termsNConditions.section1.point2.point1.point16.text", "document")}
								<a href="https://www.exerinstitute.com">https://www.exerinstitute.com</a>
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("termsNConditions.section1.point2.point1.point17.term", "document")}
								</span> 
								{messageMap("termsNConditions.section1.point2.point1.point17.text", "document")}
							</p>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section2.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section2.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section2.point2.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section2.point3.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section2.point4.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section2.point5.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section3.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section3.subpoint1.text", "document")}
						</p>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section3.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point1.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point1.point2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point1.point3.text", "document")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section3.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point2.subpoint1.text", "document")}
							</p>
							<ul>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section3.point2.subpoint1.point1.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section3.point2.subpoint1.point2.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section3.point2.subpoint1.point3.text", "document")}
								</p></li>
							</ul>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point2.subpoint2.text", "document")}
							</p>
							<h3 className="western">
								{messageMap("termsNConditions.section3.point2.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point2.point1.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point2.point1.point2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point2.point1.point3.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point2.point1.point4.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point2.point1.point5.text", "document")}
							</p>
							<ul>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section3.point2.point1.point5.point1.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section3.point2.point1.point5.point2.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section3.point2.point1.point5.point3.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section3.point2.point1.point5.point4.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section3.point2.point1.point5.point5.text", "document")}
								</p></li>
							</ul>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section3.point3.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point3.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point3.point2.text", "document")}
							</p>
						</div>
						
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section3.point4.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point4.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point4.point2.text", "document")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section3.point5.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point5.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section3.point5.point2.text", "document")}
							</p>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section4.title", "document")}
						</h2>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section4.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point1.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point1.point2.text", "document")}
							</p>
						</div>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section4.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point2.point1.text", "document")}
							</p>
						</div>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section4.point3.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point3.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point3.point2.text", "document")}
							</p>
						</div>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section4.point4.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point4.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point4.point2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point4.point3.text", "document")}
							</p>
						</div>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section4.point5.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point5.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point5.point2.text", "document")}
							</p>
						</div>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section4.point6.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point6.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point6.point2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point6.point3.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section4.point6.point4.text", "document")}
							</p>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section5.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section5.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section5.point2.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section6.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section6.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section6.point2.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section6.point3.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section6.point4.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section7.title", "document")}
						</h2>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section7.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section7.point1.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section7.point1.point2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section7.point1.point3.text", "document")}
							</p>
						</div>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section7.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section7.point2.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section7.point2.point2.text", "document")}
							</p>
							<ul>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section7.point2.point2.point1.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section7.point2.point2.point2.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section7.point2.point2.point3.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section7.point2.point2.point4.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section7.point2.point2.point5.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section7.point2.point2.point6.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section7.point2.point2.point7.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section7.point2.point2.point8.text", "document")}
								</p></li>
							</ul>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section7.point2.point3.text", "document")}
							</p>
						</div>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section7.point3.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section7.point3.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section7.point3.point2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section7.point3.point3.text", "document")}
							</p>
						</div>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section7.point4.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section7.point4.point1.text", "document")}
							</p>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section8.title", "document")}
						</h2>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section8.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section8.point1.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section8.point1.point2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section8.point1.point3.text", "document")}
							</p>
						</div>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section8.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section8.point2.point1.text", "document")}
							</p>
							<ul>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section8.point2.point1.point1.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section8.point2.point1.point2.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section8.point2.point1.point3.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section8.point2.point1.point4.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section8.point2.point1.point5.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("termsNConditions.section8.point2.point1.point6.text", "document")}
								</p></li>
							</ul>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section8.point2.point2.text", "document")}
							</p>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section9.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section9.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section9.point2.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section9.point3.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section10.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section10.point1.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section11.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section11.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section11.point2.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section11.point3.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section12.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section12.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section12.point2.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section13.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section13.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section13.point2.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section13.point3.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section14.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section14.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section14.point2.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section14.point3.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section15.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section15.point1.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section16.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section16.point1.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section17.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section17.point1.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section18.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section18.point1.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section19.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section19.point1.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section20.title", "document")}
						</h2>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section20.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section20.point1.point1.text", "document")}
							</p>
						</div>
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("termsNConditions.section20.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("termsNConditions.section20.point2.point1.text", "document")}
							</p>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section21.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section21.point1.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section22.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section22.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section22.point2.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("termsNConditions.section23.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("termsNConditions.section23.point1.text", "document")}
						</p>
						<p className="margin-left-bottom">
							{messageMap("termsNConditions.section23.point2.text", "document")}
						</p>
						<p className="margin-left-bottom">
							{messageMap("termsNConditions.section23.point3.text", "document")}
							<a href="https://exerinstitute.com">https://exerinstitute.com</a>
						</p>
					</div>

				</div>
			</div>
		</div>
	);
}

TermsNConditions.propTypes = {
	contentType: PropTypes.string
};

export default TermsNConditions;