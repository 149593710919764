import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import CountableObjects from "assets/topicArticles/Math/components/templates/CountableObjects";

import thumbnailAsset from "assets/topicArticles/Math/Level 05 Math/I- Numbers and Ordering/thumbnailShell.jpg";


export const countingTo100UsingNumeralPatternsArticle = {
	id: "I: Numbers and Ordering_A. How to Count to 100 using Numeral Patterns Skip Counting by 10s",
	title: messageMap("countingTo100UsingNumeralPatterns.article.title", "math"),
	description: messageMap("countingTo100UsingNumeralPatterns.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("countingTo100UsingNumeralPatterns.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "text3", "html1"
	],
	datePublished: "2024-06-20",
	dateModified: "2024-06-20"
};

export const countingTo100UsingNumeralPatternsArticleTexts = {
	text1: {
		text: messageMap("countingTo100UsingNumeralPatterns.countingTo100UsingNumeralPatternsArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("countingTo100UsingNumeralPatterns.countingTo100UsingNumeralPatternsArticleTexts.text2", "math"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("countingTo100UsingNumeralPatterns.countingTo100UsingNumeralPatternsArticleTexts.text3", "math"),
		type: DESCRIPTION
	}
};

export const countingTo100UsingNumeralPatternsArticleHTMLs = {
	html1: (
		<CountableObjects key={"countableObjects"} widthClass="whole-width" 
			objectCount={1} countable={true} changeableSteps={true} />
	)
};

