import degreesAsset from "assets/visData/degrees.xlsx";
import jobsAsset from "assets/visData/jobs.xlsx";
import gradesAsset from "assets/visData/grades.xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import {getLocalData, GET} from "Utilities/Fetches";
import {createPrefixTree, createTokenizedPrefixTreeWithReferencing} from "Utilities/Algos";
import {
	WORKBOOK_GROUP_NUMBERS_TO_ROOT_NODES_MAP, WORKBOOK_TO_QUESTABLE_ROOT_NODES_MAP,
	GRADE_SCHOOL_WORKBOOK, DEGREES_WORKBOOK, JOBS_WORKBOOK
} from "./NetworkConstants";

const TREE_TYPES = [JOBS_WORKBOOK, DEGREES_WORKBOOK, GRADE_SCHOOL_WORKBOOK];
const workbookMap = {
	[GRADE_SCHOOL_WORKBOOK]: gradesAsset,
	[DEGREES_WORKBOOK]: degreesAsset,
	[JOBS_WORKBOOK]: jobsAsset
};

/********		Page SPECIFIC Variables		**********/
// tracks current workbook in use
let WORKBOOK_NAME = [DEGREES_WORKBOOK];


/********		Page AGNOSTIC Variables		**********/
export var NODES_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: [],
	[DEGREES_WORKBOOK]: [],
	[JOBS_WORKBOOK]: []
};
export var LINKS_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: [],
	[DEGREES_WORKBOOK]: [],
	[JOBS_WORKBOOK]: []
};
// used to prevent duplicate NODE and LINK elements
var ID_TRACKER_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: [],
	[DEGREES_WORKBOOK]: [],
	[JOBS_WORKBOOK]: []
};
var ORIG_ID_LIST_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: [],
	[DEGREES_WORKBOOK]: [],
	[JOBS_WORKBOOK]: []
};
export var KNOWLEDGE_TREE_HEIGHT_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: 0,
	[DEGREES_WORKBOOK]: 0,
	[JOBS_WORKBOOK]: 0
};
export var KNOWLEDGE_TREE_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: {},
	[DEGREES_WORKBOOK]: {},
	[JOBS_WORKBOOK]: {}
};
var groupNumber_map = {
	[GRADE_SCHOOL_WORKBOOK]: 0,
	[DEGREES_WORKBOOK]: 0,
	[JOBS_WORKBOOK]: 0
};

// DATA TRANSFORMATIONS
// Will be used for both knowledge quests page and, eventually, general video search
// Contains IDs for degree tree (from degrees down to school degrees and trade school certifications) and grade school tree
export var KNOWLEDGE_NETWORK_IDS_TRIE_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: {},
	[DEGREES_WORKBOOK]: {},
	[JOBS_WORKBOOK]: {}
};
export var AUGMENTED_KNOWLEDGE_NETWORK_IDS_TRIE_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: {},
	[DEGREES_WORKBOOK]: {},
	[JOBS_WORKBOOK]: {}
};
export var ID_TO_OBJECT_KEYS_MAP_MAP = {
	[GRADE_SCHOOL_WORKBOOK]: {},
	[DEGREES_WORKBOOK]: {},
	[JOBS_WORKBOOK]: {}
};


export function generateAllKnowledgeNetworks(callback) {
	// nested callbacks are to prevent race conditions
	generateKnowledgeNetwork("root", 1, 0, GRADE_SCHOOL_WORKBOOK, "id", () => {
		generateKnowledgeNetwork("root", 1, 0, DEGREES_WORKBOOK, "id", () => {
			generateKnowledgeNetwork("root", 1, 0, JOBS_WORKBOOK, "id", () => {
				callback && callback();
			});
		});
	});
}

export function getQuestDetailsFromId(id) {
	let questPathDetails;

	const workbooks = [GRADE_SCHOOL_WORKBOOK, DEGREES_WORKBOOK, JOBS_WORKBOOK];

	for (let i = 0; i < workbooks.length; ++i) {
		const subTreeDetails = getSubtreeFromId(id, 0, workbooks[i], true);
		if (subTreeDetails && Object.keys(subTreeDetails).length && Object.keys(subTreeDetails[0]).length) {
			questPathDetails = subTreeDetails;
			break;
		}
	}

	return questPathDetails;
}


/**
 * @description 
 * @param {String} id identifier which would serve as root node
 * @param {Integer} depth to determine number of nodes to display from root
 * @param {Integer} resolution zoom level
 * @param {Function} callbackHandler to be executed after creating network nodes and links
 * @param {String} workbookName name of workbook to generate the tree from
 */
export function generateKnowledgeNetwork(id = "root", depth, resolution = 0, workbookName, sortType = "id", callbackHandler) {
	groupNumber_map[workbookName] = id === "root" ? 0 : groupNumber_map[workbookName];

	let callback = function() {
		NODES_MAP[workbookName] = [];
		LINKS_MAP[workbookName] = [];
		ID_TRACKER_MAP[workbookName] = [];

		const subTree = getSubtreeFromId(id, resolution, workbookName)[0];
		// const idsList = createKeysList(subTree);

		for (const [key, value] of Object.entries(subTree)) {
			ID_TRACKER_MAP[workbookName].push(key);
			NODES_MAP[workbookName].push({
				id: key,
				label: value.label,
				rootLabel: ["subject", "job", "degree"].includes(value.type) ? value.label : null,
				groupNum: value.group,
				description: value.description,
				height: value.height,
				depth: value.depth,
				availability: value.status,
				practiceCategory: value.practiceCategory,
				practiceID: value.practiceID,
				practiceArgs: value.practiceArgs,
				order: value.order,
				type: value.type,
				structuredData: value.structuredData,
				hasChildren: Object.keys(value.children).length ? true : false,
				relationship: "dependency"
			});
			createNetworkHierarchy(key, key, subTree[key], 0, depth, workbookName);
		}

		sortNodesByKey(sortType, workbookName);
		callbackHandler && callbackHandler(resolution);
	}

	if (!Object.keys(KNOWLEDGE_TREE_MAP[workbookName]).length || WORKBOOK_NAME !== workbookName) {
		generateTree(callback, workbookName);
	}
	else {
		callback();
	}
}

// Need to sort nodes for faster searches
function sortNodesByKey(keyStr, workbookName) {
	NODES_MAP[workbookName].sort((a, b) => {
		return a[keyStr] < b[keyStr] ? -1 : 1;
	});
}

/**
 * @description reads an excel file containing the 'degrees' sheet to create data structures to be used to generate knowledge tree(s)
 * @param {Function} callback function to be called after creating the tree
 */
function generateTree(callback, workbookName) {
	WORKBOOK_NAME = workbookName;
	// getLocalData(GET, degreesAsset, null, "arraybuffer", e => {
	getLocalData(GET, workbookMap[workbookName], null, "arraybuffer", e => {
		const requestData = new Uint8Array(e.target.response)
		const workbook = XLSX.read(requestData, { type: 'array' })

		// TODO: fix this to account for multiple sheets within a workbook
		workbook.SheetNames.forEach(sheetName => {
			// 'Degrees' is for production and 'TestSheet' is for, well, testing...
			// NOTE*: Even though we have several tree_types, the code is only currently designed for just 'Degrees'
			if (TREE_TYPES.includes(sheetName)) {
				let xlsData = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
				createTree(xlsData, workbookName);
				callback();
			}
		});

		createPrefixTree(ORIG_ID_LIST_MAP[workbookName], KNOWLEDGE_NETWORK_IDS_TRIE_MAP[workbookName], true);
		createTokenizedPrefixTreeWithReferencing(ORIG_ID_LIST_MAP[workbookName], AUGMENTED_KNOWLEDGE_NETWORK_IDS_TRIE_MAP[workbookName], true);
	});
}

function createTree(list, workbookName) {
	// creates a flat hash map <topic, {description, children, etc.}>
	let flatMap = {};
	const parsedData = createFlatMapNIdList(list, workbookName);
	flatMap = parsedData[0];
	ID_TO_OBJECT_KEYS_MAP_MAP[workbookName] = parsedData[0];
	ORIG_ID_LIST_MAP[workbookName] = parsedData[1];
	
	let treeVal = recurseWMap("root", 0, 0, workbookName);
	// form root to farthest leaf
	KNOWLEDGE_TREE_HEIGHT_MAP[workbookName] = treeVal.height + 1;
	KNOWLEDGE_TREE_MAP[workbookName] = {
		root: treeVal
	};


	// recurses through flat hash map to create tree
	function recurseWMap(id, height, depth, workbookName) {
		// leaving for debugging
		// console.log("id: ", id);
		let curNodeChildren = flatMap[id].children;

		if (!Object.keys(curNodeChildren).length) {
			const curNode = flatMap[id];
			return {
				label: curNode.label,
				rootLabel: WORKBOOK_GROUP_NUMBERS_TO_ROOT_NODES_MAP[workbookName][curNode.groupNum],
				groupNum: curNode.groupNum,
				description: curNode.description,
				children: {},
				height: 1,
				depth: depth,
				availability: curNode.availability,
				practiceCategory: curNode.practiceCategory,
				practiceID: curNode.practiceID,
				practiceArgs: curNode.practiceArgs,
				order: curNode.order,
				type: curNode.type,
				structuredData: curNode.structuredData,
			};
		}

		let runningObj = {};
		let localHeight = 0;
		for (const [key, value] of Object.entries(curNodeChildren)) {
			const returnedObj = recurseWMap(key, height, depth + 1, workbookName);
			runningObj[key] = {
				label: returnedObj.label,
				rootLabel: WORKBOOK_GROUP_NUMBERS_TO_ROOT_NODES_MAP[workbookName][returnedObj.groupNum],
				groupNum: returnedObj.groupNum,
				description: returnedObj.description,
				children: returnedObj.children,
				height: returnedObj.height,
				depth: returnedObj.depth,
				availability: returnedObj.availability,
				practiceCategory: returnedObj.practiceCategory,
				practiceID: returnedObj.practiceID,
				practiceArgs: returnedObj.practiceArgs,
				order: returnedObj.order,
				type: returnedObj.type,
				structuredData: returnedObj.structuredData,
			};
			localHeight = returnedObj.height;
		}

		return {
			label: flatMap[id].label,
			rootLabel: WORKBOOK_GROUP_NUMBERS_TO_ROOT_NODES_MAP[workbookName][flatMap[id].groupNum],
			groupNum: flatMap[id].groupNum,
			description: flatMap[id].description,
			availability: flatMap[id].availability,
			practiceCategory: flatMap[id].practiceCategory,
			practiceID: flatMap[id].practiceID,
			practiceArgs: flatMap[id].practiceArgs,
			order: flatMap[id].order,
			type: flatMap[id].type,
			structuredData: flatMap[id].structuredData,
			children: runningObj,
			height: localHeight + 1,
			depth: depth
		};
	}
}

function createFlatMapNIdList(list, workbookName) {
	let flatMap = {};
	let idsList= [];
	for (let i = 0; i < list.length; ++i) {
		const curEl = list[i];
		const children = {};
		const identifier = curEl.identifier;

		curEl.children && curEl.children.split("|").forEach(child => {
			children[child] = {};
		});

		flatMap[identifier] = {
			label: curEl.label,
			rootLabel: WORKBOOK_GROUP_NUMBERS_TO_ROOT_NODES_MAP[workbookName][curEl.groupNum],
			groupNum: curEl.group,
			description: curEl.description,
			availability: curEl.availability,
			practiceCategory: curEl.practiceCategory,
			practiceID: curEl.practiceID,
			practiceArgs: curEl.practiceArgs,
			order: curEl.order,
			children: children,
			type: curEl.type,
			structuredData: curEl.structuredData
		};

		idsList.push(identifier);
	}

	idsList = idsList.slice(1);
	return [flatMap, idsList];
}


/**
 * @description recurses through passed object to create nodes and links to generate knowledge tree(s)
 * @param {String} parentKey 
 * @param {String} key 
 * @param {Object} val 
 * @param {Integer} group would be used later for coloring
 * @param {Integer} depth 
 * @returns 
 */
// DFS on list to create nodes and links array for network graph
function createNetworkHierarchy(parentKey, key , val, group, depth, workbookName) {
	if (Object.keys(val["children"]).length === 0 || depth === 0) {
		return [key, val["description"]];
	}

	let newParentKey = parentKey !== key ? key : parentKey;
	for (const [localKey, value] of Object.entries(val["children"])) {
		groupNumber_map[workbookName] = group === 0 ? groupNumber_map[workbookName] + 1 : groupNumber_map[workbookName];
		const returnedKey = createNetworkHierarchy(newParentKey, localKey, value, groupNumber_map[workbookName], depth - 1, workbookName);
		const id = returnedKey[0];

		if (!ID_TRACKER_MAP[workbookName].includes(id)) {
			let relationship = "dependency";
			if (newParentKey.toLocaleLowerCase().includes("requirement")) {
				relationship = "requirement";
			}
			else if (newParentKey.toLocaleLowerCase().includes("elective")) {
				relationship = "elective";
			}

			ID_TRACKER_MAP[workbookName].push(id);
			// NOTE*: D3 Force bodies DOES NOT ALLOW CYCLES!!!
			LINKS_MAP[workbookName].push(
				{
					source: newParentKey,
					target: id
				}
			);
			NODES_MAP[workbookName].push(
				{
					id: id,
					label: value.label,
					rootLabel: WORKBOOK_GROUP_NUMBERS_TO_ROOT_NODES_MAP[workbookName][value.groupNum],
					groupNum: value.group,
					group: groupNumber_map[workbookName],
					description: returnedKey[1],
					height: value.height,
					depth: value.depth,
					availability: value.status,
					practiceCategory: value.practiceCategory,
					practiceID: value.practiceID,
					practiceArgs: value.practiceArgs,
					order: value.order,
					type: value.type,
					structuredData: value.structuredData,
					hasChildren: Object.keys(value.children).length ? true : false,
					relationship: relationship
				}
			);
		}
		else {
			const index = ID_TRACKER_MAP[workbookName].indexOf(id);
			const nodeEl = NODES_MAP[workbookName][index]
			NODES_MAP[workbookName][index] = {
					...NODES_MAP[workbookName][index],
					group: nodeEl.group + `|${groupNumber_map[workbookName]}`
			};
			LINKS_MAP[workbookName].push(
				{
						source: newParentKey,
						target: id
				}
			);
		}
	}

	return [key, val["description"]];
}

/**
 * @description first searches for 'id' then applies the resolution ('zoom') level
 * @param {String} id unique node id to create subtree from
 * @param {Integer} resolution additional zoom in level
 * @returns {Object} objected composed of multiple trees, depending on resolution, i.e. 1 tree for resolution 0, but 1 < n trees with higher resolution
 */
export function getSubtreeFromId(id, resolution = -1, workbookName, getRootLevelParentDetails = false) {
	let queue = [{
		key: "root",
		value: KNOWLEDGE_TREE_MAP[workbookName]["root"]
	}];
	let subTree = {};

	// search for ID
	while (queue.length) {
		const curNode = queue[0];
		const curNodeKey = curNode.key;
		const curNodeVal = curNode.value;

		if (curNodeKey === id) {
			subTree[curNodeKey] = curNodeVal;
			break;
		}
		else {
			for (const [key, value] of Object.entries(curNodeVal.children)) {
				queue.push({
					key: key,
					value: value
				});
			}
		}

		queue = queue.slice(1);
	}

	// used to modify ORIG_ID_LIST_MAP to be later used for automatic dropdown
	let removedKeys = [];
	// apply resolution
	if (resolution > 0) {
		const firstSubtreeKey = Object.keys(subTree)[0];
		queue = [{
			key: firstSubtreeKey,
			value: subTree[firstSubtreeKey]
		}];
		// 1 since there's a root node
		let removalCount = 1;

		while (resolution > 0) {
			let newCount = 0;

			for (let i = 0; i < removalCount; ++i) {
				for (const [key, value] of Object.entries(queue[i].value.children)) {
					queue.push({
						key: key,
						value: value
					});
					++newCount;
				}

				removedKeys.push(queue[i].key);
			}
			queue = queue.slice(removalCount);
			removalCount = newCount;
			--resolution;
		}

		let newSubtree = {};
		for (let i = 0; i < queue.length; ++i) {
			const curEl = queue[i];
			newSubtree[curEl.key] = curEl.value;
		}

		subTree = newSubtree;
	}

	let rootLevelParentTree = null;
	let rootLevelParentName = null;
	const subTreeKeys = Object.keys(subTree);
	if (getRootLevelParentDetails && subTreeKeys.length) {
		const workbookMap = WORKBOOK_GROUP_NUMBERS_TO_ROOT_NODES_MAP[workbookName];
		rootLevelParentName = workbookMap[subTree[subTreeKeys[0]].groupNum];
		rootLevelParentTree = KNOWLEDGE_TREE_MAP[workbookName]["root"].children[rootLevelParentName];


	}

	return [subTree, rootLevelParentTree, rootLevelParentName];
}

export function getQuestTree(rootNodeId, workbookName) {
	return KNOWLEDGE_TREE_MAP[workbookName].root.children[rootNodeId];
}

export function getFirstTopicInQuest(rootNodeId, workbookName) {
	let curNode = getQuestTree(rootNodeId, workbookName);
	let firstModuleTopics;
	while (!curNode.type.includes("topic")) {
		const curNodeChildren = curNode.children;
		const curNodeChildrenKeys = Object.keys(curNodeChildren);
		// this process of assuming that the first element is the first topic is okay, because our naming convention uses Roman Numerals and letters
		curNode = curNodeChildren[curNodeChildrenKeys[0]];
		if (curNode.type.includes("topic")) {
			firstModuleTopics = curNodeChildrenKeys;
			break;
		}
	}

	return firstModuleTopics[0];
}

function createKeysList(subTree) {
// traverse through subtree to get all IDs
	let newDropdownKeys = [];
	let queue = [];
	for (const [key, value] of Object.entries(subTree)) {
		queue.push({
			key: key,
			value: value
		});
	}

	while (queue.length) {
		const curNode = queue[0];
		const curNodeKey = curNode.key;
		const curNodeVal = curNode.value;

		if (!queue.length) {
			break;
		}
		else {
			for (const [key, value] of Object.entries(curNodeVal.children)) {
				queue.push({
					key: key,
					value: value
				});
			}
		}

		newDropdownKeys.push(curNodeKey);
		queue = queue.slice(1);
	}

	if (newDropdownKeys.includes("root")) {
		newDropdownKeys = newDropdownKeys.slice(1);
	}

	return newDropdownKeys;
}

export function findWorkbookFromSubject(subject) {
	subject = subject.toLocaleLowerCase();
	const workbooks = Object.keys(WORKBOOK_TO_QUESTABLE_ROOT_NODES_MAP);
	let workbook = null;
	for (let i = 0; i < workbooks.length; ++i) {
		const currentWorkbook = workbooks[i];
		const lowercasedSubjects = Array.from(WORKBOOK_TO_QUESTABLE_ROOT_NODES_MAP[workbooks[i]], subject => subject.toLocaleLowerCase());

		if (lowercasedSubjects.includes(subject)) {
			workbook = currentWorkbook;
			break;
		}
	}

	return workbook;
}

