import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";

function PrivacyPolicy(props) {
	window.scrollTo(0, 0);
	const classStr = props.contentType === "modal" ? "modal-container-privacy" : "policy-container";

	return (
		<div className="PrivacyPolicy">
			<Helmet>
				<title>{messageMap("privacyPolicy.title", "headerTag")}</title>
				<meta name="description" content={messageMap("privacyPolicy.description", "headerTag")}></meta>
			</Helmet>


			<div className={classStr}>
				<h1 className="title">
					{messageMap("privacyPolicy.title", "document")}
				</h1>
				<div className="policy">
					<p className="margin-bottom-spacing">
						{messageMap("privacyPolicy.header.date", "document")}
					</p>
					<p className="margin-bottom-spacing">
						{messageMap("privacyPolicy.header.privacy", "document")}
					</p>
					<p className="margin-bottom-spacing">
						{messageMap("privacyPolicy.header.data", "document")}
					</p>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section1.title", "document")}
						</h2>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section1.subheaderA.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section1.subheaderA.point1.text", "document")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section1.subheaderB.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section1.subheaderB.point1.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point2.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point2.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point3.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point3.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point4.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point4.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point5.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point5.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point6.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point6.text", "document")}
								<a href="https://exerinstitute.com">https://exerinstitute.com.</a>
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point7.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point7.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point8.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point8.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point9.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point9.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point10.term", "document")}
								</span> 
								{messageMap("privacyPolicy.section1.subheaderB.point10.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point11.term", "document")}
								</span> 
								{messageMap("privacyPolicy.section1.subheaderB.point11.text", "document")}
								<a href="https://www.facebook.com/MLOMH-108504940895615">https://www.facebook.com/MLOMH-108504940895615</a>
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point12.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point12.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point13.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point13.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point14.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point14.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point15.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point15.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point16.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point16.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point17.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point17.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point18.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point18.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point19.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point19.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section1.subheaderB.point20.term", "document")}
								</span>
								{messageMap("privacyPolicy.section1.subheaderB.point20.text", "document")}
							</p>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section2.title", "document")}
						</h2>
						
						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section2.subheaderA.text", "document")}
							</h3>

							<div>
								<h3 className="western">
									{messageMap("privacyPolicy.section2.subheaderA.point1.text", "document")}
								</h3>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderA.point1.point1.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point1.point1.point1.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point1.point1.point2.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point1.point1.point3.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point1.point1.point4.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point1.point1.point5.text", "document")}
								</p>
							</div>

							<div>
								<h3 className="western">
									{messageMap("privacyPolicy.section2.subheaderA.point2.text", "document")}
								</h3>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderA.point2.point1.text", "document")}
								</p>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderA.point2.point2.text", "document")}
								</p>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderA.point2.point3.text", "document")}
								</p>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderA.point2.point4.text", "document")}
								</p>
							</div>

							<div>
								<h3 className="western">
									{messageMap("privacyPolicy.section2.subheaderA.point3.text", "document")}
								</h3>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point1.text", "document")}
								</p>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point2.text", "document")}
								</p>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point3.text", "document")}
									<a href="https://www.termsfeed.com/blog/cookies/">
										{messageMap("privacyPolicy.section2.subheaderA.point3.point3.link", "document")}
									</a>.
								</p>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.text", "document")}
								</p>
								<p className="margin-left-bottom">
									<span>
										{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection1.title", "document")}
									</span>
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection1.point1.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection1.point2.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection1.point3.text", "document")}
								</p>
								<p className="margin-left-bottom">
									<span>
										{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection2.title", "document")}
									</span>
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection2.point1.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection2.point2.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection2.point3.text", "document")}
								</p>
								<p className="margin-left-bottom">
									<span>
										{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection3.title", "document")}
									</span>
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection3.point1.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection3.point2.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection3.point3.text", "document")}
								</p>
								<p className="margin-left-bottom">
									<span>
										{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection4.title", "document")}
									</span>
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection4.point1.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection4.point2.text", "document")}
								</p>
								<p className="margin-left-bottom">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point4.subsection4.point3.text", "document")}
								</p>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderA.point3.point5.text", "document")}
								</p>
							</div>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section2.subheaderB.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section2.subheaderB.point1.text", "document")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point1.point1.term", "document")}
										</span>
										{messageMap("privacyPolicy.section2.subheaderB.point1.point1.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point1.point2.term", "document")}
										</span>
										{messageMap("privacyPolicy.section2.subheaderB.point1.point2.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point1.point3.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section2.subheaderB.point1.point3.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point1.point4.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section2.subheaderB.point1.point4.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point1.point5.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section2.subheaderB.point1.point5.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point1.point6.term", "document")}
										</span>
										{messageMap("privacyPolicy.section2.subheaderB.point1.point6.text", "document")}
									</p>
								</li>
							</ul>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section2.subheaderB.point2.text", "document")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point2.point1.term", "document")}
										</span>
										{messageMap("privacyPolicy.section2.subheaderB.point2.point1.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point2.point2.term", "document")}
										</span>
										{messageMap("privacyPolicy.section2.subheaderB.point2.point2.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point2.point3.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section2.subheaderB.point2.point3.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point2.point4.term", "document")}
										</span>
										{messageMap("privacyPolicy.section2.subheaderB.point2.point4.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section2.subheaderB.point2.point5.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section2.subheaderB.point2.point5.text", "document")}
									</p>
								</li>
							</ul>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section2.subheaderC.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section2.subheaderC.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section2.subheaderC.point2.text", "document")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section2.subheaderD.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section2.subheaderD.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section2.subheaderD.point2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section2.subheaderD.point3.text", "document")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section2.subheaderE.text", "document")}
							</h3>

							<div>
								<h3 className="western">
									{messageMap("privacyPolicy.section2.subheaderE.point1.text", "document")}
								</h3>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderE.point1.point1.text", "document")}
								</p>
							</div>

							<div>
								<h3 className="western">
									{messageMap("privacyPolicy.section2.subheaderE.point2.text", "document")}
								</h3>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderE.point2.point1.text", "document")}
								</p>
							</div>

							<div>
								<h3 className="western">
									{messageMap("privacyPolicy.section2.subheaderE.point3.text", "document")}
								</h3>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section2.subheaderE.point3.point1.text", "document")}
								</p>
								<ul>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section2.subheaderE.point3.point1.point1.text", "document")}
										</p>
									</li>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section2.subheaderE.point3.point1.point2.text", "document")}
										</p>
									</li>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section2.subheaderE.point3.point1.point3.text", "document")}
										</p>
									</li>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section2.subheaderE.point3.point1.point4.text", "document")}
										</p>
									</li>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section2.subheaderE.point3.point1.point5.text", "document")}
										</p>
									</li>
								</ul>
							</div>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section2.subheaderF.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section2.subheaderF.point1.text", "document")}
							</p>
						</div>

					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section3.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section3.subpoint.text", "document")}
						</p>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section3.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section3.point1.point1.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									Google Analytics
								</span>
							</p>
							<p className="margin-left-bottom">
								{messageMap("privacyPolicy.section3.point1.point1.point1.text", "document")}
							</p>
							<p className="margin-left-bottom">
								{messageMap("privacyPolicy.section3.point1.point1.point2.text", "document")}
							</p>
							<p className="margin-left-bottom">
								{messageMap("privacyPolicy.section3.point1.point1.point3.text", "document")}
								<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section3.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section3.point2.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section3.point2.point2.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									Mailchimp
								</span>
							</p>
							<p className="margin-left-bottom">
								{messageMap("privacyPolicy.section3.point2.point2.point1.text", "document")}
							</p>
							<p className="margin-left-bottom">
								{messageMap("privacyPolicy.section3.point2.point2.point2.text", "document")}
								<a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a>
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section3.point3.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section3.point3.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section3.point3.point2.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>Stripe</span>
							</p>
							<p className="margin-left-bottom">
								{messageMap("privacyPolicy.section3.point3.point2.point1.text", "document")}
								<a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>
							</p>
							<p className="margin-left-bottom">
								<span>PayPal</span>
							</p>
							<p className="margin-left-bottom">
								{messageMap("privacyPolicy.section3.point3.point2.point2.text", "document")}
								<a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">https://www.paypal.com/webapps/mpp/ua/privacy-full</a>
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section3.point4.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section3.point4.point1.text", "document")}
							</p>
							<p className="margin-left-bottom">
								<span>
									{messageMap("privacyPolicy.section3.point4.point1.point1.text", "document")}
								</span>
							</p>
							<p className="margin-left-bottom">
								{messageMap("privacyPolicy.section3.point4.point1.point2.text", "document")}
							</p>
							<p className="margin-left-bottom">
								{messageMap("privacyPolicy.section3.point4.point1.point3.text", "document")}
							</p>
							<p className="margin-left-bottom">
								{messageMap("privacyPolicy.section3.point4.point1.point4.text", "document")}
								<a href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>
							</p>
						</div>

					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section4.title", "document")}
						</h2>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section4.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section4.point1.point1.text", "document")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point1.point1.point1.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section4.point1.point1.point1.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point1.point1.point2.term", "document")}
										</span>
										{messageMap("privacyPolicy.section4.point1.point1.point2.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point1.point1.point3.term", "document")}
										</span>
										{messageMap("privacyPolicy.section4.point1.point1.point3.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point1.point1.point4.term", "document")}
										</span>
										{messageMap("privacyPolicy.section4.point1.point1.point4.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point1.point1.point5.term", "document")}
										</span>
										{messageMap("privacyPolicy.section4.point1.point1.point5.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point1.point1.point6.term", "document")}
										</span>
										{messageMap("privacyPolicy.section4.point1.point1.point6.text", "document")}
									</p>
								</li>
							</ul>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section4.point1.point2.text", "document")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section4.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section4.point2.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section4.point2.point2.text", "document")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point2.point2.point1.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section4.point2.point2.point1.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point2.point2.point2.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section4.point2.point2.point2.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point2.point2.point3.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section4.point2.point2.point3.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point2.point2.point4.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section4.point2.point2.point4.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point2.point2.point5.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section4.point2.point2.point5.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section4.point2.point2.point6.term", "document")}
										</span>
										{messageMap("privacyPolicy.section4.point2.point2.point6.text", "document")}
									</p>
								</li>
							</ul>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section4.point3.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section4.point3.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section4.point3.point2.text", "document")}
							</p>
						</div>

					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section5.title", "document")}
						</h2>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section5.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section5.point1.point1.text", "document")}
								<a href="https://www.facebook.com/MLOMH-108504940895615">https://www.facebook.com/MLOMH-108504940895615</a>
								{messageMap("privacyPolicy.section5.point1.point1.text1", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section5.point1.point2.text", "document")}
								<a href="https://www.facebook.com/terms.php">https://www.facebook.com/terms.php</a>
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section5.point1.point3.text", "document")}
								<a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>
								{messageMap("privacyPolicy.section5.point1.point3.text1", "document")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section5.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section5.point2.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section5.point2.point2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section5.point2.point3.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section5.point2.point4.text", "document")}
								<a href="https://www.facebook.com/about/privacy/previous?paipv=0&eav=AfbHO5IHXjALT6Id9qGFOHfQah-_LTa_IzqhcWKxx1YEV3xMlmVQnMTN7Zqi_t3iQYI&_rdr">https://www.facebook.com/full_data_use_policy</a>
							</p>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section6.title", "document")}
						</h2>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section6.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section6.point1.point1.text", "document")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section6.point1.point1.point1.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section6.point1.point1.point1.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section6.point1.point1.point2.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section6.point1.point1.point2.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section6.point1.point1.point3.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section6.point1.point1.point3.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section6.point1.point1.point4.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section6.point1.point1.point4.text", "document")}
										<ul>
											<li>
												<p className="margin-bottom-spacing">
													{messageMap("privacyPolicy.section6.point1.point1.point4.point1.text", "document")}
												</p>
											</li>
											<li>
												<p className="margin-bottom-spacing">
													{messageMap("privacyPolicy.section6.point1.point1.point4.point2.text", "document")}
												</p>
											</li>
											<li>
												<p className="margin-bottom-spacing">
													{messageMap("privacyPolicy.section6.point1.point1.point4.point3.text", "document")}
												</p>
											</li>
											<li>
												<p className="margin-bottom-spacing">
													{messageMap("privacyPolicy.section6.point1.point1.point4.point4.text", "document")}
												</p>
											</li>
											<li>
												<p className="margin-bottom-spacing">
													{messageMap("privacyPolicy.section6.point1.point1.point4.point5.text", "document")}
												</p>
											</li>
										</ul>
									</p>
								</li>
							</ul>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section6.point1.point1.point5.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section6.point1.point1.point5.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										<span>
											{messageMap("privacyPolicy.section6.point1.point1.point6.term", "document")}
										</span> 
										{messageMap("privacyPolicy.section6.point1.point1.point6.text", "document")}
										<ul>
											<li>
												<p className="margin-bottom-spacing">
													{messageMap("privacyPolicy.section6.point1.point1.point6.point1.text", "document")}
												</p>
											</li>
											<li>
												<p className="margin-bottom-spacing">
													{messageMap("privacyPolicy.section6.point1.point1.point6.point2.text", "document")}
												</p>
											</li>
											<li>
												<p className="margin-bottom-spacing">
													{messageMap("privacyPolicy.section6.point1.point1.point6.point3.text", "document")}
												</p>
											</li>
											<li>
												<p className="margin-bottom-spacing">
													{messageMap("privacyPolicy.section6.point1.point1.point6.point4.text", "document")}
												</p>
											</li>
										</ul>
									</p>
								</li>
							</ul>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section6.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section6.point2.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section6.point2.point2.text", "document")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section6.point3.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section6.point3.subpoint1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section6.point3.subpoint2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section6.point3.subpoint3.text", "document")}
							</p>

							<div>
								<h3 className="western">
									{messageMap("privacyPolicy.section6.point3.point1.text", "document")}
								</h3>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section6.point3.point1.point1.text", "document")}
								</p>
								<ul>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section6.point3.point1.point1.point1.text", "document")}
										</p>
									</li>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section6.point3.point1.point1.point2.text", "document")}
										</p>
									</li>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section6.point3.point1.point1.point3.text", "document")}
										</p>
									</li>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section6.point3.point1.point1.point4.text", "document")}
										</p>
									</li>
								</ul>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section6.point3.point1.point2.text", "document")}
								</p>
							</div>

							<div>
								<h3 className="western">
									{messageMap("privacyPolicy.section6.point3.point2.text", "document")}
								</h3>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section6.point3.point2.point1.text", "document")}
								</p>
								<ul>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section6.point3.point2.point1.point1.text", "document")}
										</p>
									</li>
									<li>
										<p className="margin-bottom-spacing">
											{messageMap("privacyPolicy.section6.point3.point2.point1.point2.text", "document")}
										</p>
									</li>
								</ul>
								<p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section6.point3.point2.point2.text", "document")}
								</p>
							</div>
						</div>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section7.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section7.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section7.point2.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section8.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section8.subpoint1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section8.subpoint2.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section8.subpoint3.text", "document")}
						</p>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section8.point1.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section8.point1.point1.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section8.point1.point2.text", "document")}
							</p>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section8.point1.point3.text", "document")}
							</p>
							<ul>
								<li><p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section8.point1.point3.point1.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section8.point1.point3.point2.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section8.point1.point3.point3.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section8.point1.point3.point4.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section8.point1.point3.point5.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section8.point1.point3.point6.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section8.point1.point3.point7.text", "document")}
								</p></li>
								<li><p className="margin-bottom-spacing">
									{messageMap("privacyPolicy.section8.point1.point3.point8.text", "document")}
								</p></li>
							</ul>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section8.point1.point4.text", "document")}
							</p>
						</div>

						<div className="sub-section-div">
							<h3 className="western">
								{messageMap("privacyPolicy.section8.point2.text", "document")}
							</h3>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section8.point2.point1.text", "document")}
							</p>
							<ul>
								<li>
									<p className="margin-bottom-spacing">
										{messageMap("privacyPolicy.section8.point2.point1.point1.text", "document")}
									</p>
								</li>
								<li>
									<p className="margin-bottom-spacing">
										{messageMap("privacyPolicy.section8.point2.point1.point2.text", "document")}
									</p>
								</li>
							</ul>
							<p className="margin-bottom-spacing">
								{messageMap("privacyPolicy.section8.point2.point2.text", "document")}
							</p>
						</div>

					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section9.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section9.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section9.point2.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section10.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section10.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section10.point2.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section10.point3.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section11.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section11.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section11.point2.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section12.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section12.point1.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section12.point2.text", "document")}
						</p>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section12.point3.text", "document")}
						</p>
					</div>

					<div className="section-div">
						<h2 className="head-label">
							{messageMap("privacyPolicy.section13.title", "document")}
						</h2>
						<p className="margin-bottom-spacing">
							{messageMap("privacyPolicy.section13.point1.text", "document")}
						</p>
						<p className="margin-left-bottom">
							{messageMap("privacyPolicy.section13.point2.text", "document")}
						</p>
						<p className="margin-left-bottom">
							{messageMap("privacyPolicy.section13.point3.text", "document")}
							<a href="https://exerinstitute.com">https://exerinstitute.com</a>
						</p>
					</div>

				</div>

			</div>
		</div>
	);
}

PrivacyPolicy.propTypes = {
	contentType: PropTypes.string
};

export default PrivacyPolicy;