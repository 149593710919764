import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import profileSubNavSelector from "redux/selectors/profileSubNavSelector";

import {getAccountSettingsAPI} from "apis/controllers/person/AccountsController";

import PageLayout from "templates/PageLayout";
// Sub Pages
import AccountsSubPage from "pages/Profile/subPages/AccountsSubPage";
// import TutoringSubPage from "./subPages/TutoringSubPage";
import GroupsSubPage from "pages/Profile/subPages/GroupsSubPage";
import FinancialsSubPage from "pages/Profile/subPages/FinancialsSubPage";

import messageMap from "Utilities/MessageMaps";
import { generateSubMenu } from "templates/utilities/PageSubMenuUtilities";
import { PROFILE_PAGE_MENU } from "templates/utilities/PageSubMenu";


function Profile(props) {
	const [groupNameDisplay, setGroupNameDisplay] = useState(null);

	const [confirmUpdate, setConfirmUpdate] = useState(null),
		[alert, setAlert] = useState(null),
		[modal, setModal] = useState();

	// Sub Page stats
	const [accountsSubPage, setAccountsSubPage] = useState(),
		[groupSubPage, setGroupSubPage] = useState(),
		[financialsSubPage, setFinancialsSubPage] = useState();

	// displayRefs
	const accountSubPageRef = useRef(),
		tutoringSubPageRef = useRef(),
		groupsSubPageRef = useRef(),
		financialsSubPageRef = useRef();

	useEffect(() => {
		getUserAccount();
	}, []);

	useEffect(() => {
		navigateToSubPage();
	}, [props.profileSubNav]);

	function navigateToSubPage() {
		const urlLocation = window.location.href;
		if (urlLocation.includes("show")) {
			const subNavRegEx = new RegExp("(?<=show=).*");
			subMenuButtonClickHandler(subNavRegEx.exec(urlLocation)[0]);
		}
	}

	function getUserAccount() {
		const payload = {
			ownerId: localStorage.getItem("ownerId"),
			jwt: localStorage.getItem("jwt")
		};

		getAccountSettingsAPI(payload, response => {
			const { username, email, earnings, minimumCurrencyWithdrawal, groupIds } = response;

			setAccountsSubPage(
				<AccountsSubPage username={username} emailAddress={email}
					reRenderAccounts={getUserAccount}
					setConfirmUpdate={setConfirmUpdate} setAlert={setAlert} closeAlert={closeAlert} />
			);

			// TODO: Move the currency symbol from FE to BE
			setFinancialsSubPage(
				<FinancialsSubPage earnings={earnings} currencySymbol={props.currencySymbol}
					minimumCurrencyWithdrawal={minimumCurrencyWithdrawal}
					setAlert={setAlert} closeAlert={closeAlert} />
			);

			setGroupSubPage(
				<GroupsSubPage isMemberOfGroup={groupIds.length > 0}
					setGroupNameDisplay={setGroupNameDisplay}
					setAlert={setAlert} closeAlert={closeAlert}/>
			);
		});
	}

	function closeAlert() {
		setAlert(null);
	}

	function subMenuButtonClickHandler(clickedMenuKey) {
		const menuKeyToRefMap = {
			"account": accountSubPageRef,
			"tutoring": tutoringSubPageRef,
			"groups": groupsSubPageRef,
			"financials": financialsSubPageRef
		};

		Object.keys(menuKeyToRefMap).forEach(key => {
			menuKeyToRefMap[key].current.className = "profile-page hide";
		});

		menuKeyToRefMap[clickedMenuKey].current.className = "profile-page";
	}

	return (
		<div className="account-settings">
			<Helmet>
				<title>{messageMap("profile.title", "headerTag")}</title>
				<meta name="description" content={messageMap("profile.description", "headerTag")}></meta>
			</Helmet>

			{alert}
			{confirmUpdate}
			{modal}
			{groupNameDisplay}

			<PageLayout subMenu={generateSubMenu(PROFILE_PAGE_MENU, subMenuButtonClickHandler)}>
				<div>
					<div className="profile-page" ref={accountSubPageRef}>
						{accountsSubPage}
					</div>

					<div className="profile-page hide" ref={tutoringSubPageRef}>
						{/* <TutoringSubPage setModal={setModal} /> */}
					</div>

					<div className="profile-page hide" ref={groupsSubPageRef}>
						{groupSubPage}
					</div>

					<div className="profile-page hide" ref={financialsSubPageRef}>
						{financialsSubPage}
					</div>
				</div>
			</PageLayout>
		</div>
	);
}

Profile.defaultProps = {
	currencySymbol: "$"
}

Profile.propTypes = {
	currencySymbol: PropTypes.string,

	// Redux props
	profileSubNav: PropTypes.string.isRequired
};

export default connect(
	profileSubNavSelector
)(Profile);