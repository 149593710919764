import React from "react";
import PropTypes from "prop-types";


function PageLayout(props) {

	return (
		<div className="page-layout">
			<div className="menu-options">
				{props.subMenu}
			</div>

			<div className="menu-contents">
				{props.children}
			</div>
		</div>
	);
}


PageLayout.propTypes = {
	subMenu: PropTypes.array.isRequired,
	children: PropTypes.object.isRequired
};

export default PageLayout;

