import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/thumbnailNumbers.jpg";

import CountableObjects from "assets/topicArticles/Math/components/templates/CountableObjects";
import ComparingCountableObjects from "assets/topicArticles/Math/components/ComparingCountableObjects";



export const comparingQuantitiesArticle = {
	id: "II: Relations_B. Comparing Quantities Through Pictures",
	title: messageMap("comparingQuantities.article.title", "math"),
	description: messageMap("comparingQuantities.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("comparingQuantities.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "html1", "html2", "html3", "html4", "html5", "html6", "html7", "html8", "html9", "html10",
		"text3", "html11", "text4",
		"text5", "text6", "html12"
	],
	datePublished: "2024-06-04",
	dateModified: "2024-06-04"
};

export const comparingQuantitiesArticleTexts = {
	text1: {
		text: messageMap("comparingQuantities.comparingQuantitiesArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("comparingQuantities.comparingQuantitiesArticleTexts.text2", "math"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("comparingQuantities.comparingQuantitiesArticleTexts.text3", "math"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("comparingQuantities.comparingQuantitiesArticleTexts.text4", "math"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("comparingQuantities.comparingQuantitiesArticleTexts.text5", "math"),
		type: SUB_HEADER
	},
	text6: {
		text: messageMap("comparingQuantities.comparingQuantitiesArticleTexts.text6", "math"),
		type: DESCRIPTION
	}
};

export const comparingQuantitiesArticleHTMLs = {
	html1: (
		<CountableObjects key={1} objectCount={1} />
	),
	html2: (
		<CountableObjects key={2} objectCount={2} />
	),
	html3: (
		<CountableObjects key={3} objectCount={3} />
	),
	html4: (
		<CountableObjects key={4} objectCount={4} />
	),
	html5: (
		<CountableObjects key={5} objectCount={5} />
	),
	html6: (
		<CountableObjects key={6} objectCount={6} />
	),
	html7: (
		<CountableObjects key={7} objectCount={7} />
	),
	html8: (
		<CountableObjects key={8} objectCount={8} />
	),
	html9: (
		<CountableObjects key={9} objectCount={9} />
	),
	html10: (
		<CountableObjects key={10} objectCount={10} />
	),
	html11: (
		<CountableObjects key={"countableObjects"} objectCount={1} countable={true} widthClass="whole-width" />
	),
	html12: (
		<ComparingCountableObjects key={"comparingCountableObjects"} />
	)
};

