/* eslint-disable no-template-curly-in-string */
import {IDENTITY_VO} from "apis/models/Accounts";
import {FORM_DATA} from "Utilities/Constants/MediaConstants";
import {PAGE_SIZE} from "Utilities/Constants/PageConstants";

import request, {GET, POST} from "Utilities/Fetches";
import {$replace, fillOptionalArgs} from "Utilities/Strings";
import {modelValidator} from "Utilities/Validators/APIValidators";

const BASE = "/services/video";

export const GET_VIDEO_SEARCH_LIST = BASE + "/get/search/strings";

export const LIST_ALL_VIDEOS = BASE + "/list/all?pageCount=${pageCount}&pageSize=${pageSize}&downloadTypes=${downloadTypes}";
const LIST = BASE + "/list/${classId}";
export const GET_VIDEO_METADATA = BASE + "/get/metadata/${videoId}";
const GET_VIDEO_ENTRIES_COUNT = BASE + "/get/count";
const GET_CLASS_VIDEO_INTRO = BASE + "/${classId}/intro";
export const GET_VIDEO_HLS_RESOURCE = BASE + "/get/hls/${videoId}";
const GET_VIDEO_TRANSCRIPT = BASE + "/transcript/${videoId}";
const GET_VIDEO_MENTAL_MODEL = BASE + "/mentalmodel";

const UPLOAD_VIDEO = BASE + "/upload?ownerId=${ownerId}&jwt=${jwt}";
const EDIT_UPLOADED_VIDEO = BASE + "/edit?ownerId=${ownerId}&jwt=${jwt}&videoId=${videoId}";
const DELETE_VIDEO = BASE + "/delete?videoIds=${videoIds}&category=${category}";

const VIDEO_TRANSACTION = "/services/video/transaction";
const PURCHASE_VIDEO = VIDEO_TRANSACTION + "/purchase/${videoId}";
const WATCH_VIDEO = VIDEO_TRANSACTION + "/watch/${videoId}";


export function getVideoSearchStrings(callback) {
	request(GET_VIDEO_SEARCH_LIST, GET, null, callback);
}

/**
 * @param {Object} pathVariables  = {
 * 	pageCount: Number,
 * 	pageSize: Number,
 * 	downloadTypes: [String]
 * }
 * @param {Object} payload = {
 * 	identity: IDENTITY_VO,
 * 	filters: [String]
 * }
 */
export function listAllVideosAPI(pathVariables, payload, successCallback, errorCallback = null) {
	let newPathVariables = fillOptionalArgs(pathVariables, {pageSize: PAGE_SIZE.TEN});
	const api = $replace(LIST_ALL_VIDEOS, newPathVariables);
	request(api, POST, payload, successCallback, errorCallback, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	classId: String
 * }
 */
export function listAPI(pathVariables, callback) {
	const api = $replace(LIST, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = {
 * 	identity = IDENTITY_VO,
 * 	filters = [String]
 * }
 */
export function getVideoMetadataAPI(pathVariables, payload, callback) {
	const api = $replace(GET_VIDEO_METADATA, pathVariables);
	// modelValidator(IDENTITY_VO, payload)
	request(api, POST, payload, callback);
}

/**
 * @param {Object} payload = IDENTITY_VO
 */
export function getVideoEntriesCountAPI(payload, callback) {
	request(GET_VIDEO_ENTRIES_COUNT, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = IDENTITY_VO
 */
export function getVideoHLSResourceAPI(pathVariables, payload, callback) {
	const api = $replace(GET_VIDEO_HLS_RESOURCE, pathVariables);
	// modelValidator(IDENTITY_VO, payload)
	request(api, POST, payload, callback, null, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	classId: String
 * }
 */
export function getClassVideoIntroAPI(pathVariables, callback) {
	const api = $replace(GET_CLASS_VIDEO_INTRO, pathVariables);
	request(api, GET, null, callback, null, null, true);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = IDENTITY_VO
 */
export function getVideoTranscriptAPI(pathVariables, payload, callback) {
	const api = $replace(GET_VIDEO_TRANSCRIPT, pathVariables);
	// modelValidator(IDENTITY_VO, payload)
	request(api, POST, payload, callback, null, null, false);
}

/**
 * @param {Object} payload = VIDEO_MENTAL_MODEL_VO
 */
export function getVideoMentalModelAPI(payload, callback) {
	request(GET_VIDEO_MENTAL_MODEL, POST, payload, callback, null, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	ownerId: String,
 * 	jwt: jwt
 * }
 */
export function uploadVideoAPI(pathVariables, formDataPayload, callback) {
	const relevantFields = [
		"videoTitle",
		"videoDuration",
		"video",
		"thumbnail",
		"thumbnailDescription",
		"description",
		"subtitles",
		"mentalModels",
		"price",
		"topicID",
		// either predefined (topic from our Excel files), or "missingAnItem", which corresponds
		// to "missingItem" feedbackType for submitMissingItemsSuggestion() in KnowledgeTree.jsx
		"topicType",
		"category"
	];
	const api = $replace(UPLOAD_VIDEO, pathVariables);
	// TODO: find a way to validate formData object fields
	request(api, POST, getFormData(formDataPayload, relevantFields), callback, null, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	ownerId: String,
 * 	jwt: jwt,
 * 	videoId: videoId
 * }
 */
export function editUploadedVideoAPI(pathVariables, formDataPayload, fieldsToValidate, callback) {
	const api = $replace(EDIT_UPLOADED_VIDEO, pathVariables);
	
	request(api, POST, getFormData(formDataPayload, fieldsToValidate), callback, null, null, false);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: videoId,
 * 	category: category
 * }
 */
export function deleteVideoContentAPI(pathVariables, payload, callback) {
	const api = $replace(DELETE_VIDEO, pathVariables);
	// modelValidator(IDENTITY_VO, payload)
	request(api, POST, payload, callback);
}

function getFormData(formDataPayload, relevantFields) {
	const formData = new FormData();

	relevantFields.includes("videoTitle") && formData.append(FORM_DATA.VIDEO_TITLE, formDataPayload.videoTitle);
	relevantFields.includes("videoDuration") && formData.append(FORM_DATA.VIDEO_DURATION, formDataPayload.videoDuration);
	relevantFields.includes("video") && formData.append(FORM_DATA.VIDEO, formDataPayload.video);
	relevantFields.includes("thumbnail") && formData.append(FORM_DATA.THUMBNAIL, formDataPayload.thumbnail);
	relevantFields.includes("thumbnailDescription") && formData.append(FORM_DATA.THUMBNAIL_DESCRIPTION, formDataPayload.thumbnailDescription);
	relevantFields.includes("subtitles") && formData.append(FORM_DATA.SUBTITLES, formDataPayload.subtitles);
	relevantFields.includes("mentalModels") && formDataPayload.mentalModels.forEach(model => {
		formData.append(FORM_DATA.MENTAL_MODELS, model);
	});
	relevantFields.includes("price") && formData.append(FORM_DATA.PRICE, formDataPayload.price);
	relevantFields.includes("topicID") && formData.append(FORM_DATA.TOPIC_ID, formDataPayload.topicID);
	// either predefined (topic from our Excel files), or "missingAnItem", which corresponds
	// to "missingItem" feedbackType for submitMissingItemsSuggestion() in KnowledgeTree.jsx
	relevantFields.includes("topicType") && formData.append(FORM_DATA.TOPIC_TYPE, formDataPayload.topicType);
	relevantFields.includes("description") && formData.append(FORM_DATA.DESCRIPTION, formDataPayload.description);
	relevantFields.includes("category") && formData.append(FORM_DATA.CATEGORY, formDataPayload.category);

	return formData;
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = {
 * 	identity = IDENTITY_VO,
 * 	filters = [String]
 * }
 */
export function purchaseVideoAPI(pathVariables, payload, callback) {
	const api = $replace(PURCHASE_VIDEO, pathVariables);
	modelValidator(IDENTITY_VO, payload.identity) && request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = {
 * 	identity = IDENTITY_VO,
 * 	filters = [String]
 * }
 */
export function watchVideoAPI(pathVariables, payload, callback) {
	const api = $replace(WATCH_VIDEO, pathVariables);
	request(api, POST, payload, callback);
}