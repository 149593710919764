import {
	SET_OWNER_ID, REMOVE_OWNER_ID,
	SET_LOG_IN_TIMEOUT_START_TIME, CLEAR_LOG_IN_TIMEOUT, LOG_IN_TIMEOUT,
	SET_IP, SET_IP_LOCKED, SET_IP_CREATED_AT, UPDATE_SHINY_NEURONS_BALANCE
} from "redux/actions/accountConstants";

const initialState = {
	ownerId: null,
	username: null,
	isInAGroup: false,
	jwt: null,
	shinyNeurons: 0,
	timedOutStartTime: localStorage.getItem("logInTimeout") === null ? 0 : localStorage.getItem("logInTimeout"),
	timeOutDuration: LOG_IN_TIMEOUT,
	ip: null,
	ipLocked: false,
	ipCreatedAt: 0
};

const LOCAL_STORAGE_KEYS_NOT_COUNTING_OWNER_ID = ["isInAGroup", "jwt", "shinyNeurons", "username"];

// TODO: Fix the usage of local storage for timeout related dispatch.
export default function (state = initialState, action) {
	if (action.type === SET_OWNER_ID) {
		const payload = action.payload;
		localStorage.setItem("ownerId", payload.id);// For use after a redirect.
		LOCAL_STORAGE_KEYS_NOT_COUNTING_OWNER_ID.forEach((key) => localStorage.setItem(key, payload[key]));

		return {
			...state,
			ownerId: payload.id,
			username: payload.username,
			jwt: payload.jwt,
			shinyNeurons: payload.shinyNeurons,
			isInAGroup: payload.isInAGroup
		};
	}
	else if (action.type === REMOVE_OWNER_ID) {
		localStorage.removeItem("ownerId");// Clean-up
		LOCAL_STORAGE_KEYS_NOT_COUNTING_OWNER_ID.forEach((key) => localStorage.removeItem(key));

		return {
			...state,
			ownerId: null,
			username: null,
			jwt: null,
			shinyNeurons: 0,
			isInAGroup: false
		};
	}
	else if (action.type === SET_LOG_IN_TIMEOUT_START_TIME) {
		const timedOutDuration = action.payload.duration;
		localStorage.setItem("logInTimeout", action.payload.startTime);
		setTimeout(() => {
			action.payload.callback();
		}, (timedOutDuration));

		return {
			...state,
			timeOutDuration: action.payload.duration,
			timedOutStartTime: action.payload.startTime
		};
	}
	else if (action.type === CLEAR_LOG_IN_TIMEOUT) {
		localStorage.setItem("logInTimeout", 0);
		return {
			...state,
			timedOutStartTime: 0
		};
	}
	else if (action.type === SET_IP) {
		return {
			...state,
			ip: action.payload.ip
		};
	}
	else if (action.type === SET_IP_LOCKED) {
		return {
			...state,
			ipLocked: action.payload.isLocked
		};
	}
	else if (action.type === SET_IP_CREATED_AT) {
		return {
			...state,
			ipCreatedAt: action.payload.createdAt
		};
	}
	else if (action.type === UPDATE_SHINY_NEURONS_BALANCE) {
		return {
			...state,
			shinyNeurons: action.payload.shinyNeurons
		};
	}
	else {
		return state;
	}
}