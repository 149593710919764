import React, { useRef } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";


/**
 * @param {String} customClass? // optional css class to change user button styling
 * @param {String} borderClass? // optional css class to change username styling
 * @param {String} hideUsername? // true -> hide username
 * @param {Function} mouseOverHandler? // optional mouseOverHandler
 * @param {Function} mouseOutHandler? // optional mouseOutHandler
 */
function Username(props) {

	function mouseOverHandler(e) {
		props.mouseOverHandler && props.mouseOverHandler(e);
	}

	function mouseOutHandler(e) {
		props.mouseOutHandler && props.mouseOutHandler(e);
	}

	return (
		<button className={`username ${props.customClass}`}
			onClick={mouseOverHandler} onKeyPress={mouseOverHandler}
			onMouseOver={mouseOverHandler} onFocus={mouseOverHandler}
			onMouseLeave={mouseOutHandler} onBlur={mouseOutHandler}>
			<div className={`user-initial-container ${props.borderClass}`}>
				<div className={`user-initial ${props.borderClass}`}>
					{
						props.username ? props.username[0] : (localStorage.getItem("username") ? localStorage.getItem("username")[0] : "U")
					}
				</div>
			</div>
			<div className={`username-string ${props.hideUsername && "hide"}`}>
				{
					props.username ? props.username : (localStorage.getItem("username") ? localStorage.getItem("username") : "Unknown")
				}
			</div>
		</button>
	);
}

Username.propTypes = {
	customClass: PropTypes.string,
	borderClass: PropTypes.string,
	hideUsername: PropTypes.bool,

	mouseOverHandler: PropTypes.func,
	mouseOutHandler: PropTypes.func,

	// redux props
	ownerId: PropTypes.string,
	username: PropTypes.string,
	jwt: PropTypes.string
}

export { Username as UsernameComponentWithoutRedux };

export default connect(
	account,
	null
)(Username);

