// KEEP FIRST-LEVEL KEYS IN ALPHABETIC ORDER
export const apiWordMap = {
	accounts: {
		access: {
			earlyRelease: {
				duplicate: "Found duplicate early release access keys. Please reach out to support to get a new access key.",
				granted: "Early release access granted. Happy Learning!!!",
				notFound: "Early release access key not found.",
				used: "Early release access key already in use.",
				emailed: "Access key emailed to ",
				notEnough: "Run out of access keys."
			}
		},
		created: {
			failed: {
				email: {
					exists: "This email is already used by another account. Please use a different one."
				},
				username: {
					exists: "Username already exists. Please try another one."
				},
				usernameAndEmail: {
					exists: "Username and email already exists. Please try another one."
				}
			},
			success: "Account created! Please verify your account with the message we sent to your email: ${email}. After that, we can get things rolling by helping you pick some classes. Glad to have you in our community! 😄"
		},
		verified: {
			success: "Thank you for verifying your account.",
			failed: "Invalid token. Unverified account."
		},
		loggedIn: {
			success: "Logged in successfully",
			failed: "Wrong username or password."
		},
		update: {
			failed: "Account update failed.",
			all: {
				success: "Username, email, and password has been updated."
			},
			username: {
				success: "Username has been updated."
			},
			email: {
				success: "Email address has been updated."
			},
			password: {
				success: "Password has been updated."
			}
		},
		withdrawal: {
			insufficientBalance: "Requested withdrawal amount exceeds earnings balance.",
			lessThanMinimumWithdrawal: "Requested withdrawal amount is less than minimum withdrawal amount.",
			withdrawalPending: "Withdrawal request successfully submitted. Our payments team will process your request in the next 48 hours."
		}
	},
	bookmark: {
		saved: {
			success: "Saved bookmark.",
			failed: "Failed to save bookmark. Please try again."
		},
		deleted: {
			success: "Deleted bookmark.",
			failed: "Deleted bookmark failed. Please try again."
		}
	},
	feedback: {
		received: {
			home: "Thank you for your input. For every feedback you provide, you're helping us better serve you and the community.",
			clazz: "Thank you for your feedback on this class. We'll use this to improve our classes and our relationship with content creators.",
			topic: {
				missing: "We really appreciate your feedback! 😁 Our team will look into this."
			}
		},
		failed: {
			type: "Incorrect, or missing, feedback type.",
			ownerId: "Please login first.",
			classId: "Missing class ID.",
			message: "Missing feedback message.",
			invalidAccount: "Invalid account."
		},
	},
	groups: {
		create: {
			success: "Successfully created group.",
			failure: "Failed to create group."
		},
		join: {
			alreadyInGroup: "Already in group.",
			invalidGroupCode: "Invalid group code.",
			success: "Successfully joined group."
		},
		leave: {
			adminsCantLeave: "Admins can't leave the group.",
			success: "Successfully left group."
		},
		remove: {
			failAdmin: "Admins can't be removed from the group.",
			success: "Successfully removed member. Refresh page to view the latest members list."
		}
	},
	knowledge: {
		view: {
			save: {
				success: "view saved.",
				failed: {
					missing: {
						viewName: "Missing view name.",
						workbookName: "Missing workbook name.",
						sheetName: "Missing sheet name.",
						rootName: "Missing root name",
						depth: "Missing depth.",
						zoomLevel: "Missing zoom level."
					},
					alreadyExists: "View already exists."
				}
			},
			delete: {
				success: "view deleted.",
				failed: "Failed to delete"
			}
		}
	},
	note: {
		update: {
			updated: "Note updated",
			failed: "Failed to update note. Please try again."
		},
		save: {
			saved: "Note saved",
			failed: "Failed to save note. Please try again."
		},
		delete: {
			deleted: "Note deleted",
			failed: "Failed to delete note. Please try again."
		}
	},
	notifications: {
		delete: {
			deleted: "Notification deleted.",
			failed: "Failed to delete notification."
		}
	},
	checkout: {
		successfulPurchase: "Congratulations! Your Purchase Was Successful!",
		paypal: {
			redirect: "You are now being redirected to Paypal."
		}
	},
	confirmation: {
		complete: {
			thankYou: "Thank you for your payment!",
			emailConfirmation: "An email confirmation has been sent.",
			redirect: "You will now be redirected to the learning page. Happy learning!"
		},
		pending: "Payment pending.",
		invalid: "Invalid transaction."
	},
	practice: {
		expIncrease: {
			success: "Experience increased.",
			failed: "Failed to increase experience."
		},
		levelIncrease: {
			success: "Level increased.",
			failed: "Failed to increase level."
		},
		progress: {
			success: "Progress saved.",
			failed: "Failed to save progress."
		},
		inventory: {
			success: "Inventory updated.",
			failed: {
				text: "Failed to update inventory",
				invalidItem: "Invalid inventory item."
			}
		}
	},
	promotion: {
		earlyAccess: "Awesome! We'll email you as soon as we go live."
	},
	promo: {
		educator: {
			incentives: {
				comm: `Automatically opted into limited time bonus for commissions. Please email us the video's title
							you uploaded, including your username and email, to support@exerinstitute.com to receive these
							bonuses. Once confirmed, your video will receive either the 80%, or 90%, bonus, depending on your
							video's quality for a whole year. You can upload multiple videos and all of them could qualify
							for the bonus. The year countdown begins once the commissions system is deployed in February.`
			}
		}
	},
	upload: {
		success: {
			video: "Video uploaded.",
			thumbnail: "Thumbnail uploaded.",
			mentalModel: "Mental model(s) uploaded.",
			subtitle: "Subtitle uploaded.",
			transcoding: "Video is now being encoded for adaptive streaming; once finished, the video will be available to the public."
		},
		failed: {
			video: {
				duplicateName: "Upload failed. Found duplicate video title."
			}
		},
		missing: {
			fields: "Please correct the following fields: ",
			videoDescription: "Couldn't find video description.",
			videoFile: "Couldn't find video file.",
			videoTitle: "Couldn't find video title",
			thumbnail: "Couldn't find thumbnail.",
			thumbnailDescription: "Couldn't find thumbnail description.",
			mentalModel: "Couldn't find mental model.",
			subtitle: "Couldn't find subtitle."
		}
	},
	module: {
		success: {
			created: "Module created.",
			edited: "Updated module",
			deleted: "Module deleted."
		},
		failed: {
			invalidIds: "Module members need to be videos.",
			edit: "Failed to edit module.",
			delete: "Failed to delete module."
		},
		missing: {
			name: "Please provide a module name.",
			description: "Please provide a module description",
			videoIds: "Please provide at least 2 module members."
		}
	},
	edit: {
		success: {
			videoTitle: "Video title edited.",
			video: "Modified video.",
			thumbnail: "Modified thumbnail.",
			thumbnailDescription: "Thumbnail description edited.",
			subtitle: "Modified subtitles.",
			mentalModel: "Modified mental model(s).",
			price: "Modified price.",
			videoDescription: "Video description edited.",
			videoTopic: "Video topic edited."
		}
	},
	delete: {
		success: "Video, thumbnail, subtitles, mental model(s), and description deleted."
	},
	purchases: {
		transaction: {
			invalid: {
				cost: "Invalid cost.",
				transactionType: "Invalid transaction type."
			},
			cost: {
				insufficientShinyNeurons: "Not enough shiny neurons to purchase.",
			},
			missing: {
				shinyNeurons: "No shiny neurons were used.",
				payor: "Your identity could not be found.",
				payee: "The content creator's identity could not be found.",
				transactionCurrency: "The content creator did not specify a currency.",
				transactionType: "Transaction details are missing.",
				transactedItemId: "The video could not be found."
			}
		},
		hints: {
			success: "Hint obtained",
			failed: "Failed to obtain hint."
		},
		answers: {
			success: "Answer obtained",
			failed: "Failed to obtain answer."
		},
		levels: {
			success: "Level increased",
			failed: "Failed to obtain level."
		},
		videos: {
			failed: {
				unnecessaryPurchase: "Invalid purchase of owned video."
			},
			success: "Congrats, your video purchase was successful. You are now being redirected to your video."
		}
	}
};