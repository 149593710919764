import { SET_PAYMENT_INFO } from "redux/actions/paymentConstants";

const initialState = {
	imgRef: null,
	price: null,
	quantity: null
};

export default function(state = initialState, action) {
	if (action.type === SET_PAYMENT_INFO) {
		return {
			...state,
			...action.payload
		}
	}
	else {
		return state;
	}
}

