import React, {useState, useEffect} from "react";

import messageMap from "Utilities/MessageMaps";

import CountableObjects from "assets/topicArticles/Math/components/templates/CountableObjects";


function ComparingCountableObjects(props) {

	const [leftValue, setLeftValue] = useState(1),
		[rightValue, setRightValue] = useState(1);

	useEffect(() => {

	}, [leftValue, rightValue]);

	function updateComparisonPhrase(newValue, position) {
		if (position === "left") {
			setLeftValue(newValue);
		}
		else if (position === "right") {
			setRightValue(newValue);
		}
	}

	return (
		<div className="comparing-countable-objects">
			<div className="countable-objects-container">
				<CountableObjects key={"test1"} objectCount={1} countable={true} changeHandler={updateComparisonPhrase} 
					position="left" widthClass="whole-width" />
				<CountableObjects key={"test2"} objectCount={1} countable={true} changeHandler={updateComparisonPhrase}
					position="right" widthClass="whole-width" />
			</div>
			<div className="comparison-text">
				{messageMap("comparisons.quantity.leftGroup", "math")}
				<div className="underline bold">
					{
						leftValue === rightValue
						? messageMap("comparisons.quantity.equalTo", "math")
						: (leftValue > rightValue
							? messageMap("comparisons.quantity.moreThan", "math")
							: messageMap("comparisons.quantity.lessThan", "math")
						)
					}
				</div>
				{messageMap("comparisons.quantity.rightGroup", "math")}
			</div>
		</div>
	);
}

export default ComparingCountableObjects;
