import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import account from "redux/selectors/accountSelector";

import { quoteAPI } from 'apis/controllers/PaymentsController';

import ShinyNeuronCard from "templates/ShinyNeuronCard";

import { CURRENCIES } from 'Utilities/Constants/PaymentConstants';
import messageMap from "Utilities/MessageMaps";
import { isValidIdentity } from 'Utilities/Validators/IdentityValidators';
import { askUserToSignUpFirst } from "templates/customModals/utilities/SignUpLoginUtilities";


function Quotes(props) {
	// State
	const [shinyNeuronCards, setShinyNeuronCards] = useState([]);
	const [signUpModal, setSignUpModal] = useState(null);
	const [loginModal, setLoginModal] = useState(null);
	// Hooks
	const stateRef = useRef();
	stateRef.current = isValidIdentity(props.ownerId);

	function continueCallbackWrapper() {
		if (!stateRef.current)
		{
			return () => askUserToSignUpFirst(setSignUpModal, setLoginModal)
		}
		else {
			return null;
		}
	}

	useEffect(() => {
		getShinyNeuronCards();
	}, []);

	function getShinyNeuronCards() {

		function _setBackupshinyNeuronCards() {// TO-DO: Deprecate this in the future, b/c prices shouldn't be available if endpoint is down.
			const shinyNeuronsArray = [{quantity: 100, price: 1.99, bonus: null},
				{quantity: 280, price: 4.99, bonus: 12},
				{quantity: 580, price: 9.99, bonus: 16},
				{quantity: 1180, price: 19.99, bonus: 18},
				{quantity: 2970, price: 49.99, bonus: 19}];
			
			_setShinyNeuronCards(shinyNeuronsArray);
		}

		function _setShinyNeuronCards(shinyNeuronsArray) {
			let cardArray = [];

			shinyNeuronsArray.forEach((neuronsObj, index) => {
				let shiny_neuron_id = "shiny_neuron_" + (index + 1)
				cardArray.push(
					<ShinyNeuronCard key={shiny_neuron_id} id={shiny_neuron_id}
						imgRef={index + 1}
						bonus={neuronsObj.bonus}
						bonusString={messageMap("payments.quotes.bonusString", "generic")}
						quantity={neuronsObj.quantity}
						price={neuronsObj.price}
						currencySymbol={CURRENCIES.USD}
						continueCallbackWrapper={continueCallbackWrapper}/>
				);
			});

			setShinyNeuronCards(cardArray);
		}

		//Actual call
		quoteAPI(response => {
			_setShinyNeuronCards(response)
		}, _setBackupshinyNeuronCards);
	}

	return (
		<div className="quotes">
			<Helmet>
        <title>{messageMap("quotes.title", "headerTag")}</title>
				<meta name="description" content={messageMap("quotes.description", "headerTag")}></meta>
			</Helmet>
			
			<div className="blurb">{messageMap("payments.quotes.quotesHeader", "generic")}</div>
			<div className="shiny-neuron-cards-container">
				{shinyNeuronCards}
			</div>

			{signUpModal}
			{loginModal}

		</div>
	);
}

export default connect(
	account,
	null
)(Quotes);