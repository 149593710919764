/**
 * @description checks if inputVal satisfies the following:
 * 	1) is at least 7 characters long
 * 	2) contains at least 1 uppercase letter
 * 	3) contains at least 1 lowercase letter
 * 	4) contains at least 1 number
 * 	5) contains at least one of the following special characters: !@#$%^&*
 * @param {String} inputVal password to validate
 * @returns {Boolean} if inputVal satisfies password requirements
 */
export function passwordValidator(inputVal) {
	let validationStatus = {};

	validationStatus["passed"] = false;
	validationStatus["hasLower"] = RegExp("^(?=.*[a-z])").test(inputVal);
	validationStatus["hasUpper"] = RegExp("^(?=.*[A-Z])").test(inputVal);
	validationStatus["hasNumber"] = RegExp("^(?=.*[0-9])").test(inputVal);
	validationStatus["hasSpecial"] = RegExp("^(?=.*[!@#$%^&*])").test(inputVal);
	validationStatus["hasLength"] = RegExp("^(?=.{7,})").test(inputVal);

	validationStatus["hasLower"] && validationStatus["hasUpper"] 
	&& validationStatus["hasNumber"] && validationStatus["hasSpecial"] 
	&& validationStatus["hasLength"] && (validationStatus["passed"] = true);

	return validationStatus;
}

/**
 * @description checks if email has an @ and if it has a '.'
 * @param {String} inputVal email to validate
 * @returns {Boolean} if inputVal is actually an email
 */
export function emailValidator(inputVal) {
	return RegExp("^(?=.*[@])(?=.*[.])").test(inputVal);
}

/**
 * @description checks if username is at least 5 characters long and is no more than 20 characters long. Only allow alphanumeric characters
 * @param {String} inputVal userName to validate
 * @returns {Boolean} if inputVal satisfies userName requirements
 */
export function userNameValidator(inputVal) {
	let validationStatus = {};

	validationStatus["hasLength"] = RegExp("^(?=[a-zA-Z0-9]{5,20})").test(inputVal);

	validationStatus["hasLength"] && (validationStatus["passed"] = true);

	return validationStatus;
}

/**
 * @description 
 * @param {String} inputVal input value to validate
 * @returns {Boolean} if inputVal satisfies userName requirements
 */
export function stringLengthValidator(inputVal, requiredLength) {
	return inputVal.length === requiredLength;
}