import {useState, useEffect} from 'react';

/**
 * @description debouncer, sets time threshold on how often a function would be called within a time period
 * @param {Function} fn 
 * @param {Number} ms 
 */
export function debounce(fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

/**
 * @description checks if adjusted window width < windowWidth threshold to see if need to replace defaultDom with domToChange.\
 * This also handles initial page reload
 * @param {Number} windowWidth 
 * @param {Node} defaultDom initial dom to render
 * @param {Node} domToChange dom to render if current window width < windowWidth
 */
export function useChangeDomByWindowWidth(windowWidth = 1100, defaultDom, domToChange) {
	let [dom, setDom] = useState(defaultDom);
	useEffect(() => {
		const debounceHandleResize = debounce(function handleResize() {
			if (window.innerWidth <= windowWidth) {
				setDom(domToChange);
			}
			else {
				setDom(defaultDom);
			}
		}, 1000);

		window.addEventListener("resize", debounceHandleResize);

		return () => {
			window.removeEventListener("resize", debounceHandleResize);
		}
	});

	if (window.innerWidth <= windowWidth) {
		dom = domToChange;
	}
	else {
		dom = defaultDom;
	}

	return dom;
}

/**
 * @description used for toggling views on/off
 * @param {Boolean} displayStatus 0 -> not displayed, 1 -> is displayed
 * @param {Node} domToChange dom to change to
 */
export function useDisplayStatus(displayStatus, domToChange) {
	const [display, setDisplay] = useState(null);
	const [isDisplay, setIsDisplay] = useState(0);
	useEffect(() => {
		if (displayStatus !== isDisplay) {
			setIsDisplay(displayStatus);
			setDisplay(domToChange);
		}
		else {
			setDisplay(null);
		}
	});

	return display;
}