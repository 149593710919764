import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';

import { connect } from "react-redux";
import { updateShinyNeuronsBalance } from 'redux/actions/actionTypes';
import account from "redux/selectors/accountSelector";

import { paymentsAPI } from 'apis/controllers/PaymentsController';

import Spinner from "templates/Spinner";

import { PAYMENT_PROCESSORS } from 'Utilities/Constants/PaymentConstants';
import messageMap from 'Utilities/MessageMaps';
import { isValidIdentity } from 'Utilities/Validators/IdentityValidators';
import { AUTO_REDIRECT_TIMEOUT_PERIOD } from 'Utilities/Constants/MediaConstants';

function PaymentConfirmation(props) {
	// Constants that need to be loaded first.
	const identityPayload = {
		ownerId: props.ownerId || localStorage.getItem("ownerId"),
		jwt: props.jwt || localStorage.getItem("jwt")
	};
	const validIdentity = isValidIdentity(identityPayload.ownerId);
	// Hooks + Query Params
	const history = useHistory();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get("token") || queryParams.get("payment_intent_client_secret");
	const remainingRetries = useRef(queryParams.get("payment_intent_client_secret") ? 5: 1);
	// State
	const [paymentProcessing, setPaymentProcessing] = useState(true);
	const [messages, setMessages] = useState([]);
	// More Constants
		const paymentProcessor = queryParams.get("PayerID") != null ? PAYMENT_PROCESSORS.PAYPAL : PAYMENT_PROCESSORS.STRIPE;

	useEffect(() => {
		if (!validIdentity || token == null) {
			history.push('/quotes');
		}
		finishPayment();
	}, [history]);

	function redirect() {
		history.push(props.redirectTarget);
	}

	function finishPayment() {
		let pathVariables = {
			paymentProcessor: paymentProcessor,
			resource: 'capture'
		};
		let payment = {
			identity: identityPayload,
			paymentProcessorToken: token
		};
		paymentsAPI(pathVariables, payment, redirectMessage);
	}

	function redirectMessage(resp) {
		let msgs = Array.isArray(resp.status) ? resp.status: [resp.status];
		let pendingMessage = messageMap("confirmation.pending", "api");
		// Attempt Pending
		--remainingRetries.current;
		if (messageMap(msgs[0],"api") == pendingMessage) {
			if (remainingRetries.current) {
				setTimeout(() => finishPayment(), 1000);
			}
			else {
				setPaymentProcessing(false);
				setMessages([messageMap("payments.confirmation.checkLater","generic")]);
				setTimeout(redirect, AUTO_REDIRECT_TIMEOUT_PERIOD);
			}
		}
		else {
			if (resp.shinyNeurons) {
				props.updateShinyNeuronsBalance(resp.shinyNeurons);
			}
			setPaymentProcessing(false);
			setMessages(msgs.map(msg => messageMap(msg, "api")));
			setTimeout(redirect, AUTO_REDIRECT_TIMEOUT_PERIOD);
		}
	}

	return (
		<div className="payment-confirm-container">
			<Helmet>
				<title>{messageMap("confirmation.title", "headerTag")}</title>
				<meta name="description" content={messageMap("confirmation.description", "headerTag")}></meta>
			</Helmet>
			{paymentProcessing ? <Spinner loadingText={messages[0]}/>
													: <div>
															{messages.map((msg, idx) => <div key={"confirm_" + idx}>{msg}</div>)}
														</div>
			}
		</div>
	);
}

PaymentConfirmation.defaultProps = {
	redirectTarget: "/",
	updateShinyNeuronsBalance: PropTypes.func.isRequired
};

export default connect(
	account,
	{ updateShinyNeuronsBalance }
)(PaymentConfirmation);