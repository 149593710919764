import messageMap from "Utilities/MessageMaps";

import thumbnailAsset from "assets/topicArticles/LearningOptimizations/environment/thumbnailFeelings.jpg";

import {DESCRIPTION, SUB_HEADER, SOURCE, SOURCE_HEADER} from "assets/topicArticles/articleConstants";


export const environmentFeelingsArticles = {
	id: "I. Environment_0_3 How our feelings affect our learning",
	title: messageMap("feeling.article.title", "learningOptimization"),
	description: messageMap("feeling.article.description", "learningOptimization"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("feeling.article.thumbnailAlt", "learningOptimization"),
	bodyFormatOrder: [
		"text1", "text2",
		"text3", "text4",
		"text5", "text6", "text7",
		"text8", "text9", "text10", "text11", "text12", "text13", "text14", "text15", "text16", "text17", "text18", "text19", "text20", "text21",
		"text22", "text23", "text24"
	],
	datePublished: "2023-11-30",
	dateModified: "2024-06-21"
};

export const environmentFeelingsArticlesTexts = {
	text1: {
		text: messageMap("feeling.texts.header1", "learningOptimization"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("feeling.texts.p1", "learningOptimization"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("feeling.texts.header2", "learningOptimization"),
		type: SUB_HEADER
	},
	text4: {
		text: messageMap("feeling.texts.p2", "learningOptimization"),
		type: DESCRIPTION
	},
	text5: {
		text: messageMap("feeling.texts.header3", "learningOptimization"),
		type: SUB_HEADER
	},
	text6: {
		text: messageMap("feeling.texts.p3", "learningOptimization"),
		type: DESCRIPTION
	},
	text7: {
		text: messageMap("feeling.texts.p4", "learningOptimization"),
		type: DESCRIPTION
	},
	text8: {
		text: messageMap("feeling.texts.header4", "learningOptimization"),
		type: SUB_HEADER
	},
	text9: {
		text: messageMap("feeling.texts.p5", "learningOptimization"),
		type: DESCRIPTION
	},
	text10: {
		text: messageMap("feeling.texts.p6", "learningOptimization"),
		type: DESCRIPTION
	},
	text12: {
		text: messageMap("feeling.texts.p7", "learningOptimization"),
		type: DESCRIPTION
	},
	text13: {
		text: messageMap("feeling.texts.p8", "learningOptimization"),
		type: DESCRIPTION
	},
	text14: {
		text: messageMap("feeling.texts.p9", "learningOptimization"),
		type: DESCRIPTION
	},
	text15: {
		text: messageMap("feeling.texts.p10", "learningOptimization"),
		type: DESCRIPTION
	},
	text16: {
		text: messageMap("feeling.texts.p11", "learningOptimization"),
		type: DESCRIPTION
	},
	text17: {
		text: messageMap("feeling.texts.p12", "learningOptimization"),
		type: DESCRIPTION
	},
	text18: {
		text: messageMap("feeling.texts.p13", "learningOptimization"),
		type: DESCRIPTION
	},
	text19: {
		text: messageMap("feeling.texts.p14", "learningOptimization"),
		type: DESCRIPTION
	},
	text20: {
		text: messageMap("feeling.texts.p15", "learningOptimization"),
		type: DESCRIPTION
	},
	text21: {
		text: messageMap("feeling.texts.16", "learningOptimization"),
		type: DESCRIPTION
	},
	text22: {
		text: messageMap("feeling.texts.p17", "learningOptimization"),
		type: DESCRIPTION
	},
	text23: {
		text: messageMap("sourceHeader", "learningOptimization"),
		type: SOURCE_HEADER
	},
	text24: {
		text: messageMap("feeling.sources.s1", "learningOptimization"),
		type: SOURCE
	},
	text25: {
		text: messageMap("feeling.sources.s2", "learningOptimization"),
		type: SOURCE
	}
};


