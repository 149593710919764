import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

import StripeForm from './StripeForm';

import { paymentsAPI } from 'apis/controllers/PaymentsController';
import { quoteAPI } from 'apis/controllers/PaymentsController';

import PayPalLogo from 'assets/images/currencies/PayPal.svg';

import { PAYMENT_PROCESSORS } from 'Utilities/Constants/PaymentConstants';
import messageMap from 'Utilities/MessageMaps';
import { getAlt, importShinyNeuron } from 'Utilities/ShinyNeuronImages';
import { isValidIdentity } from 'Utilities/Validators/IdentityValidators';


function Checkout(props) {
	// State
	const [showShinyNeuronRows, setShowShinyNeuronRows] = useState(false);
	const [shinyNeuronRows, setShinyNeuronRows] = useState([]);
	const [shinyNeuron, setShinyNeuron] = useState(null);
	const [shinyNeuronAltText, setShinyNeuronAltText] = useState(null);
	const [price, setPrice] = useState(props.payments.price);
	const [quantity, setQuantity] = useState(props.payments.quantity);
	const [currencySymbol, setCurrencySymbol] = useState("$");
	const [signUpModal, setSignUpModal] = useState(null);
	const [imgRef, setImgRef] = useState((props.payments || {}).imgRef);
	// Hooks
	const history = useHistory();
	// Constants
	const identityPayload = {
		ownerId: props.account.ownerId,
		jwt: props.account.jwt
	};
	const validIdentity = isValidIdentity(identityPayload.ownerId);

	function toggleShinyNeuronRows() {
		setShowShinyNeuronRows(!showShinyNeuronRows);
	}

	function updateShinyNeuronsBalance(shinyNeuronCards, imgRefParam = imgRef) {
		let rows = [];
		{
			shinyNeuronCards.forEach((element, idx) => {
				const relImgRef = idx + 1;
				const relImg = importShinyNeuron(relImgRef);
				const relAlt = getAlt(relImgRef);
				const selected = (relImgRef == imgRefParam) ? "selected" : null;
				const selectedText = selected ? " (" + selected + ")" : "";

				function updateCart() {
					setShinyNeuron(relImg);
					setShinyNeuronAltText(relAlt);
					setPrice(element.price);
					setQuantity(element.quantity);
					updateShinyNeuronsBalance(shinyNeuronCards, relImgRef);
				}

				rows.push(
					<div className={selected} key={"shiny_neuron_" + relImgRef}
						onClick={updateCart} onKeyDown={updateCart}>
						<img src={relImg} alt={relAlt}></img>
						<div className="spaced-apart">
							<span className="shiny-neurons-price">
								{element.quantity} {messageMap("payments.checkout.shinyNeuronPack", "generic") + selectedText}
							</span>
							<span className="shiny-neurons-price">
								{element.currencySymbol}{element.price}
							</span>
						</div>
					</div>);
			})
		}

		setShinyNeuronRows(rows);
	}

	useEffect(() => {
		if (validIdentity) {
			quoteAPI(response => updateShinyNeuronsBalance(response));
			setShinyNeuron(importShinyNeuron(imgRef));
			setShinyNeuronAltText(getAlt(imgRef));
		}
		else {
			history.push("/quotes");
		};
	}, []);

	function startPaypalPayment() {
		let pathVariables = {
			paymentProcessor: PAYMENT_PROCESSORS.PAYPAL,
			resource: 'init'
		};
		let payment = {
			paymentAmount: price,
			shinyNeuronsPurchased: quantity,
			identity: identityPayload
		};
		function paypalRedirectMessage(resp) {
			if (resp["SUCCESS"]) {
				window.location.href = resp["redirectUrl"];
			};
		}
		paymentsAPI(pathVariables, payment, paypalRedirectMessage);
	};

	return (
		validIdentity &&
		<div className="checkout-container">
			<Helmet>
				<title>{messageMap("checkout.title", "headerTag")}</title>
				<meta name="description" content={messageMap("checkout.description", "headerTag")}></meta>
			</Helmet>

			<div className="cart-container">
				<div>
					<img src={shinyNeuron} alt={shinyNeuronAltText}></img>
					<div className="spaced-apart">
						<span className="shiny-neurons-price">
							{quantity} {messageMap("payments.checkout.shinyNeuronPack", "generic")}
						</span>
						<span className="shiny-neurons-price">
							{currencySymbol}{price}
						</span>
					</div>
					<button className={showShinyNeuronRows ? "expand-button chevron-up" : "expand-button chevron-down"}
						onClick={toggleShinyNeuronRows} onKeyDown={toggleShinyNeuronRows}>
					</button>
				</div>
				<div className={showShinyNeuronRows ? "edit-shiny-neurons-list" : "no-shiny-neurons-list"}>
					{showShinyNeuronRows && shinyNeuronRows}
				</div>
				<div className="half divider" />
				<div className="half" />
				<div className="spaced-apart">
					<span className="shiny-neurons-price">
						{messageMap("payments.checkout.subtotal", "generic")}
					</span>
					<span className="shiny-neurons-price">
						{currencySymbol}{price}
					</span>
				</div>
				<div className="half divider" />
				<div className="half" />
				<div className="spaced-apart">
					<span className="total-price">
						{messageMap("payments.checkout.total", "generic")}</span>
					<span>
						<span className="currency-symbol">
							{messageMap("payments.checkout.usd", "generic")}
						</span>
						<span className="total-price">
							{currencySymbol}{price}
						</span>
					</span>
				</div>
			</div>

			<div className="payment-container">
				<div className="base-payment-form">
					<button className="payPal-button" onClick={startPaypalPayment} onKeyDown={startPaypalPayment}>
						<img className="payPal-logo" src={PayPalLogo} alt={messageMap("checkout.buyNowWithPayPal", "button")} />
					</button>
					<div className="or-div">
						<span className="or-span" />
						<span>{messageMap("payments.checkout.or", "generic")}</span>
						<span className="or-span" />
					</div>
					{(price && quantity) &&
						<div className="stripe-form-container">
							<StripeForm price={price} quantity={quantity} identityPayload={identityPayload} />
						</div>
					}
				</div>
			</div>
			{signUpModal}
		</div>);
}

function mapStateToProps(state) {
	const { account, payments } = state;
	return { account, payments };
}

export default connect(
	mapStateToProps,
	null
)(Checkout);