/* eslint-disable no-template-curly-in-string */
import {IDENTITY_VO} from "apis/models/Accounts";
import {PAGE_SIZE} from "Utilities/Constants/PageConstants";

import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";
import {modelValidator} from "Utilities/Validators/APIValidators";

const BASE = "/services/video/note";

const NOTE_UPDATE = BASE + "/update";
const NOTE_SAVE = BASE + "/save";
const NOTE_DELETE = BASE + "/delete";
const NOTE_LIST_GET = BASE + "/list/${videoId}";
const NOTE_GET = BASE + "/get/${videoId}?videoTs=${videoTs}";

/**
 * @param {Object} payload = NOTE_VO
 */
export function updateNoteAPI(payload, callback) {
	// model validator isn't working for this
	// console.log("updating NOTE_VO: ", NOTE_VO);
	// modelValidator(NOTE_VO, payload) &&
	request(NOTE_UPDATE, POST, payload, callback);
}

/**
 * @param {Object} payload = NOTE_VO
 */
export function saveNoteAPI(payload, callback) {
	// for some reason, NOTE_VO's fields are getting overwritten, which causing the validator to fail
	// console.log("NOTE_VO: ", NOTE_VO);
	// modelValidator(NOTE_VO, payload) &&
	request(NOTE_SAVE, POST, payload, callback);
}

/**
 * @param {Object} payload = NOTE_VO
 */
export function deleteNoteAPI(payload, callback) {
	// model validator isn't working for this
	// console.log("NOTE_VO: ", NOTE_VO);
	// modelValidator(NOTE_VO, payload) &&
	request(NOTE_DELETE, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = IDENTITY_VO
 */
export function notesGetAPI(pathVariables, payload, callback) {
	const api = $replace(NOTE_LIST_GET, pathVariables);
	modelValidator(IDENTITY_VO, payload) && request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String,
 * 	videoTs: String
 * }
 * @param {Object} payload = IDENTITY_VO
 */
export function noteGetAPI(pathVariables, payload, callback) {
	const api = $replace(NOTE_GET, pathVariables);
	modelValidator(IDENTITY_VO, payload) && request(api, POST, payload, callback);
}