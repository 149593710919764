import {SET_PROFILE_SUB_NAV} from "redux/actions/subNavConstants";


const initialState = {
	profileSubNav: null
};


export default function(state = initialState, action) {
	if (action.type === SET_PROFILE_SUB_NAV) {
		return {
			...state,
			profileSubNav: action.payload
		};
	}
	else {
		return state;
	}
}


