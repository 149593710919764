import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import CountableObjects from "assets/topicArticles/Math/components/templates/CountableObjects";

import thumbnailAsset from "assets/topicArticles/Math/Level 05 Math/I- Numbers and Ordering/thumbnailRomanNumerals.jpg";


export const numbersWithRomanNumeralsArticle = {
	id: "I: Numbers and Ordering_E. What are Roman Numerals",
	title: messageMap("numbersWithRomanNumerals.article.title", "math"),
	description: messageMap("numbersWithRomanNumerals.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("numbersWithRomanNumerals.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "html1", "html2", "html3", "html4", "html5", "html6", "html7", "html8", "html9", "html10", "text3",
		"text4", "html11", "html12", "html13", "html14", "html15", "text5", "html16", "html17"
	],
	datePublished: "2024-06-20",
	dateModified: "2024-06-20"
};

export const numbersWithRomanNumeralsArticleTexts = {
	text1: {
		text: messageMap("numbersWithRomanNumerals.numbersWithRomanNumeralsArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("numbersWithRomanNumerals.numbersWithRomanNumeralsArticleTexts.text2", "math"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("numbersWithRomanNumerals.numbersWithRomanNumeralsArticleTexts.text3", "math"),
		type: DESCRIPTION
	},
	text4: {
		text: messageMap("numbersWithRomanNumerals.numbersWithRomanNumeralsArticleTexts.text4", "math"),
		type: SUB_HEADER
	},
	text5: {
		text: messageMap("numbersWithRomanNumerals.numbersWithRomanNumeralsArticleTexts.text5", "math"),
		type: DESCRIPTION
	}
};

export const numbersWithRomanNumeralsArticleHTMLs = {
	html1: (
		<CountableObjects key={1} objectCount={1} useRomanNumerals={true} />
	),
	html2: (
		<CountableObjects key={2} objectCount={2} useRomanNumerals={true} />
	),
	html3: (
		<CountableObjects key={3} objectCount={3} useRomanNumerals={true} />
	),
	html4: (
		<CountableObjects key={4} objectCount={4} useRomanNumerals={true} />
	),
	html5: (
		<CountableObjects key={5} objectCount={5} useRomanNumerals={true} />
	),
	html6: (
		<CountableObjects key={6} objectCount={6} useRomanNumerals={true} />
	),
	html7: (
		<CountableObjects key={7} objectCount={7} useRomanNumerals={true} />
	),
	html8: (
		<CountableObjects key={8} objectCount={8} useRomanNumerals={true} />
	),
	html9: (
		<CountableObjects key={9} objectCount={9} useRomanNumerals={true} />
	),
	html10: (
		<CountableObjects key={10} objectCount={10} useRomanNumerals={true} />
	),
	html11: (
		<CountableObjects key={11} objectCount={1} useRomanNumerals={true} />
	),
	html12: (
		<CountableObjects key={12} objectCount={5} useRomanNumerals={true} />
	),
	html13: (
		<CountableObjects key={13} objectCount={10} useRomanNumerals={true} />
	),
	html14: (
		<CountableObjects key={13} objectCount={50} useRomanNumerals={true} />
	),
	html15: (
		<CountableObjects key={13} objectCount={100} useRomanNumerals={true} />
	),
	html16: (
		<CountableObjects key={14} objectCount={500} useRomanNumerals={true} usePictures={false} />
	),
	html17: (
		<CountableObjects key={15} objectCount={1000} useRomanNumerals={true} usePictures={false} />
	)
};

