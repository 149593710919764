import React from 'react';

function CreateFullAccount() {

	return (
		<div className="FullAccount">
			Full Account Sign Up
		</div>
	);
}

export default CreateFullAccount;