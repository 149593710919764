import React from "react";

import messageMap from "Utilities/MessageMaps";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import Arithmetic from "pages/Classes/Practice/components/Math/Arithmetic";

import thumbnailAsset from "assets/topicArticles/Math/Level 01 Math/III- Numbers/thumbnailSubtraction.jpg";


export const singleDigitSubtractionArticle = {
	id: "III: Numbers_C. Single Digit Subtraction with Pictures and Numbers",
	title: messageMap("singleDigitSubtraction.article.title", "math"),
	description: messageMap("singleDigitSubtraction.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("singleDigitSubtraction.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "html1",
		"text3", "html2"
	],
	datePublished: "2024-06-05",
	dateModified: "2024-06-05"
};

export const singleDigitSubtractionArticleTexts = {
	text1: {
		text: messageMap("singleDigitSubtraction.singleDigitSubtractionArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("singleDigitSubtraction.singleDigitSubtractionArticleTexts.text2", "math"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("singleDigitSubtraction.singleDigitSubtractionArticleTexts.text3", "math"),
		type: DESCRIPTION
	}
};

export const singleDigitSubtractionArticleHTMLs = {
	html1: (
		<Arithmetic key="static" showExpressionSolution={true} operatorType={1} usePictures={true} />
	),
	html2: (
		<Arithmetic key="dynamic" showExpressionSolution={true} operatorType={1} usePictures={true} countable={true} />
	)
};

