import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { updateShinyNeuronsBalance } from 'redux/actions/actionTypes';
import account from "redux/selectors/accountSelector";

import { purchaseVideoAPI } from 'apis/controllers/video/VideoController';

import Alert, {SUCCESS, ERROR} from 'templates/Alert';

import { getPriceInShinyNeurons } from './VideoUtilitiesList';
import { AUTO_REDIRECT_TIMEOUT_PERIOD } from 'Utilities/Constants/MediaConstants';
import messageMap from "Utilities/MessageMaps";
import { getAlt, importShinyNeuron } from 'Utilities/ShinyNeuronImages';

import closeAsset from "assets/icons/common/close.svg";


function PurchasePopUp(props) {
  const [alert, setAlert] = useState(null),
    [showExerShopButton, setShowExerShopButton] = useState(false),
    [purchaseDisabled, setPurchaseDisabled] = useState(false);
  const history = useHistory();
  const identityPayload = {
    ownerId: props.ownerId,
    jwt: props.jwt
  };

  useEffect(() => {
    if (props.shinyNeurons < getPriceInShinyNeurons(props.price)) {
      if (props.autoRedirect) {
        props.setShowPopUp(false);
        redirectToExerShop();
      }
      else {
        setShowExerShopButton(true);
        setPurchaseDisabled(true);
      }
    }
  }, []);

  function closeAlert() {
    setAlert(null);
  }

  function redirectToExerShop() {
    history.push("/quotes");
  }

  function purchaseVideo() {
    let pathVariables = {
      videoId: props.videoId
    };
    let payload = {
      identity: identityPayload,
      filters: [
        "price",
        "earningsRate"
      ]
    };

		purchaseVideoAPI(pathVariables, payload, (resp) => {
			const alertContainerStyle = {
				"position": "fixed",
				"left": "0px",
				"maxWidth": "100vw",
				"top": "50px",
				"width": "100vw"
			};
			let alertMsg = messageMap(resp["MESSAGES"][0], "api");

			if (resp["shinyNeurons"]) {
				const videoURL = "/learn?id=" + props.videoId + "&channel=" + props.channel + "&title=" + props.videoTitle.replaceAll(" ", "_");

				props.updateShinyNeuronsBalance(resp["shinyNeurons"]);

				// TO-DO: Optimize Message
				setAlert(
					<Alert alertContainerStyle={alertContainerStyle}
						type={SUCCESS} closeHandler={closeAlert}
						msg={alertMsg} timeout={AUTO_REDIRECT_TIMEOUT_PERIOD} />
				);

				if (props.postPurchaseLogic) {
					props.postPurchaseLogic();
				}
				else {
					setTimeout(() => history.push(videoURL), AUTO_REDIRECT_TIMEOUT_PERIOD);
				}
			}
			else {
				props.setShowPopUp(false);
				setAlert(
					<Alert alertContainerStyle={alertContainerStyle}
						type={ERROR} closeHandler={closeAlert}
						msg={alertMsg} timeout={AUTO_REDIRECT_TIMEOUT_PERIOD} />
				);
			}
		});
	}

  return (
    <div className="purchase-button-sub-container">
      <div className="purchase-button-pop-up">
        <button className="close-button"
                onClick={e => props.setShowPopUp(false)}
                onKeyPress={e => props.setShowPopUp(false)}>
          <img className="x-button" src={closeAsset} alt={messageMap("alerts.close", "image")}></img>
        </button>
        <div className="purchase-button-blurb">
          {messageMap("video.listPage.ownTheVideo", "generic")}
        </div>
        <img className="purchase-button-image" src={props.thumbnail} alt={props.thumbnailAlt} />
        <div className="purchase-button-buttons-container">
          {showExerShopButton ?
            <button className="exer-shop-button" onClick={redirectToExerShop} onKeyDown={redirectToExerShop}>
              <img className="icon" src={importShinyNeuron(1)}
                alt={getAlt(0)}
              ></img>
              {messageMap("links.exerShop", "button")}
            </button>
            : null}
          <button className={"purchase-button-buy-button"} onClick={purchaseVideo} onKeyDown={purchaseVideo}
            disabled={purchaseDisabled}>
            {getPriceInShinyNeurons(props.price, " Neurons")}
          </button>
        </div>
        {alert}
      </div>
    </div>
  );
}

PurchasePopUp.defaultProps = {
  autoRedirect: false // True -> Redirects user to quotes page on useEffect. False -> Keeps user on page.
};

PurchasePopUp.propTypes = {
  // Required
  channel: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  setShowPopUp: PropTypes.func.isRequired,
  thumbnail: PropTypes.string.isRequired,
  thumbnailAlt: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  // Optional
  autoRedirect: PropTypes.bool,
  postPurchaseLogic: PropTypes.func
}

export const ConnectedPurchasePopUp = connect(
  account,
  { updateShinyNeuronsBalance }
)(PurchasePopUp);