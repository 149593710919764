/* eslint-disable no-template-curly-in-string */
import {IDENTITY_VO} from "apis/models/Accounts";
import {PAGE_SIZE} from "Utilities/Constants/PageConstants";

import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";
import {modelValidator} from "Utilities/Validators/APIValidators";

const BASE = "/services/video/bookmark";

const SAVE_BOOKMARK = BASE + "/save/${videoId}";
const GET_BOOKMARK = BASE + "/get/${videoId}";
const DELETE_BOOKMARK = BASE + "/delete/${videoId}";
const GET_BOOKMARK_LIST = BASE + "/get/list/${classId}";

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = IDENTITY_VO
 */
export function saveBookmarkAPI(pathVariables, payload, callback) {
	const api = $replace(SAVE_BOOKMARK, pathVariables);
	modelValidator(IDENTITY_VO, payload) && request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = IDENTITY_VO
 */
export function checkIfBookmarkedAPI(pathVariables, payload, callback) {
	const api = $replace(GET_BOOKMARK, pathVariables);
	modelValidator(IDENTITY_VO, payload) && request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	videoId: String
 * }
 * @param {Object} payload = IDENTITY_VO
 */
export function deleteBookmarkAPI(pathVariables, payload, callback) {
	const api = $replace(DELETE_BOOKMARK, pathVariables);
	modelValidator(IDENTITY_VO, payload) && request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	classId: String
 * }
 * @param {Object} payload = IDENTITY_VO
 */
export function getBookmarkListAPI(pathVariables, payload, callback) {
	const api = $replace(GET_BOOKMARK_LIST, pathVariables);
	modelValidator(IDENTITY_VO, payload) && request(api, POST, payload, callback);
}