import { PAGE_SIZE } from "Utilities/Constants/PageConstants";
import request, { POST } from "Utilities/Fetches";
import { $replace, fillOptionalArgs } from "Utilities/Strings";

const BASE = "/services/groups";
const CREATE_GROUP = BASE + "/create";
const JOIN_GROUP = BASE + "/join";
const LEAVE_GROUP = BASE + "/leave";
const LIST_MEMBERS = BASE + "/list/members/all?pageCount=${pageCount}&pageSize=${pageSize}&membersFilter=${membersFilter}";
const LIST_GROUPS = BASE + "/list/groups/all?pageCount=${pageCount}&pageSize=${pageSize}&groupsFilter=${groupsFilter}";
const REMOVE_MEMBER = BASE + "/removeMember?username=${username}";

/**
 * @param {Object} payload = GROUP_VO
 */
export function createGroupAPI(payload, callback) {
	request(CREATE_GROUP, POST, payload, callback);
}

/**
 * @param {Object} payload = GROUP_VO
 */
export function joinGroupAPI(payload, callback) {
	request(JOIN_GROUP, POST, payload, callback);
}

/**
 * @param {Object} payload = GROUP_VO
 */
export function listGroupMembersAPI(pathVariables, payload, callback) {
	const api = $replace(LIST_MEMBERS, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} payload = IDENTITY_VO
 */
export function listGroupsAPI(pathVariables, payload, callback) {
	let newPathVariables = fillOptionalArgs(pathVariables, { pageSize: PAGE_SIZE.TEN });
	const api = $replace(LIST_GROUPS, newPathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} payload = GROUP_VO
 */
export function removeMemberAPI(pathVariables, payload, callback) {
	const api = $replace(REMOVE_MEMBER, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} payload = GROUP_VO
 */
export function leaveGroupAPI(payload, callback) {
	request(LEAVE_GROUP, POST, payload, callback);
}