/* eslint-disable no-template-curly-in-string */
import request, {GET, POST} from "Utilities/Fetches";
import {$replace, fillOptionalArgs} from "Utilities/Strings";
import {modelValidator} from "Utilities/Validators/APIValidators";

const BASE = "/services/knowledge/metadata";

const LIST_ALL_KNOWLEDGE_METADATA = BASE + "/list/all";


/**
 * @param {Array} payload // list of topics
 */
export function getAllKnowledgeMetadata(payload, callback) {
	request(LIST_ALL_KNOWLEDGE_METADATA, POST, payload, callback);
}
