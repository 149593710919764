import messageMap from "Utilities/MessageMaps";


import accountAsset from "assets/icons/navigation/pageSubMenus/account/user.svg";
import groupsAsset from "assets/icons/navigation/pageSubMenus/account/groups.svg";
import financialsAsset from "assets/icons/navigation/pageSubMenus/account/financials.svg";



export const ACCOUNT = "account";
export const TUTORING = "tutoring";
export const GROUPS = "groups";
export const FINANCIALS = "financials";
const PROFILE_MENU = {
	[ACCOUNT]: accountAsset,
	// replace asset
	// [TUTORING]: accountAsset,
	[GROUPS]: groupsAsset,
	[FINANCIALS]: financialsAsset
};
const PROFILE_MENU_ALTS = {
	[ACCOUNT]: messageMap("subPages.profile.account", "image"),
	// [TUTORING]: "",
	[GROUPS]: messageMap("subPages.profile.groups", "image"),
	[FINANCIALS]: messageMap("subPages.profile.financials", "image")
};
const PROFILE_MENU_LABELS = {
	[ACCOUNT]: messageMap("profile.account", "subPageNavigation"),
	// [TUTORING]: messageMap("profile.tutoring", "subPageNavigation"),
	[GROUPS]: messageMap("profile.groups", "subPageNavigation"),
	[FINANCIALS]: messageMap("profile.financials", "subPageNavigation")
};


//************** Exports ************//
export const PROFILE_PAGE_MENU = "profile";


export const SUB_MENU_TYPES_IMAGES = {
	profile: PROFILE_MENU
};
export const SUB_MENU_TYPES_ALTS = {
	profile: PROFILE_MENU_ALTS
};
export const SUB_MENU_TYPES_LABELS = {
	profile: PROFILE_MENU_LABELS
};


