import React from "react";

import messageMap from "Utilities/MessageMaps";

import StaticImages from "assets/topicArticles/Math/components/StaticImages";
import ComparingSizableObjects from "../../components/ComparingSizableObjects";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/thumbnailLengths.jpg";
import dimensionsAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/dimensions.svg";
import pencilAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/pencil.svg";


export const comparingLengthsArticle = {
	id: "II: Relations_D. Comparing Lengths Through Pictures",
	title: messageMap("comparingLengths.article.title", "math"),
	description: messageMap("comparingLengths.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("comparingSizes.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "html1",
		"text3", "html2"
	],
	datePublished: "2024-06-05",
	dateModified: "2024-06-05"
};

export const comparingLengthsArticleTexts = {
	text1: {
		text: messageMap("comparingLengths.comparingLengthsArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("comparingLengths.comparingLengthsArticleTexts.text2", "math"),
		type: DESCRIPTION
	},
	text3: {
		text: messageMap("comparingLengths.comparingLengthsArticleTexts.text3", "math"),
		type: DESCRIPTION
	}
};

export const comparingLengthsArticleHTMLs = {
	html1: (
		<StaticImages key={"dimensions"} imgContainerClass="center"
			images={{
				left: {
					img: dimensionsAsset,
					alt: "Showing width, length, and height of rectangular prism.",
					class: "two-hundred-eighty-height"
				}
			}} />
	),
	html2: (
		<ComparingSizableObjects key="comparingLengths" imgToResize={pencilAsset} imgAlt="Pencil" sizing="length" />
	)
};


