import React, {useState, Fragment} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {logInUser} from "redux/actions/actionTypes";

import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";
import messageMap from "Utilities/MessageMaps";

import Alert from "templates/Alert";
import Modal from "templates/Modal";
import Spinner from "templates/Spinner";
import {modalSchema} from "templates/schemas/navBarSchemas";
import TermsNConditionsModal from "templates/customModals/TermsNConditionsModal";
import PrivacyPolicyModal from "templates/customModals/PrivacyPolicyModal";

import {signUpAPI, usernameAvailabilityAPI} from "apis/controllers/person/AccountsController";

import checkAsset from "assets/icons/home/check.svg";
import exAsset from "assets/icons/home/ex.svg";

/**
 * @description custom modal for Signing Up
 * @param {String} title modal's title
 * @param {String} subHeader? modal's subheader
 * @param {Function} closeModal for closing the modal
 */
function SignUpModal(props) {
	const [alert, setAlert] = useState(""),
				[anotherModal, setAnotherModal] = useState(""),
				[spinner, setSpinner] = useState("");

	const errorMsg = (
		<div className="error-msg">
			{messageMap("account.signUp.errorMsg.msg", "generic")}
			<span>
				{messageMap("account.signUp.errorMsg.highlight", "generic")}
			</span>
		</div>
	);

	const disclaimer = (
		<Fragment>
			<div>
				{messageMap("account.signUp.agreement.text", "generic")}
				<button className="terms-policy" onClick={() => openAnotherModal("terms")} onKeyPress={() => openAnotherModal("terms")}>
					{messageMap("account.signUp.agreement.termOfUse", "generic")}
				</button>
				{messageMap("account.signUp.agreement.and", "generic")}
				<button className="terms-policy" onClick={() => openAnotherModal("privacy")} onKeyPress={() => openAnotherModal("privacy")}>
					{messageMap("account.signUp.agreement.privacyPolicy", "generic")}
				</button>
			</div>
		</Fragment>
	);

	const signUpModalSchema = modalSchema(props.closeModal, openAnotherModal, checkUsername)["signUp"];

	function signUp(signUpStates) {
		const usernameValue = signUpStates.signUpUser.value;

		if (checkHasExplicitWords(usernameValue)) {
			setAlert(
				<Alert type="error" closeHandler={closeAlert} msg={messageMap("input.explicit.input.username", "validation")}></Alert>
			);
			return;
		}

		const payload = {
			email: signUpStates.signUpEmail.value,
			hashedPassword: signUpStates.signUpPass.value,
			username: signUpStates.signUpUser.value,
			accessKey: ""
		};

		const spinnerContainerStyle = {
			"margin-left": "-50px",
			"margin-top": "-120px"
		};
		setSpinner(<Spinner spinnerContainerStyle={spinnerContainerStyle}/>);

		signUpAPI(payload, 
			(data) => {
				if (data.message && data.message.includes("failed")) {
					displayErrorMessage(data.message);
				}
				else {
					props.logInUser(data);
					displaySignUpMessage(data, payload.email)
				}
			},
			(msg) => displayErrorMessage(msg)
		);
	}

	function displaySignUpMessage(data, email) {
		setSpinner(null);

		if (data.httpStatus) {
			const message = messageMap(data.message, "api");

			// TODO: create more data parsing for offensive username checks done in the BE
			setAlert(
				<Alert closeHandler={closeAlert} type="information" msg={message} />
			);
		}
		else {
			props.closeModal(null);
			let message = messageMap("accounts.created.success", "api");
			message = message.replace("${email}", email);

			setAlert(
				<Alert closeHandler={closeAlert} type="success" msg={message}/>
			);
		}
	}

	function displayErrorMessage(msg) {
		setSpinner(null);
		setAlert(
			<Alert closeHandler={closeAlert} type="error" msg={messageMap(msg, "api")}/>
		);
	}

	function closeAlert () {
		setAlert(null);
	}

	function closeAnotherModal() {
		setAnotherModal(null);
	}

	function openAnotherModal(type) {
		const closeButtonStyle = {
			"position": "absolute",
			"marginLeft": "195px",
			"marginTop": "15px",
			"filter": "invert(1)"
		},
		modalContainerStyle = {
			"padding": "0px"
		};

		if (type === "terms") {
			setAnotherModal(
				<TermsNConditionsModal closeHandler={closeAnotherModal}/>
			);
		}
		else if (type === "privacy") {
			setAnotherModal(
				<PrivacyPolicyModal closeHandler={closeAnotherModal}/>
			);
		}
		else if (type === "signInPrivacy") {
			setAnotherModal(
				<Modal closeHandler={closeAnotherModal} closeType={"xButton"}
								closeButtonStyle={closeButtonStyle} modalContainerStyle={modalContainerStyle}>
					<div>
						{messageMap("account.signUp.privacy.data", "generic")}
					</div>
				</Modal>
			);
		}
	}

	function checkUsername(...args) {
		const target = args[0].target;
		usernameAvailabilityAPI({username: args[1].value}, (resp) => displayUsernameResultIcon(resp, target));
	}

	function displayUsernameResultIcon(resp, event) {
		// remove existing checks or exes icons
		event.parentNode.childNodes.forEach(dom => {
			if (dom.tagName === "IMG" && dom.className === "") {
				dom.remove();
			}
		});

		if (!resp) {
			// Apologies. I know this is an anti-pattern, but I don't know how to do this in the 'React' way
			let img = document.createElement("img")
			img.src = checkAsset;
			img.alt = "Check icon indicating that the username is available";
			img.style.display = "inline-block";
			img.style.marginLeft = "5px";
			img.style.height = "25px";
			event.parentNode.insertBefore(img, event.nextSibling);
		}
		else {
			// Apologies. I know this is an anti-pattern, but I don't know how to do this in the 'React' way
			let img = document.createElement("img")
			img.src = exAsset;
			img.alt = "Cross icon indicating that the username is not available";
			img.style.display = "inline-block";
			img.style.marginLeft = "5px";
			img.style.height = "25px";
			event.parentNode.insertBefore(img, event.nextSibling);
		}
	}

	return (
		<div>
			<Modal stateProps={signUpModalSchema.stateProps}
						closeHandler={props.closeModal} submitHandler={signUp}
						subHeader={props.subHeader}
						title={props.title} titleStyle={{"width": "350px"}}
						inputs={signUpModalSchema.inputs} footer={disclaimer} errorMsg={errorMsg} 
						inputsToValidate={signUpModalSchema.stateProps.slice(0, signUpModalSchema.stateProps.length - 2)}/>
			{anotherModal}
			{alert}
			{spinner}
		</div>
	);
}

SignUpModal.propTypes = {
	title: PropTypes.string.isRequired,
	subHeader: PropTypes.any,
	closeModal: PropTypes.func.isRequired,

	// redux actions
	logInUser: PropTypes.func.isRequired
};

export default connect(
	null,
	{logInUser}
)(SignUpModal);