import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import {flipCoinForPositiveOrNegativeNumber, rollNSidedDice} from "pages/Classes/Practice/utilities/MathUtilities";

import {CORRECT, WRONG} from "pages/Classes/Practice/constants/PracticeConstants";


/**
 * @param {String} matchId // parent prop for re-rendering
 * @param {Object} answer // to determine if user's answer is right/wrong
 * @param {Function} setAnswerCorrectness // parent function used to check if user's answer is right/wrong
 * @param {Function} setClearResponseFunc // to clear user's answer
 * @param {Array} possibleOptions // for creating user options
 */
function TrueFalse(props) {

	const [showOption, setShowOption] = useState();

	const trueInputRef = useRef(),
		falseInputRef = useRef();

	useEffect(() => {
		attachParentProps();
		randomizePicLabel();
	}, [props.matchId]);

	function randomizePicLabel() {
		const showAnswerOrOtherOptions = flipCoinForPositiveOrNegativeNumber();

		if (showAnswerOrOtherOptions) {
			setShowOption(props.answer.current);
		}
		else {
			let possibleOptionsCopy = [...props.possibleOptions];
			possibleOptionsCopy.splice(possibleOptionsCopy.indexOf(props.answer.current), 1);
			const falseAnswer = possibleOptionsCopy[rollNSidedDice(possibleOptionsCopy.length)];

			setShowOption(falseAnswer);
		}
	}

	function attachParentProps() {
		props.setClearResponseFunc(clearResponse);
	}

	function clearResponse() {
		falseInputRef.current.checked = false;
		trueInputRef.current.checked = false;
		randomizePicLabel();
	}

	function solveTrueFalse(event) {
		const clickedButtonVal = event.target.getAttribute("name");

		if (clickedButtonVal === "True") {
			falseInputRef.current.checked = false;
		}
		else if (clickedButtonVal === "False") {
			trueInputRef.current.checked = false;
		}

		if (showOption === props.answer.current) {
			if (clickedButtonVal === "True") {
				props.setAnswerCorrectness(CORRECT);
			}
			else {
				props.setAnswerCorrectness(WRONG);
			}
		}
		else {
			if (clickedButtonVal === "False") {
				props.setAnswerCorrectness(CORRECT);
			}
			else {
				props.setAnswerCorrectness(WRONG);
			}
		}
	}

	return (
		<div className="true-false-component">
			<div className="guessed-picture-label">
				{showOption}
			</div>
			<div className="true-false-container">
				<div className="true-false-option">
					<label htmlFor="True">
						{messageMap("practicePage.answerComponent.true", "generic")}
					</label>
					<input className="radio-input" ref={trueInputRef}
									onClick={solveTrueFalse} onKeyPress={solveTrueFalse}
									type="radio" id="True" name="True" value="True"></input>
				</div>
				<div className="true-false-option">
					<label htmlFor="False">
						{messageMap("practicePage.answerComponent.false", "generic")}
					</label>
					<input className="radio-input" ref={falseInputRef}
									onClick={solveTrueFalse} onKeyPress={solveTrueFalse}
									type="radio" id="False" name="False" value="False"></input>
				</div>
			</div>
		</div>
	);
}

TrueFalse.propTypes = {
	matchId: PropTypes.string.isRequired,
	answer: PropTypes.object.isRequired,
	possibleOptions: PropTypes.array.isRequired,
	setAnswerCorrectness: PropTypes.func.isRequired,
	setClearResponseFunc: PropTypes.func.isRequired
}

export default TrueFalse;

