import React from "react";

import messageMap from "Utilities/MessageMaps";

import ComparingSizableObjects from "assets/topicArticles/Math/components/ComparingSizableObjects";

import {DESCRIPTION, SUB_HEADER} from "assets/topicArticles/articleConstants";

import thumbnailAsset from "assets/topicArticles/Math/Level 01 Math/II- Relations/thumbnailSizes.jpg";


export const comparingSizesArticle = {
	id: "II: Relations_C. Comparing Sizes Through Pictures",
	title: messageMap("comparingSizes.article.title", "math"),
	description: messageMap("comparingSizes.article.description", "math"),
	thumbnail: thumbnailAsset,
	thumbnailAlt: messageMap("comparingSizes.article.thumbnailAlt", "math"),
	bodyFormatOrder: [
		"text1", "text2", "html1"
	],
	datePublished: "2024-06-05",
	dateModified: "2024-06-05"
};

export const comparingSizesArticleTexts = {
	text1: {
		text: messageMap("comparingSizes.comparingSizesArticleTexts.text1", "math"),
		type: SUB_HEADER
	},
	text2: {
		text: messageMap("comparingSizes.comparingSizesArticleTexts.text2", "math"),
		type: DESCRIPTION
	}
};

export const comparingSizesArticleHTMLs = {
	html1: (
		<ComparingSizableObjects key="compare1" />
	)
};


