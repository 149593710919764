import messageMap from "Utilities/MessageMaps";

export function getUserBrowser() {
	let userAgent = navigator.userAgent;
	let browserName;
	
	if (userAgent.match(/chrome|chromium|crios/i)) {
		browserName = "Chrome";
	}
	else if (userAgent.match(/firefox|fxios/i)) {
		browserName = "Firefox";
	}
	else if(userAgent.match(/safari/i)) {
		browserName = "Safari";
	}
	else if(userAgent.match(/opr\//i)) {
		browserName = "Opera";
	}
	else if(userAgent.match(/edg/i)) {
		browserName = "Microsoft Edge";
	}
	else {
		browserName = messageMap("metadata.browser.notDetected", "generic");
	}

	return browserName;
}

export function getUserPlatform() {
	let userAgent = navigator.userAgent;
	let platform;

	if (userAgent.indexOf("Win") !== -1) {
		platform = "Windows OS";
	}
	else if (userAgent.indexOf("Mac") !== -1) {
		platform = "MacOS";
	}
	else if (userAgent.indexOf("X11") !== -1) {
		platform = "UNIX OS";
	}
	else if (userAgent.indexOf("Linux") !== -1) {
		platform = "Linux OS";
	}
	else {
		platform = messageMap("metadata.platform.notDetected", "generic");
	}

	return platform;
}