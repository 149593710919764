import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { setPaymentInfo } from 'redux/actions/actionTypes';

import { CURRENCIES } from 'Utilities/Constants/PaymentConstants';
import { importShinyNeuron, getAlt } from 'Utilities/ShinyNeuronImages';
import messageMap from 'Utilities/MessageMaps';

/**
 * @description
 * @param {Number} quantity The quantity of neurons
 * @param {String} currencySymbol Currency symbol (ex. $ for USD)
 * @param {Number} price The price for a given quanity.
 * @param {Number} bonus? Should be an integer.
 * @param {String} bonusString? Should be a string that indicates what the bonus applies to.
 * @param {String} img image, usually neuron(s)
 * @param {String} alt image description for screen readers
 */
function ShinyNeuronCard(props) {
	const [isHovered, setIsHovered] = useState(false);

	const history = useHistory();
	const src = importShinyNeuron(props.imgRef);
	const alt = getAlt(props.imgRef);

	function handleMouseEnter() {
		setIsHovered(true);
	}
	
	function handleMouseLeave() {
		setIsHovered(false);
	}

	function goToCheckout(event) {
		let callback = props.continueCallbackWrapper();
	
		if (callback) {
			event.preventDefault();
			callback();
		}
		else {
			props.setPaymentInfo(props);

			history.push('/checkout');
		}
	}

	return (
		<div className="neuron-card-container" tabIndex={0} onClick={props.clickHandler} onKeyDown={props.clickHandler}
			onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<div className="card">
				<div className="neuron-quantity-container">
					<div className="neuron-quantity">
						{props.quantity + " Neurons"}
					</div>
				</div>

				<div className="neuron-img-container">
					{
						props.bonus && props.bonusString 
						? // Returns a bonus ribbon or placeholder div.
						(
							<div className="neuron-bonus-ribbon">
								{props.bonus + props.bonusString}
							</div>
						)
						: <div/>
					}
					<img className="img" src={src} alt={alt}></img>
				</div>

				<div className="neuron-price">
					{props.currencySymbol + props.price}
				</div>
				
				{
					isHovered
					? (
							<div className='continue-button-container'>
								<Link to="/checkout" className="continue-link"
											onClick={goToCheckout} onKeyDown={goToCheckout}>
									{messageMap("quotes.continue", "button")}
								</Link>
							</div>
						)
					: null
				}
				
			</div>
		</div>
	);
}

ShinyNeuronCard.propTypes = {
	// Pricing
	quantity: PropTypes.number.isRequired,
	currencySymbol: PropTypes.oneOf(Object.values(CURRENCIES)).isRequired,//TO-DO: Return currency from BE.
	price: PropTypes.number.isRequired,
	bonus: PropTypes.number,
	bonusString: PropTypes.string,
	// // Image
	imgRef: PropTypes.number.isRequired,
	// Continue Button
	continue: PropTypes.bool,
	continueCallbackWrapper: PropTypes.func,
  setPaymentInfo: PropTypes.func.isRequired
};

export default connect(
	null,
	{ setPaymentInfo }
)(ShinyNeuronCard);