import {CLEAR_API_KEYS} from "redux/actions/apiConstants";

const initialState = {
	tinyMce: null
};

export default function(state = initialState, action) {
	if (action.type === CLEAR_API_KEYS) {
		return {
			tinyMce: null
		}
	}
	else {
		return state;
	}
}