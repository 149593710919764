import React, { Fragment, useState } from 'react';
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";

import VideoUpload from "pages/Classes/Videos/components/VideoUpload";
import Module from "pages/Classes/Videos/components/Module";

import Modal from "templates/Modal";

import { AUTO_SAVE_TIMEOUT_PERIOD } from "Utilities/Constants/MediaConstants";

export const videosSchema = {
	tableHeader: [
		messageMap("video.editPage.videosTableHeaders.title", "generic"),
		messageMap("video.editPage.videosTableHeaders.mentalModel", "generic"),
		messageMap("video.editPage.videosTableHeaders.uploadDate", "generic"),
		messageMap("video.editPage.videosTableHeaders.viewCount", "generic"),
		messageMap("video.editPage.videosTableHeaders.pricing", "generic"),
		messageMap("video.editPage.videosTableHeaders.earnings", "generic"),
		// TODO: uncomment once we have paid videos
		// , "Free / Paid"
	],
	row(iteratorValues, logicValues, onClickHandlers, domRefs) {
		return (
			<tr key={iteratorValues.item.videoId}>
				<td className="checkbox-cell">
					<input type="checkbox" ref={(el) => (domRefs.checkboxRefs.current[iteratorValues.index + 1] = el)}
						// this can get parsed as entityid and entityId. I chose entityId for readability.
						entityid={iteratorValues.item.videoId}
						videotitle={iteratorValues.item.title}
						duration={iteratorValues.item.duration}
						onClick={e => onClickHandlers.handleCheckboxHandler(iteratorValues.index + 1)}
						onKeyPress={e => onClickHandlers.handleCheckboxHandler(iteratorValues.index + 1)} />
				</td>
				<td className="title-cell">
					{logicValues.isProcessing}
					<img className="thumbnail" role="button" tabIndex={0}
						src={logicValues.thumbnailFile} alt={iteratorValues.item.thumbnailDescription}
						onClick={e => onClickHandlers.openEntityThumbnailModalHandler(iteratorValues.item.videoId)}
						onKeyPress={e => onClickHandlers.openEntityThumbnailModalHandler(iteratorValues.item.videoId)}></img>
					<div className="entity-title" role="button" title={logicValues.fullTitle} tabIndex={0}>
						{logicValues.videoTitle}
					</div>
				</td>
				<td className="mentalModel-container">
					{
						logicValues.mentalModelFile ?
							<img className="mentalModel" role="button" tabIndex={0}
								// TODO: Create mental model image description for accessibility (This should be populated by the user). Using placeholder for now
								src={logicValues.mentalModelFile} alt={"A mental model of the concepts discussed in the video"}
								onClick={e => onClickHandlers.openMentalModelModalHandler(logicValues.mentalModelFile)}
								onKeyPress={e => onClickHandlers.openMentalModelModalHandler(logicValues.mentalModelFile)}></img>
							: <div></div>
					}
				</td>
				<td className="body-text">
					{logicValues.date}
				</td>
				<td className="body-text">
					{onClickHandlers.getViewCountHandler(iteratorValues.item.viewCount)}
				</td>
				{/* TODO: uncomment once we have paid videos */}
				<td className="body-text">
					<PriceInput videoId={iteratorValues.item.videoId} price={iteratorValues.item.price}
						currencySymbol={onClickHandlers.currencySymbol}
						getPriceInShinyNeurons={onClickHandlers.getPriceInShinyNeurons}
						updatePendingEdits={onClickHandlers.updatePendingEdits} />
				</td>
				{/* <td>
					{el.isPublic ? "Free" : "Paid"}
				</td> */}
				{/* <td className="body-text">
						{/* Spacing can improve, numbered inputs use some space for the ^,v number toggle. */}

				{/* </td> */}
				<td className="body-text">
					{logicValues.earnings ? onClickHandlers.currencySymbol + logicValues.earnings : null}
				</td>
			</tr>
		);
	},
	editModal(resp, closeModal) {
		const reg = /(?<=(\/)([a-zA-Z0-9]{10})(-{1})).*/g;

		let videoFileName = resp.videoUploadLocation;
		videoFileName = videoFileName.match(reg)[0];

		let thumbnailFileName = resp.thumbnailUploadLocation;
		thumbnailFileName = thumbnailFileName.match(reg)[0];

		// For now, just assume that we're still allowing users to upload only one mental model
		let mentalModelFileName = resp.mentalModels.length ? resp.mentalModels[0].uploadLocation : null;
		mentalModelFileName = mentalModelFileName ? mentalModelFileName.match(reg)[0] : null;

		let subtitleFileName = null;
		if (resp.subtitleUploadLocation != null) {
			subtitleFileName = resp.subtitleUploadLocation;
			subtitleFileName = subtitleFileName.match(reg)[0];
		}
		const modalContainerStyle = {
			textAlign: "justify",
			backgroundColor: "#fff"
		},
			titleStyle = {
				textAlign: "center",
				fontSize: "36px"
			};

		return (
			<Modal closeHandler={closeModal} closeType="xButton"
				title={"Edit Video"} titleStyle={titleStyle}
				modalContainerStyle={modalContainerStyle}>
				<VideoUpload videoTitle={resp.title} videoFileName={videoFileName}
					thumbnailFileName={thumbnailFileName} thumbnailDescription={resp.thumbnailDescription}
					mentalModelFileName={mentalModelFileName}
					subtitleFileName={subtitleFileName} topicID={resp.videoTopicID}
					price={resp.price} videoDescription={resp.description}
					videoId={resp.id} submitText={messageMap("update.text", "button")} />
			</Modal>
		);
	}
};

export const modulesSchema = {
	tableHeader: [
		messageMap("video.editPage.videosTableHeaders.title", "generic"),
		messageMap("video.editPage.videosTableHeaders.uploadDate", "generic")
		// TODO: uncomment once we created private access to videos
		// Private / Public
	],
	row(iteratorValues, logicValues, onClickHandlers, domRefs) {
		return (
			<tr key={iteratorValues.item.playlistId}>
				<td className="checkbox-cell">
					<input type="checkbox" ref={(el) => (domRefs.checkboxRefs.current[iteratorValues.index + 1] = el)}
						// this can get parsed as entityid and entityId. I chose entityId for readability.
						entityid={iteratorValues.item.playlistId}
						videotitle={iteratorValues.item.playlistName}
						duration={iteratorValues.item.totalDuration}
						onClick={e => onClickHandlers.handleCheckboxHandler(iteratorValues.index + 1)}
						onKeyPress={e => onClickHandlers.handleCheckboxHandler(iteratorValues.index + 1)} />
				</td>
				<td className="module-thumbnail">
					<div className="outer-border">
						<div className="inner-border">
							<img className="thumbnail" role="button" tabIndex={0}
								src={logicValues.thumbnailFile} alt={iteratorValues.item.thumbnailDescription}></img>
						</div>
					</div>
					<div className="entity-title" role="button" title={logicValues.fullPlaylistName} tabIndex={0}>
						{logicValues.playlistName}
					</div>
				</td>
				<td className="body-text">
					{logicValues.date}
				</td>
			</tr>
		);
	},
	editModal(resp, closeModal) {
		const modalContainerStyle = {
			textAlign: "justify",
			backgroundColor: "#fff"
		},
			titleStyle = {
				textAlign: "center",
				fontSize: "36px"
			};

		return (
			<Modal closeHandler={closeModal} closeType="xButton"
				title={"Edit Module"} titleStyle={titleStyle}
				modalContainerStyle={modalContainerStyle}>
				<Module moduleId={resp.playlistId} videoIds={resp.itemIds}
					moduleName={resp.playlistName} moduleDescription={resp.description}
					modalPurpose="update"
					submitText={messageMap("editVideoPage.modules.update", "button")} />
			</Modal>
		);
	}
};

function PriceInput(props) {
	const [price, setPrice] = useState(props.price);

	return (
		<Fragment>
			<label htmlFor="price">
				<span>
					{props.currencySymbol}
				</span>
				<input className="price-input" placeholder={props.price} type="number" min="0"
					onChange={(e) => {
						props.updatePendingEdits(props.videoId, "price", e.target.value, AUTO_SAVE_TIMEOUT_PERIOD);
						setPrice(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter")
							props.updatePendingEdits(props.videoId);
					}} />
				=
				<span className="converted-price">
					{props.getPriceInShinyNeurons(price)}
				</span>
				<span className="shiny-neurons-text">
					Neurons
				</span>
			</label>
		</Fragment>
	)
}

PriceInput.propTypes = {
	videoId: PropTypes.string,
	price: PropTypes.string,
	currencySymbol: PropTypes.string,
	updatePendingEdits: PropTypes.func,
	getPriceInShinyNeurons: PropTypes.func
};


