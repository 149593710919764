import {getLocalData, GET} from "Utilities/Fetches";
import {createPrefixTree, searchWordInTrie} from "Utilities/Algos";

import bannedWordsAsset from "assets/bannedWords.txt";
import contextualBannedWordsAsset from "assets/contextualBannedWords.txt";

var BANNED_WORDS_TRIE = {};
var CONTEXTUAL_BANNED_WORDS_TRIE = {};

/**
 * @description creates dictionary trees from public/bannedWords.txt and public/contextualBannedWords.txt.
 */
export function initializeExplicitDictionaryTree() {
	createExplicitDictionaryTree(bannedWordsAsset, BANNED_WORDS_TRIE);
	createExplicitDictionaryTree(contextualBannedWordsAsset, CONTEXTUAL_BANNED_WORDS_TRIE);
}

function createExplicitDictionaryTree(filePath, treeContainer) {
	var onReadyStateChangeHandler = function(e) {
		if (e.target.readyState === 4) {
			if (e.target.status === 200 || e.target.status === 0) {

				createPrefixTree(e.target.responseText.split("\n"), treeContainer, true);
			}
		}
	};

	getLocalData(GET, filePath, onReadyStateChangeHandler);
}

// word map is taken from: https://www.freewebheaders.com/full-list-of-bad-words-banned-by-google/
// It includes the list from facebook, youtube, wordpress, and google
// More banned words are also taken from: http://www.bannedwordlist.com/
// The first link contains a list of profanity from other languages: British, French, German, Italian, Spanish, Chinese, Indonesian
// I created a master list (bannedWords.txt) and I've gone through all the words to see to see if some words
// can only be considered offensive in certain contexts and not offensive in another (contextualBannedWords.txt).
// I've also removed words that aren't offensive at all regardless of the context (removedBannedWords.txt) it's being used in.
// TODO: come up with a rule to help identify offensive words in the future and a way to update our existing list
// TODO: support for different languages would depend on traffic data
// TODO: should allow the user to appeal, depending on the circumstances that they're using the blacklisted word/phrase
export function checkHasExplicitWordsStrict(text) {
	text = text.toLowerCase();
	let contextuallyExplicit = searchWordInTrie(text, CONTEXTUAL_BANNED_WORDS_TRIE)[0];
	let alwaysExplicit = searchWordInTrie(text, BANNED_WORDS_TRIE)[0];

	return contextuallyExplicit || alwaysExplicit;
}

export function checkHasExplicitWords(text) {
	text = text.toLowerCase();
	return searchWordInTrie(text, BANNED_WORDS_TRIE)[0];
}

export function checkHasExplicitWordsContextually(text) {
	text = text.toLowerCase();
	return searchWordInTrie(text, CONTEXTUAL_BANNED_WORDS_TRIE)[0];
}

export function initializeExplicitContent() {

}

function checkViolence(text) {

}

function checkGore(text) {

}

function checkNudity(text) {

}

function checkPornography(text) {

}

export function checkExplicitContent(content) {

}

// Will need to use a 3rd party service, such as google cloud video intelligence to look directly into the video frames