import {level01MathMessageMap} from "./Level 01 Math/level01MathMessageMap";
import {level05MathMessageMap} from "./Level 05 Math/level05MathMessageMap";

export const mathMessageMap = {
	...level01MathMessageMap,
	...level05MathMessageMap,
	shapeProperties: {
		corners: "Corners:",
		sides: "Sides:",
		edges: "Edges: ",
		vertices: "Vertices: ",
		faces: "Faces: ",
		baseEdgeCount: "Face edge count: "
	},
	descriptions: {
		text: "Description: "
	},
	comparisons: {
		position: {
			left: "to the left ",
			right: "to the right ",
			front: "in front of ",
			behind: "behind ",
			under: "under ",
			above: "above ",
			personRelative: "The person is ",
			ballRelative: "of the ball.",
			fenceRelative: "the fence.",
			treeRelative: "the tree."
		},
		quantity: {
			equalTo: "equal to ",
			moreThan: "more than ",
			lessThan: "less than ",
			leftGroup: "The left group is ",
			rightGroup: " the right group.",
			count: "Count: ",
			stepSize: "Step size: "
		},
		size: {
			left: "The left object is ",
			right: " than the right object.",
			bigger: "bigger ",
			smaller: "smaller ",
			equal: "the same size as"
		},
		length: {
			longer: "longer ",
			shorter: "shorter ",
			equal: "the same length as"
		}
	},
	numberWords: {
		one: "one",	two: "two", three: "three", four: "four", five: "five", six: "six", seven: "seven", eight: "eight", nine: "nine",
		ten: "ten", eleven: "eleven", twelve: "twelve", thirteen: "thirteen", fourteen: "fourteen", fifteen: "fifteen", sixteen: "sixteen", seventeen: "seventeen", eighteen: "eighteen", nineteen: "nineteen",
		twenty: "twenty", twentyOne: "twenty-one", twentyTwo: "twenty-two", twentyThree: "twenty-three", twentyFour: "twenty-four", twentyFive: "twenty-five", twentySix: "twenty-six", twentySeven: "twenty-seven", twentyEight: "twenty-eight", twentyNine: "twenty-nine",
		thirty: "thirty", thirtyOne: "thirty-one", thirtyTwo: "thirty-two", thirtyThree: "thirty-three", thirtyFour: "thirty-four", thirtyFive: "thirty-five", thirtySix: "thirty-six", thirtySeven: "thirty-seven", thirtyEight: "thirty-eight", thirtyNine: "thirty-nine",
		forty: "forty", fortyOne: "forty-one", fortyTwo: "forty-two", fortyThree: "forty-three", fortyFour: "forty-four", fortyFive: "forty-five", fortySix: "forty-six", fortySeven: "forty-seven", fortyEight: "forty-eight", fortyNine: "forty-nine",
		fifty: "fifty", fiftyOne: "fifty-one", fiftyTwo: "fifty-two", fiftyThree: "fifty-three", fiftyFour: "fifty-four", fiftyFive: "fifty-five", fiftySix: "fifty-six", fiftySeven: "fifty-seven", fiftyEight: "fifty-eight", fiftyNine: "fifty-nine",
		sixty: "sixty", sixtyOne: "sixty-one", sixtyTwo: "sixty-two", sixtyThree: "sixty-three", sixtyFour: "sixty-four", sixtyFive: "sixty-five", sixtySix: "sixty-six", sixtySeven: "sixty-seven", sixtyEight: "sixty-eight", sixtyNine: "sixty-nine",
		seventy: "seventy", seventyOne: "seventy-one", seventyTwo: "seventy-two", seventyThree: "seventy-three", seventyFour: "seventy-four", seventyFive: "seventy-five", seventySix: "seventy-six", seventySeven: "seventy-seven", seventyEight: "seventy-eight", seventyNine: "seventy-nine",
		eighty: "eighty", eightyOne: "eighty-one", eightyTwo: "eighty-two", eightyThree: "eighty-three", eightyFour: "eighty-four", eightyFive: "eighty-five", eightySix: "eighty-six", eightySeven: "eighty-seven", eightyEight: "eighty-eight", eightyNine: "eighty-nine",
		ninety: "ninety", ninetyOne: "ninety-one", ninetyTwo: "ninety-two", ninetyThree: "ninety-three", ninetyFour: "ninety-four", ninetyFive: "ninety-five", ninetySix: "ninety-six", ninetySeven: "ninety-seven", ninetyEight: "ninety-eight", ninetyNine: "ninety-nine",
		oneHundred: "one hundred"
	},
	ordinalWord: {
		one: "first",	two: "second", three: "third", four: "fourth", five: "fifth", six: "sixth", seven: "seventh", eight: "eighth", nine: "ninth",
		ten: "tenth", eleven: "eleventh", twelve: "twelfth", thirteen: "thirteenth", fourteen: "fourteenth", fifteen: "fifteenth", sixteen: "sixteenth", seventeen: "seventeenth", eighteen: "eighteenth", nineteen: "nineteenth",
		twenty: "twentieth", twentyOne: "twenty-first", twentyTwo: "twenty-second", twentyThree: "twenty-third", twentyFour: "twenty-fourth", twentyFive: "twenty-fifth", twentySix: "twenty-sixth", twentySeven: "twenty-seventh", twentyEight: "twenty-eighth", twentyNine: "twenty-ninth",
		thirty: "thirtieth", thirtyOne: "thirty-first", thirtyTwo: "thirty-second", thirtyThree: "thirty-third", thirtyFour: "thirty-fourth", thirtyFive: "thirty-fifth", thirtySix: "thirty-sixth", thirtySeven: "thirty-seventh", thirtyEight: "thirty-eighth", thirtyNine: "thirty-ninth",
		forty: "fortieth", fortyOne: "forty-first", fortyTwo: "forty-second", fortyThree: "forty-third", fortyFour: "forty-fourth", fortyFive: "forty-fifth", fortySix: "forty-sixth", fortySeven: "forty-seventh", fortyEight: "forty-eighth", fortyNine: "forty-ninth",
		fifty: "fiftieth", fiftyOne: "fifty-first", fiftyTwo: "fifty-second", fiftyThree: "fifty-third", fiftyFour: "fifty-fourth", fiftyFive: "fifty-fifth", fiftySix: "fifty-sixth", fiftySeven: "fifty-seventh", fiftyEight: "fifty-eighth", fiftyNine: "fifty-ninth",
		sixty: "sixtieth", sixtyOne: "sixty-first", sixtyTwo: "sixty-second", sixtyThree: "sixty-third", sixtyFour: "sixty-fourth", sixtyFive: "sixty-fifth", sixtySix: "sixty-sixth", sixtySeven: "sixty-seventh", sixtyEight: "sixty-eighth", sixtyNine: "sixty-ninth",
		seventy: "seventieth", seventyOne: "seventy-first", seventyTwo: "seventy-second", seventyThree: "seventy-third", seventyFour: "seventy-fourth", seventyFive: "seventy-fifth", seventySix: "seventy-sixth", seventySeven: "seventy-seventh", seventyEight: "seventy-eighth", seventyNine: "seventy-ninth",
		eighty: "eightieth", eightyOne: "eighty-first", eightyTwo: "eighty-second", eightyThree: "eighty-third", eightyFour: "eighty-fourth", eightyFive: "eighty-fifth", eightySix: "eighty-sixth", eightySeven: "eighty-seventh", eightyEight: "eighty-eighth", eightyNine: "eighty-ninth",
		ninety: "ninetieth", ninetyOne: "ninety-first", ninetyTwo: "ninety-second", ninetyThree: "ninety-third", ninetyFour: "ninety-fourth", ninetyFive: "ninety-fifth", ninetySix: "ninety-sixth", ninetySeven: "ninety-seventh", ninetyEight: "ninety-eighth", ninetyNine: "ninety-ninth",
		oneHundred: "one hundredth"
	},
	ordinalNumber: {
		one: "1st",	two: "2nd", three: "3rd", four: "4th", five: "5th", six: "6th", seven: "7th", eight: "8th", nine: "9th",
		ten: "10th", eleven: "11th", twelve: "12th", thirteen: "13th", fourteen: "14th", fifteen: "15th", sixteen: "16th", seventeen: "17th", eighteen: "18th", nineteen: "19th",
		twenty: "20th", twentyOne: "21st", twentyTwo: "22nd", twentyThree: "23rd", twentyFour: "24th", twentyFive: "25th", twentySix: "26th", twentySeven: "27th", twentyEight: "28th", twentyNine: "29th",
		thirty: "30th", thirtyOne: "31st", thirtyTwo: "32nd", thirtyThree: "33rd", thirtyFour: "34th", thirtyFive: "35th", thirtySix: "36th", thirtySeven: "37th", thirtyEight: "38th", thirtyNine: "39th",
		forty: "40th", fortyOne: "41st", fortyTwo: "42nd", fortyThree: "43rd", fortyFour: "44th", fortyFive: "45th", fortySix: "46th", fortySeven: "47th", fortyEight: "48th", fortyNine: "49th",
		fifty: "50th", fiftyOne: "51st", fiftyTwo: "52nd", fiftyThree: "53rd", fiftyFour: "54th", fiftyFive: "55th", fiftySix: "56th", fiftySeven: "57th", fiftyEight: "58th", fiftyNine: "59th",
		sixty: "60th", sixtyOne: "61st", sixtyTwo: "62nd", sixtyThree: "63rd", sixtyFour: "64th", sixtyFive: "65th", sixtySix: "66th", sixtySeven: "67th", sixtyEight: "68th", sixtyNine: "69th",
		seventy: "70th", seventyOne: "71st", seventyTwo: "72nd", seventyThree: "73rd", seventyFour: "74th", seventyFive: "75th", seventySix: "76th", seventySeven: "77th", seventyEight: "78th", seventyNine: "79th",
		eighty: "80th", eightyOne: "81st", eightyTwo: "82nd", eightyThree: "83rd", eightyFour: "84th", eightyFive: "85th", eightySix: "86th", eightySeven: "87th", eightyEight: "88th", eightyNine: "89th",
		ninety: "90th", ninetyOne: "91st", ninetyTwo: "92nd", ninetyThree: "93rd", ninetyFour: "94th", ninetyFive: "95th", ninetySix: "96th", ninetySeven: "96th", ninetyEight: "98th", ninetyNine: "99th",
		oneHundred: "100th"
	},
	romanNumerals: {
		one: "I",	two: "II", three: "III", four: "IV", five: "V", six: "VI", seven: "VII", eight: "VIII", nine: "IX",
		ten: "X", eleven: "XI", twelve: "XII", thirteen: "XIII", fourteen: "XIV", fifteen: "XV", sixteen: "XVI", seventeen: "XVII", eighteen: "XVIII", nineteen: "XIX",
		twenty: "XX", twentyOne: "XXI", twentyTwo: "XXII", twentyThree: "XXIII", twentyFour: "XXIV", twentyFive: "XXV", twentySix: "XXVI", twentySeven: "XXVII", twentyEight: "XXVIII", twentyNine: "XXIX",
		thirty: "XXX", thirtyOne: "XXXI", thirtyTwo: "XXXII", thirtyThree: "XXXIII", thirtyFour: "XXXIV", thirtyFive: "XXXV", thirtySix: "XXXVI", thirtySeven: "XXXVII", thirtyEight: "XXXVIII", thirtyNine: "XXXIX",
		forty: "XL", fortyOne: "XLI", fortyTwo: "XLII", fortyThree: "XLIII", fortyFour: "XLIV", fortyFive: "XLV", fortySix: "XLVI", fortySeven: "XLVII", fortyEight: "XLVIII", fortyNine: "XLVIX",
		fifty: "L", fiftyOne: "LI", fiftyTwo: "LII", fiftyThree: "LIII", fiftyFour: "LIV", fiftyFive: "LV", fiftySix: "LVI", fiftySeven: "LVII", fiftyEight: "LVIII", fiftyNine: "LIX",
		sixty: "LX", sixtyOne: "LXI", sixtyTwo: "LXII", sixtyThree: "LXIII", sixtyFour: "LXIV", sixtyFive: "LXV", sixtySix: "LXVI", sixtySeven: "LXVII", sixtyEight: "LXVIII", sixtyNine: "LXIX",
		seventy: "LXX", seventyOne: "LXXI", seventyTwo: "LXXII", seventyThree: "LXXIII", seventyFour: "LXXIV", seventyFive: "LXXV", seventySix: "LXXVI", seventySeven: "LXXVII", seventyEight: "LXXVIII", seventyNine: "LXXIX",
		eighty: "LXXX", eightyOne: "LXXXI", eightyTwo: "LXXXII", eightyThree: "LXXXIII", eightyFour: "LXXXIV", eightyFive: "LXXXV", eightySix: "LXXXVI", eightySeven: "LXXXVII", eightyEight: "LXXXVIII", eightyNine: "LXXXIX",
		ninety: "XC", ninetyOne: "XCI", ninetyTwo: "XCII", ninetyThree: "XCIII", ninetyFour: "XCIV", ninetyFive: "XCV", ninetySix: "XCVI", ninetySeven: "XCVII", ninetyEight: "XCVIII", ninetyNine: "XCIX",
		oneHundred: "C",
		fiveHundred: "D",
		oneThousand: "M"
	}
};