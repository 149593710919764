/* eslint-disable no-template-curly-in-string */
import { LOGIN_VO, IDENTITY_VO, ACCOUNT_VO } from "apis/models/Accounts";

import request, { GET, POST } from "Utilities/Fetches";
import { $replace } from "Utilities/Strings";
import { modelValidator } from "Utilities/Validators/APIValidators";

const crypto = require("crypto");

const BASE = "/services/accounts";

const LOGIN = BASE + "/login";
const AUTO_LOGIN = BASE + "/auto/login";
const SIGN_UP = BASE + "/signUp";
const LOG_OUT = BASE + "/logOut";
const USERNAME_AVAILABILITY = BASE + "/username/availability/${username}";
const GET_ACCOUNT_SETTINGS = BASE + "/settings/get";
const SAVE_ACCOUNT_SETTINGS = BASE + "/settings/save";
const VERIFY_TOKEN = BASE + "/verify/${token}";
const WITHDRAW_EARNINGS = BASE + "/withdraw";

/**
 * @param {Object} payload = LOGIN_VO
 */
export function loginAPI(payload, callback) {
	payload.password = crypto.createHash("sha512").update(payload.password).digest("hex");
	modelValidator(LOGIN_VO, payload) && request(LOGIN, POST, payload, callback);
}

/**
 * @param {Object} payload = IDENTITY_VO
 */
export function autoLoginAPI(payload, callback) {
	modelValidator(IDENTITY_VO, payload) && request(AUTO_LOGIN, POST, payload, callback);
}

/**
 * @param {Object} payload  = ACCOUNT_VO
 */
export function signUpAPI(payload, callback, errorHandler) {
	payload.hashedPassword = crypto.createHash("sha512").update(payload.hashedPassword).digest("hex");
	modelValidator(ACCOUNT_VO, payload) && request(SIGN_UP, POST, payload, callback, errorHandler);
}

/**
 * @param {Object} payload = IDENTITY_VO
 */
export function logOutAPI(payload, callback) {
	modelValidator(IDENTITY_VO, payload) && request(LOG_OUT, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	username: String
 * }
 */
export function usernameAvailabilityAPI(pathVariables, callback) {
	const api = $replace(USERNAME_AVAILABILITY, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} payload = IDENTITY_VO
 */
export function getAccountSettingsAPI(payload, callback) {
	modelValidator(IDENTITY_VO, payload) && request(GET_ACCOUNT_SETTINGS, POST, payload, callback);
}

/**
 * @description model validation isn't required, since we're allowing users to update as many and as little fields as they'd like
 * @param {Object} payload = ACCOUNT_VO
 */
export function saveAccountSettingsAPI(payload, callback) {
	payload.account.hashedPassword = crypto.createHash("sha512").update(payload.account.hashedPassword).digest("hex");
	request(SAVE_ACCOUNT_SETTINGS, POST, payload, callback);
}

// TODO: create APIs for just updating username, password, and email

/**
 * @param {*} pathVariables = {
 * 	token: String
 * }
 */
export function verifyTokenAPI(pathVariables, callback) {
	const api = $replace(VERIFY_TOKEN, pathVariables);
	request(api, GET, null, callback);
}

/**
 * @param {Object} payload = IDENTITY_VO
 */
export function withdrawEarningsAPI(payload, callback) {
	modelValidator(IDENTITY_VO, payload) && request(WITHDRAW_EARNINGS, POST, payload, callback);
}
