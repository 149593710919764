import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";

const BASE = "/services/api";

const GET_WOLFRAM_ALPHA_STEP_BY_STEP_ANSWER = BASE + "/get/wolframalpha/stepByStep/answer?input=${input}";


/**
 * @param {Object} payload = IDENTITY_VO
 * @param {Object} pathVariables = {
 * 	input: String
 * }
 */
export function getWolframAlphaStepByStepAnswerAPI(payload, pathVariables, callback) {
	const api = $replace(GET_WOLFRAM_ALPHA_STEP_BY_STEP_ANSWER, pathVariables);
	request(api, POST, payload, callback);
}
