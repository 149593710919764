export const statBagModalClass = {
	backgroundColor: "inherit"
};
export const statBagModalContainerStyle = {
	border: "1px solid #C1C1C1",
	padding: "16px",
	backgroundColor: "white",
	width: "380px",
	maxHeight: "900px"
};
export const statBagCloseButtonStyle = {
	marginTop: "-20px",
	marginRight: "-20px"
};
export const statBagModalTitleStyle = {
	fontSize: "24px",
	fontWeight: 600,
	letterSpacing: "inherit",
	marginBottom: "24px"
};