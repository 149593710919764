import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";


function TopicNavigator(props) {
	const previousText = messageMap("learnPage.navigation.previousTopic", "generic");
	const nextText = messageMap("learnPage.navigation.nextTopic", "generic");

	return (
		<div className="resource-topic-navigation-container">
			{
				props.previousLink ?
				(
					<Link to={props.previousLink} onClick={props.goToPreviousTopic} onKeyPress={props.goToPreviousTopic}
						className={`previous-topic-button chevron-left ${props.showOrHideLeftButton === "hide" ? "hide" : ""}`}>
						{previousText}
					</Link>
				)
				:
				(
					<button className={`previous-topic-button chevron-left ${props.showOrHideLeftButton === "hide" ? "hide" : ""}`}
						onClick={props.goToPreviousTopic} onKeyPress={props.goToPreviousTopic}>
						{previousText}
					</button>
				)
			}
			{
				props.nextLink ?
				(
					<Link to={props.nextLink} onClick={props.goToNextTopic} onKeyPress={props.goToNextTopic}
						className={`next-topic-button chevron-right ${props.showOrHideRightButton === "hide" ? "hide" : ""}`}>
						{nextText}
					</Link>
				)
				:
				(
					<button className={`next-topic-button chevron-right ${props.showOrHideRightButton === "hide" ? "hide" : ""}`}
						onClick={props.goToNextTopic} onKeyPress={props.goToNextTopic}>
					{nextText}
					</button>
				)
			}
		</div>
	);
}

TopicNavigator.propTypes = {
	goToPreviousTopic: PropTypes.func,
	goToNextTopic: PropTypes.func,

	nextLink: PropTypes.object,
	previousLink: PropTypes.object,

	showOrHideLeftButton: PropTypes.string,
	showOrHideRightButton: PropTypes.string
};

export default TopicNavigator;
