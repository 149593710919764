/* eslint-disable no-template-curly-in-string */
import {FEEDBACK_VO} from "apis/models/Feedback";

import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";
import {modelValidator} from "Utilities/Validators/APIValidators";

const BASE = "/services/feedback";

const GENERAL = BASE + "/general?ownerId=${ownerId}&jwt=${jwt}";
const CLAZZ = BASE + "/clazz?ownerId=${ownerId}&jwt=${jwt}";
const ITEMS = BASE + "/missing/items?ownerId=${ownerId}&jwt=${jwt}";
export const CLASS = BASE + "/class/${classId}";



/**
 * @param {Object} pathVariables = {
 * 	ownerId: String,
 * 	jwt: String
 * }
 * @param {Object} payload = FEEDBACK_VO
 */
export function generalFeedbackAPI(pathVariables, payload, callback) {
	const api = $replace(GENERAL, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	ownerId: String,
 * 	jwt: String
 * }
 * @param {Object} payload = FEEDBACK_VO
 */
export function clazzFeedbackAPI(pathVariables, payload, callback) {
	const api = $replace(CLAZZ, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	ownerId: String,
 * 	jwt: String
 * }
 * @param {Object} payload = FEEDBACK_VO
 */
export function missingItemsFeedbackAPI(pathVariables, payload, callback) {
	const api = $replace(ITEMS, pathVariables);
	request(api, POST, payload, callback);
}

/**
 * @param {Object} pathVariables = {
 * 	classId: String
 * }
 */
export function classAPI(pathVariables, callback) {
	const api = $replace(CLASS, pathVariables);
	request(api, GET, null, callback);
}