import React, {useState, useEffect, useRef, Fragment} from 'react';
import {useHistory} from "react-router-dom";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

import {connect} from "react-redux";
import accountProgressSelector from "redux/selectors/accountProgressSelector";

import RowNesting from "pages/KnowledgeTree/Topic/components/RowNesting";

import messageMap from "Utilities/MessageMaps";
import {
	NODES_MAP, getFirstTopicInQuest,
	generateKnowledgeNetwork, getQuestDetailsFromId
} from "diagrams/utilities/NetworkGenerator";
import { GRADE_SCHOOL_WORKBOOK, DEGREES_WORKBOOK, JOBS_WORKBOOK } from "diagrams/utilities/NetworkConstants";
import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";
import {getTopicAsset, getTopicAvailability} from "pages/KnowledgeTree/Topic/utilities/TopicUtilities";
import {askUserToSignUpFirst} from "templates/customModals/utilities/SignUpLoginUtilities";

import {getQuestProgressAPI} from "apis/controllers/person/PersonProgressController";

import Alert from "templates/Alert";
import Search from "templates/Search";


/**
 * @param {Object} componentType // either modal or page
 * @param {String} title? // custom component title
 * @param {String} subHeader? // custom component sub title
 * @param {Function} closeHandler? // function to call when user clicks on 'x' icon
 */
function Topic(props) {

	const history = useHistory();

	const [topicItems, setTopicItems] = useState(),
				[alert, setAlert] = useState(),
				[loginModal, setLoginModal] = useState(null),
				[signUpModal, setSignUpModal] = useState();

	// DOM ref
	const gradeSchoolButtonRef = useRef(),
				degreesButtonRef = useRef(),
				jobsButtonRef = useRef(),
				inputSuggestionRef = useRef(),
				// function ref
				closeHandlerPointerReferenceRef = useRef(),
				clearSetInputPointerReferenceRef = useRef(),
				// value ref
				rootNodeRef = useRef("root"),
				currentExcelWorkbookRef = useRef(GRADE_SCHOOL_WORKBOOK),
				personProgressRef = useRef();

	const identityPayload = {
		ownerId: props.ownerId ? props.ownerId : localStorage.getItem("ownerId"),
		jwt: props.jwt ? props.jwt : localStorage.getItem("jwt")
	};

	useEffect(() => {
		generateTopicItems(GRADE_SCHOOL_WORKBOOK);
	}, [props.ownerId]);


	function generateTopicItems(category, nodeValue = "root", cardDesign = "card") {
		currentExcelWorkbookRef.current = category;
		rootNodeRef.current = nodeValue;
		changeButtonStyleToSelected(category);
		clearInputString();

		generateKnowledgeNetwork(nodeValue, 1, 0, category, "id",
														() => renderTopicList(cardDesign, nodeValue));
	}

	function renderTopicList(cardDesign, topic) {
		if (NODES_MAP[currentExcelWorkbookRef.current].length > 1) {
			if (props.componentType !== "modal" && cardDesign === "card" && identityPayload.ownerId && identityPayload.jwt) {
				getQuestProgressAPI(identityPayload, resp => {
					personProgressRef.current = resp;
					setTopicListState(cardDesign, generateTopicList(cardDesign, topic, resp));
				});
			}
			else {
				setTopicListState(cardDesign, generateTopicList(cardDesign, topic));
			}
		}
		else {
			showTopicSuggestion(cardDesign, topic);
		}
	}

	function setTopicListState(cardDesign, uiCards) {
		setTopicItems(
			<Fragment >
				{
					cardDesign === "row" ?
					(
						<button className="back-button" tabIndex="0"
										onClick={resetTopicItems}
										onKeyPress={resetTopicItems}>
							{messageMap("uploadVideoPage.back", "button")}
						</button>
					)
					: <div style={{display: "none"}}></div>
				}
				{uiCards}
			</Fragment>
		);
	}

	function generateTopicList(cardDesign, topic, resp) {
		let uiCard = [];
		const progressKeys = resp ? Object.keys(resp) : null;

		Array.isArray(NODES_MAP[currentExcelWorkbookRef.current]) && NODES_MAP[currentExcelWorkbookRef.current].forEach(node => {
			if (node.label !== "root") {

				if (cardDesign === "card") {
					const topicAsset = getTopicAsset(node.id);
					const topicImageClass = topicAsset ? "topic-item-image" : "topic-item-image hide";
					const topicButtonClassName = props.componentType && props.componentType === "modal" ? "topic-item-card" : (topicAsset ? "topic-item-card page" : "topic-item-card page no-pic");
					const topicImage = (
						props.componentType && props.componentType === "modal"
						? <div></div>
						: <img src={topicAsset} className={topicImageClass}></img>
					);

					const topicIsAvailable = getTopicAvailability(node.id) || (props.componentType && props.componentType === "modal");
					// TODO: check if user is starting or resuming
					const journeyPoint = progressKeys && progressKeys.includes(node.id) ? messageMap("knowledgeTree.quest.resume", "button") : messageMap("knowledgeTree.quest.start", "button");

					if (topicIsAvailable) {
						uiCard.push(
							<div className="topic-item-container" key={node.id}>
								{topicImage}
								<div key={node.id} className={topicButtonClassName}
									onClick={e => displayItemBreakdown(e, node.id, "row")}
									onKeyPress={e => displayItemBreakdown(e, node.id, "row")}>
									{
										topicIsAvailable
										? node.label
										: (
											<Fragment>
												{node.label}
												<div style={{display: "block"}}>
													{`[${messageMap("knowledgeTree.quest.construction", "generic")}]`}
												</div>
											</Fragment>
										)
									}
									{
										topicIsAvailable && props.componentType !== "modal"
										? (
											<Fragment>
												<button className="journey-or-practice" domkey="practice">
													{messageMap("practice.text", "button")}
												</button>
												<button className="journey-or-practice" domkey="startResume">
													{journeyPoint}
												</button>
											</Fragment>
										)
										: null
									}
								</div>
							</div>
						);
					}
				}
				else if (cardDesign === "row") {
					if (topic !== node.label) {
						const missingItemSuggestionHandler = ((props.componentType && props.componentType === "modal") || node.practiceCategory) ? showTopicSuggestion : null;
						const topicClickHandler = ((props.componentType && props.componentType === "modal") || node.practiceCategory) ? practiceChosenTopic : null;
						// need to be explicit with this, to prevent defaultProps value
						const isPartOfModal = props.componentType && props.componentType === "modal" ? true : false;

						uiCard.push(
							<RowNesting key={node.id} workbookType={currentExcelWorkbookRef.current}
													node={node} hasBackButton={true} includeChevron={true} rowType={"subtree"}
													missingItemSuggestionHandler={missingItemSuggestionHandler}
													topicClickHandler={topicClickHandler} isPartOfModal={isPartOfModal}/>
						);
					}
				}
			}
		});

		return uiCard;
	}

	function showTopicSuggestion(cardDesign, topic) {
		let uiCard = [];

		uiCard.push(
			<div className="topic-suggestion-container">
				<div>
					{
						props.componentType === "modal"
						? `${messageMap("video.upload.topicSuggestion.none", "generic")} '${topic}'`
						: `${messageMap("video.upload.topicExercises.none", "generic")} '${topic}'`
					}
				</div>
				<div>
					{
						props.componentType === "modal"
						? `${messageMap("video.upload.topicSuggestion.suggest", "generic")}`
						: `${messageMap("video.upload.topicExercises.suggest", "generic")}`
					}
				</div>
				<input type="text" className="suggestion-input"
								onChange={checkMissingTopicInput} ref={inputSuggestionRef}></input>

				<button tabIndex={0} className="suggestion-submit-button"
								onClick={submitMissingTopic} onKeyPress={submitMissingTopic}>
					{messageMap("submit.text", "button")}
				</button>
			</div>
		);

		setTopicItems(
			<Fragment >
				{
					cardDesign === "row" ?
					(
						<button className="back-button" tabIndex="0"
										onClick={resetTopicItems}
										onKeyPress={resetTopicItems}>
							{messageMap("uploadVideoPage.back", "button")}
						</button>
					)
					: <div style={{display: "none"}}></div>
				}
				{uiCard}
			</Fragment>
		);
	}

	function submitMissingTopic() {
		if (inputSuggestionRef.current.value !== "" || inputSuggestionRef.current.value != null) {
			props.closeHandler && props.closeHandler(inputSuggestionRef.current.value, "missingAnItem", currentExcelWorkbookRef.current);
		}
	}

	function practiceChosenTopic(topicId, topicDetails) {
		if (props.componentType !== "modal") {
			history.push({
				pathname: "/exercise?subject=" + topicDetails.rootLabel + "&topic=" + topicId.replaceAll(" ", "-"),
				state: {
					topicId: topicId,
					topicDetails: topicDetails,
					subject: topicDetails.rootLabel,
					workbook: currentExcelWorkbookRef.current,
					isJourney: false
				}
			});
		}
	}

	function checkMissingTopicInput(e) {
		const missingTopicInputValue = e.target.value;

		if (checkHasExplicitWords(missingTopicInputValue)) {
			const alertContainerStyle = {
				"top": "75px"
			};
			setAlert(
				<Alert type="error" closeHandler={closeAlert}
								msg={messageMap("input.explicit.text", "validation")}
								alertContainerStyle={alertContainerStyle}
								timeout={0} display="alert" customiconid="validation"></Alert>
			);
			inputSuggestionRef.current.value = "";
		}
	}

	function closeAlert() {
		setAlert(null);
	}

	function resetTopicItems() {
		generateTopicItems(currentExcelWorkbookRef.current, "root");
	}

	function getChosenTopic(chosenId, nodeItem) {
		if (nodeItem.type !== "topic") {
			closeDropdown();
			generateTopicItems(currentExcelWorkbookRef.current, chosenId, "row");
		}
		else if (props.componentType !== "modal") {
			let topicDetails = getQuestDetailsFromId(chosenId);
			topicDetails = topicDetails && topicDetails[0][chosenId];

			if (topicDetails && topicDetails.practiceArgs) {
				history.push({
					pathname: "/exercise?subject=" + topicDetails.rootLabel + "&topic=" + chosenId.replaceAll(" ", "-"),
					state: {
						topicId: chosenId,
						topicDetails: topicDetails,
						subject: topicDetails.rootLabel,
						workbook: currentExcelWorkbookRef.current,
						isJourney: true
					}
				});
			}
		}
		else {
			props.closeHandler && props.closeHandler(chosenId, "predefined", currentExcelWorkbookRef.current);
		}
	}

	function displayItemBreakdown(event, topic, cardDesign) {
		const clickedElement = event.target.tagName;
		const domKey = event.target.getAttribute("domkey");

		if ((clickedElement === "BUTTON" && domKey === "practice" && props.componentType !== "modal")
					|| (clickedElement !== "BUTTON" && (getTopicAvailability(topic) || (props.componentType && props.componentType === "modal")))) {
			rootNodeRef.current = topic;

			if (cardDesign === "subsection") {
				toggleChevron(event);
			}
			else {
				generateKnowledgeNetwork(topic, 1, 0, currentExcelWorkbookRef.current,
																"id", () => renderTopicList(cardDesign, topic));
			}
	
			if (props.componentType !== "modal") {
				window.scrollTo(0, 0);
			}
		}
		else if (clickedElement === "BUTTON" && domKey === "startResume") {
			if (identityPayload.ownerId) {
				let topicId = personProgressRef.current[topic];
				let topicDetails;
	
				if (!topicId) {
					topicId = getFirstTopicInQuest(topic, currentExcelWorkbookRef.current);
				}
				topicDetails = getQuestDetailsFromId(topicId);
				topicDetails = topicDetails && topicDetails[0][topicId];
				history.push({
					pathname: "/exercise?subject=" + topicDetails.rootLabel + "&topic=" + topicId.replaceAll(" ", "-"),
					state: {
						topicId: topicId,
						topicDetails: topicDetails,
						subject: topicDetails.rootLabel,
						workbook: currentExcelWorkbookRef.current,
						isJourney: true
					}
				});
			}
			else {
				askUserToSignUpFirst(setSignUpModal, setLoginModal);
			}
		}
	}

	function closeDropdown() {
		closeHandlerPointerReferenceRef.current();
	}

	function clearInputString() {
		clearSetInputPointerReferenceRef.current.clear();
	}

	function toggleChevron(event) {
		const target = event.target;

		if (target.className === "topic-item-row") {
			target.childNodes[1].className = target.childNodes[1].className === "chevron-down" ? "chevron-up" : "chevron-down";
		}
		else if (["chevron-down", "chevron-up"].includes(target.className)) {
			target.className = target.className === "chevron-down" ? "chevron-up" : "chevron-down";
		}
		else if (["chevron-down", "chevron-up"].includes(target.nextElementSibling.className)) {
			target.nextElementSibling.className = target.nextElementSibling.className === "chevron-down" ? "chevron-up" : "chevron-down";
		}
	}

	function changeButtonStyleToSelected(category) {
		if (category === GRADE_SCHOOL_WORKBOOK) {
			gradeSchoolButtonRef.current.className = "topic-category-button selected";
			degreesButtonRef.current.className = "topic-category-button";
			jobsButtonRef.current.className = "topic-category-button";
		}
		else if (category === DEGREES_WORKBOOK) {
			degreesButtonRef.current.className = "topic-category-button selected";
			gradeSchoolButtonRef.current.className = "topic-category-button";
			jobsButtonRef.current.className = "topic-category-button";
		}
		else if (category === JOBS_WORKBOOK) {
			jobsButtonRef.current.className = "topic-category-button selected";
			gradeSchoolButtonRef.current.className = "topic-category-button";
			degreesButtonRef.current.className = "topic-category-button";
		}
	}

	return (
		<Fragment>
			{signUpModal}
			{loginModal}

			<div className={props.componentType && props.componentType === "modal" ? "topic-page-container" : "topic-page-container page"}
					onClick={closeDropdown} onKeyPress={closeDropdown}>
				{alert}

				<Helmet>
					<title>{messageMap("topics.title", "headerTag")}</title>
					<meta name="description" content={messageMap("topics.description", "headerTag")}></meta>
				</Helmet>

				<div className={props.componentType && props.componentType === "modal" ? "topic-title" : "topic-title page"}>
					{
						props.title
						? props.title
						: messageMap("practicePage.pageTitle", "generic")
					}
				</div>
				{
					props.componentType !== "modal"
					? (
							<div className="topic-subheader">
								{
									props.subHeader
									? props.subHeader
									: messageMap("practicePage.subHeader", "generic")
								}
							</div>
						)
					: ""
				}

				<div className="search-block-container">
					<Search searchCategory={currentExcelWorkbookRef.current} searchType="topic"
									onlyAvailableResource={props.componentType && props.componentType === "modal" ? false : true}
									chosenResultClickHandler={getChosenTopic}
									closeHandlerPointerReference={closeHandlerPointerReferenceRef}
									clearSetInputPointerReference={clearSetInputPointerReferenceRef}/>

					<div className="topic-categories-container">
						<button className="topic-category-button" ref={gradeSchoolButtonRef}
										onClick={e => generateTopicItems(GRADE_SCHOOL_WORKBOOK)}
										onKeyPress={e => generateTopicItems(GRADE_SCHOOL_WORKBOOK)}>
							{messageMap("uploadVideoPage.grades", "button")}
						</button>
						<button className="topic-category-button" ref={degreesButtonRef}
										onClick={e => generateTopicItems(DEGREES_WORKBOOK)}
										onKeyPress={e => generateTopicItems(DEGREES_WORKBOOK)}>
							{messageMap("uploadVideoPage.degrees", "button")}
						</button>
						<button className="topic-category-button" ref={jobsButtonRef}
										onClick={e => generateTopicItems(JOBS_WORKBOOK)}
										onKeyPress={e => generateTopicItems(JOBS_WORKBOOK)}>
							{messageMap("uploadVideoPage.jobs", "button")}
						</button>
					</div>
				</div>

				<div className={props.componentType && props.componentType === "modal" ? "topic-items-container" : "topic-items-container page"}>
					{topicItems}
				</div>
			</div>
		</Fragment>
	);
}

Topic.propTypes = {
	componentType: PropTypes.string,
	title: PropTypes.string,
	subHeader: PropTypes.string,
	closeHandler: PropTypes.func,

	// Redux prop values
	ownerId: PropTypes.string,
	jwt: PropTypes.string
};

export default connect(
	accountProgressSelector,
	null
)(Topic);
