import React from 'react';

import messageMap from "Utilities/MessageMaps";

import comingSoonAsset from "assets/images/tbd/coming_soon.jpg";

function ContactUs() {
	return (
		<div className="contactUs">
			<h1>
				{messageMap("company.contact.title", "generic")}
			</h1>
			<img src={comingSoonAsset} alt={messageMap("tbd", "image")}></img>
		</div>
	);
}

export default ContactUs;