import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from "prop-types";

import messageMap from "Utilities/MessageMaps";
import { getSearchSuggestions } from "pages/KnowledgeTree/KnowledgeTreeUtilities";
import {
	getSubtreeFromId,
	KNOWLEDGE_NETWORK_IDS_TRIE_MAP, AUGMENTED_KNOWLEDGE_NETWORK_IDS_TRIE_MAP
} from "diagrams/utilities/NetworkGenerator";
import { GRADE_SCHOOL_WORKBOOK, DEGREES_WORKBOOK, JOBS_WORKBOOK } from "diagrams/utilities/NetworkConstants";
import { createTokenizedPrefixTreeWithReferencing } from "Utilities/Algos";

import searchAsset from "assets/icons/home/search.svg";


/**
 * @param {String} searchCategory? // the workbook category from which to conduct the search on. Null if this is being used for video search
 * @param {String} searchType // whether search is for "topics", "knowledge", or "videos"
 * @param {Function} chosenResultClickHandler // custom handler when user clicks on search result
 * @param {Function} clearSearchHandler? // custom handler when user clears Search's input.
 * @param {Function} resultsGroupEnterHandler? // when user presses "Enter", or clicks on the search icon, pass current list of results to handler
 * @param {Object} closeHandlerPointerReference // coupling reference to allow parent component to close Search's dropdown DOM
 * @param {Object} clearSetInputPointerReference // coupling reference to allow parent component to clear and update Search's input
 * @param {Boolean} nestedSearch? // option to allow nested search from previous search results
 * @param {String} inputPlaceholder? // optional placeholder for search input
 * @param {Object} customSearchMap? // custom search map to replace the default idToObjectMap in KnowledgeTreeUtilities's getSearchSuggestions()
 * @param {Boolean} onlyAvailableResource? // whether to show results if there's an available video, article, or practice question
 */
function Search(props) {
	const [suggestionsDropdown, setSuggestionsDropdown] = useState([]),
		[subList1, setSubList1] = useState([]),
		[subList2, setSubList2] = useState([]),
		[subList3, setSubList3] = useState([]),
		[subList4, setSubList4] = useState([]);

	// DOM ref
	const inputSearchRef = useRef(),
		searchSuggestionsDivRef = useRef(),
		searchSuggestionsRef = useRef(),
		subListDropdown1Ref = useRef(),
		subListDropdown2Ref = useRef(),
		subListDropdown3Ref = useRef(),
		subListDropdown4Ref = useRef(),
		// value Ref
		videoSearchTrieRef = useRef({}),
		searchKeysResultsRef = useRef();

	const inputPlaceholder = props.inputPlaceholder ? props.inputPlaceholder : messageMap("video.placeholder.searchTopic", "generic");
	const allowNestedSearch = props.nestedSearch ? props.nestedSearch : false;
	const searchType = props.searchType;


	useEffect(() => {
		if (searchType === "videos") {
			let titles = [];

			for (const [key, value] of Object.entries(props.customSearchMap)) {
				titles.push(key.split("_")[1]);
			}

			createTokenizedPrefixTreeWithReferencing(titles, videoSearchTrieRef.current, false);
		}

		createClearSetInputPointerReference();
		createCloseHandlerPointerReference();
	}, []);


	function createClearSetInputPointerReference() {
		props.clearSetInputPointerReference.current = () => {
			inputSearchRef.current.value = "";
		};

		props.clearSetInputPointerReference.current = {
			clear: () => {
				inputSearchRef.current.value = "";
			},
			set: inputValue => {
				inputSearchRef.current.value = inputValue;
			}
		};
	}

	function createCloseHandlerPointerReference() {
		props.closeHandlerPointerReference.current = () => {
			searchSuggestionsDivRef.current.className = `search-suggestions-container ${searchType} hide`;
			searchSuggestionsRef.current.className = `search-suggestions-child ${searchType} hide`;
			subListDropdown1Ref.current.className = `search-suggestions-child ${searchType} hide`;
			subListDropdown2Ref.current.className = `search-suggestions-child ${searchType} hide`;
			subListDropdown3Ref.current.className = `search-suggestions-child ${searchType} hide`;
			subListDropdown4Ref.current.className = `search-suggestions-child ${searchType} hide`;
		};
	}

	function showSearchSuggestions() {
		searchSuggestionsDivRef.current.className = `search-suggestions-container ${searchType}`;
		searchSuggestionsRef.current.className = `search-suggestions-child ${searchType}`;
		subListDropdown1Ref.current.className = subList1.length ? `search-suggestions-child ${searchType}` : `search-suggestions-child ${searchType} hide`;
		subListDropdown2Ref.current.className = subList2.length ? `search-suggestions-child ${searchType}` : `search-suggestions-child ${searchType} hide`;
		subListDropdown3Ref.current.className = subList3.length ? `search-suggestions-child ${searchType}` : `search-suggestions-child ${searchType} hide`;
		subListDropdown4Ref.current.className = subList4.length ? `search-suggestions-child ${searchType}` : `search-suggestions-child ${searchType} hide`;
	}

	function searchForItem(event) {
		const searchTarget = event.target;
		const searchString = searchTarget.value;
		const searchTerm = searchString ? searchString : inputSearchRef.current.value;

		if ((searchTarget !== null && searchTarget.tagName.includes("IMG", "INPUT"))
			|| searchTerm == null || searchTerm === "") {
			if (searchType !== "videos") {
				props.closeHandlerPointerReference.current();
			}
			else {
				if (searchTarget.tagName === "INPUT") {
					searchKeysResultsRef.current = 0;
				}
				if (searchTerm === "") {
					// Clear Search Handling specific to videos.
					props.clearSearchHandler && props.clearSearchHandler();
				}
				submitGroupResults();
			}
		}
		else {
			let defaultTrie = KNOWLEDGE_NETWORK_IDS_TRIE_MAP[props.searchCategory];
			let charSearchTrie = AUGMENTED_KNOWLEDGE_NETWORK_IDS_TRIE_MAP[props.searchCategory];
			let idToObjectMap;
			if (searchType === "videos") {
				defaultTrie = videoSearchTrieRef.current;
				charSearchTrie = videoSearchTrieRef.current;
				idToObjectMap = {};

				for (const [key, value] of Object.entries(props.customSearchMap)) {
					const splitKey = key.split("_");
					const splitValue = value.split("_");
					idToObjectMap[splitKey[1]] = {
						id: splitKey[0],
						title: splitKey[1],
						itemType: splitValue[1]
					}
				}
			}

			const searchTokens = searchTerm.split(" ");
			let masterSearchList = {};
			let searchIdList = [];
			for (let i = 0; i < searchTokens.length; ++i) {
				if (searchTokens[i].length) {
					const searchResults = getSearchSuggestions(searchTokens[i], props.searchCategory, props.chosenResultClickHandler,
						showNestedChildren, defaultTrie, charSearchTrie, idToObjectMap,
						searchType, props.onlyAvailableResource);
					const strToResultItemMap = searchResults[0];
					const strToNodeItemMap = searchResults[1];
					const strToResultItemMapKeys = Object.keys(strToResultItemMap);
					if (i) {
						if (strToResultItemMapKeys.length) {
							let newMasterSearchList = {};
							let newSearchIdList = [];
							for (let j = 0; j < strToResultItemMapKeys.length; ++j) {
								const curEl = strToResultItemMapKeys[j];
								if (masterSearchList[curEl]) {
									newMasterSearchList[curEl] = strToResultItemMap[curEl];
									newSearchIdList.push(strToNodeItemMap[curEl]);
								}
							}
							masterSearchList = newMasterSearchList;
							searchIdList = newSearchIdList;
						}
						else {
							masterSearchList = {};
							searchIdList = [];
						}
					}
					else {
						for (let j = 0; j < strToResultItemMapKeys.length; ++j) {
							const curEl = strToResultItemMapKeys[j];
							if (masterSearchList[curEl] == null) {
								masterSearchList[curEl] = strToResultItemMap[curEl];
								searchIdList.push(strToNodeItemMap[curEl]);
							}
						}
					}
				}
			}

			searchKeysResultsRef.current = searchIdList;
			setSuggestionsDropdown(
				<Fragment>
					{
						Object.values(masterSearchList)
					}
				</Fragment>
			);
			showSearchSuggestions();
		}
	}

	function submitGroupResults() {
		props.resultsGroupEnterHandler(searchKeysResultsRef.current);
	}

	function showNestedChildren(event, parentNodeId, nestingCount) {
		if (allowNestedSearch) {
			const dropdownNestingRefMap = {
				"1": subListDropdown1Ref,
				"2": subListDropdown2Ref,
				"3": subListDropdown3Ref,
				"4": subListDropdown4Ref
			};
			const subListStateMap = {
				"1": setSubList1,
				"2": setSubList2,
				"3": setSubList3,
				"4": setSubList4
			};

			parentNodeId = parentNodeId.replace("\n", "");
			const subtree = getSubtreeFromId(parentNodeId, -1, props.searchCategory)[0];
			const subtreeChildren = Object.keys(subtree[parentNodeId].children);

			let newDropdownItems = [];
			let incrementedNestingCount = String(Number(nestingCount) + 1);

			if (incrementedNestingCount > 5) {
				incrementedNestingCount = "4";
			}

			if (subtreeChildren.length) {
				for (const child of subtreeChildren) {
					let displayStr = child;

					if ([GRADE_SCHOOL_WORKBOOK, JOBS_WORKBOOK].includes(props.searchCategory)) {
						const splitLabels = child.split("_");
						displayStr = splitLabels[splitLabels.length - 1];
					}

					newDropdownItems.push(
						<div key={child} className="dropdown-item-container"
							tabIndex="0"
							onClick={e => props.chosenResultClickHandler(child)} onKeyPress={e => props.chosenResultClickHandler(child)}
							onMouseEnter={e => showNestedChildren(e, child, incrementedNestingCount)}
						>
							<p className="dropdown-item">
								{displayStr}
							</p>
						</div>
					);
				}

				if (nestingCount < 5) {
					dropdownNestingRefMap[nestingCount].current.className = `search-suggestions-child ${searchType}`;
					subListStateMap[nestingCount](
						<Fragment>
							{newDropdownItems}
						</Fragment>
					);
				}
			}
			else {
				for (let nestLevel = nestingCount; nestLevel < 5; ++nestLevel) {
					dropdownNestingRefMap[nestLevel].current.className = `search-suggestions-child ${searchType} hide`;
				}
			}
		}
	}

	return (
		<div className={`search-container ${searchType}`}>
			<div className={`search-input-container ${searchType}`}>
				<img className={`search-btn ${searchType}`} src={searchAsset} alt={messageMap("search", "image")}
					tabIndex="0" role="button"
					onClick={searchForItem} onKeyPress={searchForItem}></img>
				<input type="search" className={`search-input ${searchType}`} placeholder={inputPlaceholder}
					ref={inputSearchRef}
					onChange={searchForItem}
					onKeyDown={e => { e.key === "Enter" && submitGroupResults() }}></input>
			</div>
			<div className={`search-suggestions-container ${searchType} hide`} ref={searchSuggestionsDivRef}>
				<div className={`search-suggestions-child ${searchType}`} ref={searchSuggestionsRef}>
					{suggestionsDropdown}
				</div>
				<div className={`search-suggestions-child ${searchType}`} ref={subListDropdown1Ref}>
					{subList1}
				</div>
				<div className={`search-suggestions-child ${searchType}`} ref={subListDropdown2Ref}>
					{subList2}
				</div>
				<div className={`search-suggestions-child ${searchType}`} ref={subListDropdown3Ref}>
					{subList3}
				</div>
				<div className={`search-suggestions-child ${searchType}`} ref={subListDropdown4Ref}>
					{subList4}
				</div>
			</div>
		</div>
	);
}

Search.defaultProps = {
	onlyAvailableResource: false
};

Search.propTypes = {
	onlyAvailableResource: PropTypes.bool,
	searchCategory: PropTypes.string,
	searchType: PropTypes.string.isRequired,
	chosenResultClickHandler: PropTypes.func.isRequired,
	resultsGroupEnterHandler: PropTypes.func,
	clearSearchHandler: PropTypes.func,

	closeHandlerPointerReference: PropTypes.object.isRequired,
	clearSetInputPointerReference: PropTypes.object.isRequired,

	nestedSearch: PropTypes.bool,
	inputPlaceholder: PropTypes.string,
	customSearchMap: PropTypes.object
};

export default Search;