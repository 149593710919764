// Used for notifications message and their full text
export const notificationMessageMap = {
	notification: {
		title: "Notifications",
		new: "New",
		old: "Old",
		learnMore: "learn more"
	},
	promo: {
		educator: {
			incentives: {
				adsCommSubMsg: "Limited time bonus!",
				adsCommSubMsgFull: {
					title: "Limited Time Bonus!",
					subHeader: `Upload an educational video now to qualify for the following bonus:`,
					body: [
						"1. Receive 80% of proceeds on all videos that you own for an entire year!",
						"2. $5 Amazon gift card",
						`Please email us the video's title you uploaded, including your username and email, to support@exerinstitute.com
						to receive these bonuses. Once confirmed, your video will receive the 80% bonus for a whole year. You can upload
						multiple videos and all of them could qualify for the bonus. The year countdown begins once the commissions system 
						is deployed (we are targeting for the second week of February). You'll also receive a notification email from us 
						once it's deployed.`
					],
					footer: `Since we can only accommodate up to 20 videos per month, any additional videos uploaded on the same month 
									will take priority on the next month. Additionally, your video would not qualify for the bonus if it
									violates our terms of service.`
				}
			}
		}
	}
};