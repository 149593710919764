/**
 * @description checks for the following
 * 1) if model contains all the required keys
 * 2) if all keys in model are of the specified type in template
 * 3) if model contains more keys than the template
 * @param {Object} template pre-defined model
 * @param {Object} model model to test if it satisfies template
 * @returns {Boolean} if the model satisfies pre-defined template
 */
 export function modelValidator(template, model) {
	let isValid = true;
	const invalidNMissingKeysCount = DFSValidator(template, model),
				invalidKeys = invalidNMissingKeysCount.invalidKeys,
				missingKeys = invalidNMissingKeysCount.missingKeys,
				invalidKeysCount = invalidKeys.length,
				missingKeysCount = missingKeys.length;

	if (invalidKeysCount && missingKeysCount) {
		console.error(`Invalid keys: ${invalidKeys} and missing keys: ${missingKeys}`);
		isValid = false;
	}
	else if (invalidKeysCount) {
		console.error(`Invalid keys: ${invalidKeys}`);
		isValid = false;
	}
	else if (missingKeysCount) {
		console.error(`Missing keys: ${missingKeys}`);
		isValid = false;
	}

	return isValid;
}

/**
 * @description does a breadth-first-search for validating template to model key types
 * @param {Object} template pre-defined model
 * @param {Object} model model to test if it satisfies template
 */
function DFSValidator(template, model) {
	const templateKeys = Object.keys(template),
				modelKeys = Object.keys(model);
	let invalidNMissingKeysCount = {
		invalidKeys: [],
		missingKeys: []
	};

	// check if template with key has more than one children, where the 1 child is the 'type' key
	for (var i = 0; i < templateKeys.length; ++i) {
		const currentTemplateKey = templateKeys[i];

		if (Object.keys(template[currentTemplateKey]).length > 1) {
			let temTemplate = template[currentTemplateKey];
			delete temTemplate.type;

			let localInvalidMissingKeysCount = DFSValidator(temTemplate, model[currentTemplateKey]);
			invalidNMissingKeysCount.invalidKeys = invalidNMissingKeysCount.invalidKeys.concat(localInvalidMissingKeysCount.invalidKeys);
			invalidNMissingKeysCount.missingKeys = invalidNMissingKeysCount.missingKeys.concat(localInvalidMissingKeysCount.missingKeys);
		}
		else {
			const modelKeyIndex = modelKeys.indexOf(currentTemplateKey);

			if (!modelKeys.includes(currentTemplateKey) 
					&& (template[currentTemplateKey].required === undefined		// fields are true by default in template
							|| template[currentTemplateKey].required === true) ) { // fields can be explicity stated to be required
				invalidNMissingKeysCount.missingKeys.push(currentTemplateKey);
			}
			if (modelKeys[modelKeyIndex] && typeof model[currentTemplateKey] !== template[currentTemplateKey].type) {
				invalidNMissingKeysCount.invalidKeys.push(currentTemplateKey);
			}
		}
	}

	return invalidNMissingKeysCount;
}

/**
 * @description recursively checks if the response received from the BE matches the expected viewObjectModel
 * @param {*} response 
 * @param {*} viewObjectModel 
 */
// TODO: implement this validator
export function viewObjectValidator(response, viewObjectModel) {

}