import {IP_VO} from "apis/models/IPs";

import request, {GET, POST} from "Utilities/Fetches";
import {$replace} from "Utilities/Strings";
import {modelValidator} from "Utilities/Validators/APIValidators";

const crypto = require("crypto");

const BASE = "/services/session";

const UNBLOCK = BASE + "/unblock/ip";
const BLOCK_IP_POST = BASE + "/block/ip";
const BLOCK_IP_GET = BASE + "/block/ip?ip=${ip}";


/**
 * @param {Object} payload = {
 * 	ip: String
 * }
 */
export function unblockIpAPI(payload) {
	payload.ip = crypto.createHash("sha512").update(payload.ip).digest("hex");
	// modelValidator(IP_VO, payload) &&
	request(UNBLOCK, POST, payload);
}

/**
 * @param {Object} payload  = {
 * 	ip: String
 * }
 */
export function blockIpPostAPI(payload) {
	payload.ip = crypto.createHash("sha512").update(payload.ip).digest("hex");
	// modelValidator(IP_VO, payload) &&
	request(BLOCK_IP_POST, POST, payload);
}

/**
 * @param {Object} pathVariables = {
 * 	ip: String
 * }
 */
export function blockIpGetAPI(pathVariables, callback) {
	pathVariables.ip = crypto.createHash("sha512").update(pathVariables.ip).digest("hex");
	const api = $replace(BLOCK_IP_GET, pathVariables);
	request(api, GET, null, callback);
}


export async function getIP(callback) {
	await fetch("https://api.ipify.org?format=json")
		.then(response => {
			return response.json();
		})
		.then(data => {
			callback(data.ip);
	});
}