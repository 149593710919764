import React, { Fragment } from 'react';
import PropTypes from "prop-types";

import Tooltip from "templates/Tooltip";
import Alert from "templates/Alert";

import {emailValidator, passwordValidator, userNameValidator} from "Utilities/Validators/InputValidators";
import {checkHasExplicitWords} from "Utilities/Validators/ContentValidator.js";
import {binarySearch} from "Utilities/Algos";
import messageMap from "Utilities/MessageMaps";

import hidePassAsset from "assets/icons/navigation/hide_pass.svg";
import revealPassAsset from "assets/icons/navigation/reveal_pass.svg";
import closeAsset from "assets/icons/common/close.svg";
import checkAsset from "assets/icons/alerts/check.svg";
import warningAsset from "assets/icons/alerts/warning.svg";
import cancelAsset from "assets/icons/alerts/cancel.svg";
import informationAsset from "assets/icons/alerts/information.svg";

/**
 * @description has default behavior for modals passed with password and email inputs
 * @param {Object} modalStyle? custom modal styling
 * @param {String} modalClass? custom modal class
 * @param {Object} modalContainerStyle? custom modal-container styling
 * @param {String} title? header for the modal
 * @param {Object} titleStyle? customized styling for title
 * @param {String} subHeader? any additional description
 * @param {Object} subHeaderStyle? customized styling for subHeader
 * @param {Node} errorMsg? customized error message for input validation
 * @param {Array} stateProps? array of objects = {
 * 	state: string, // state is the name of the state
 * 	defValue: string // defValue is the default state value
 * }
 * @param {Array} inputs? array of objects = {
 * 	label?: string, // label for the input
 * 	value: string, // will be used as a state key, so this needs to be the same as one of the states in stateProps
 * 	type: string, // input type {email and password type are automatically creates a ref}
 * 	icon?: string, // input icon
 * 	onChangeValidationRef?: string, // name of the ref to change when validation returns true or false
 * 	alt?: string, // icon's definition for accessibility purposes
 * 	containerStyle?: { // styling for the container holding label and input
 * 		marginTop: "" // for example
 * },
 * 	inputStyle?: {	// styling for individual inputs
 * 		width: "" // for example
 * 	},
 * 	tooltip?: {
 * 		classStr?: string, // default is provided, refer to tooltipMixins for options
 * 		subheader?: string,
 * 		type?: string, // different behavior per type: ["password"]
 * 		list?: array of objects {
 * 			key: string, // can be used for input validation in modal
 * 			text: string // <li>{text}</li>
 * 		}
 * 	},
 * 	additionalElems?: [
 * 		{
 * 			type: string // dom tag
 * 			props?: {
 * 				onClick?: function, // handler when elem gets clicked
 * 				style?: object, // custom inline styles
 * 				className?: string, // if you want to apply a common class not specific to the modal you're creating
 * 				ref?: string, // name of the ref to be created for this element
 * 			},
 * 			children?: array // array of children. Assumes dom elements
 * 			handler?: function // if dom tag includes eventHandler
 * 			`${eventType}Args`?: ...Strings // string args for handler. Assumes, non-event args are states
 * 		}
 * 	]
 * 	onEnter?: boolean // if true trigger submissionHandler when user presses "Enter" key
 * }
 * @param {Array} inputsToValidate? array of strings = [
 * 	inputKey: string // string elements corresponding to state values in stateProps
 * ]
 * @param {Object} textArea? object of textarea attribute values = {
 * 	rows: number // number of rows
 * 	columns: number // number of columns
 * 	value?: string/number // default value of textarea
 * }
 * @param {Object} textAreaStyle? customized styling for textArea
 * @param {Object} icon? = {
 * 	type: string // {"check", "warning", "cancel", "information"}
 * 	tooltip?: {
 * 		subheader?: string // subheader text
 * 		containerStyle?: object // CSS styles
 * 		tooltipStyle?: object // CSS styles
 * 	}
 * }
 * @param {Object} children? any DOM props children
 * @param {Array} thumbnailList? array of objects = {
 * 	progress: number // indicates user's current progress in completing a class
 * 	current: boolean // indicates if user is currently viewing this class
 * 	src: string // image source of the thumbnail
 * 	alt: string // image's alternate description
 * 	title: string // thumbnail's title
 * 	subtitle: string // thumbnail's subtitle
 * }
 * @param {Boolean} disableForms? disable all inputs and buttons, except cancel button
 * @param {String} disabledMsg? message explaining why inputs and buttons are disabled\
 * @param {Function} closeHandler function handler for closing the modal. If null, there'll be no close buttons
 * @param {Object} closeArgs? additional arguments for closeHandler, in addition to the default target object
 * @param {String} closeType? {"closeButton"/"xButton"} default is closeButton
 * @param {Object} closeButtonStyle? custom style of "xButton"
 * @param {Function} submitHandler? function handler for any submission (this should be extended to handle multiple action buttons)
 * @param {Object} submitHandlerStyle? custom style for submit button
 * @param {String} buttonAltText? customized aria-label text for submit button
 * @param {String} submitText? text for submit button
 * @param {Array} customButtons? array of customButtons
 * @param {Node} footer? can be used for any disclaimer texts
 * @param {Object} footerStyle? customized styling for footer
 */
export default class Modal extends React.Component {
	constructor(props) {
		super(props);

		// dynamically create states from props
		if (this.props.stateProps) {
			let tempState = {
				"alert": null
			};
			this.props.stateProps.forEach(obj => {
				tempState[obj.state] = obj.defValue ? obj.defValue : "";
			});

			if (this.props.errorMsg) {
				tempState["errorMsg"] = "";
			}

			this.state = tempState;
		}

		this.closeAlert = this.closeAlert.bind(this);
		this.usernameVerifier = this.usernameVerifier.bind(this);
		this.changeInput = this.changeInput.bind(this);
		this.makeInputs = this.makeInputs.bind(this);
		this.createElements = this.createElements.bind(this);
		this.dynamicallyCreateHandlers = this.dynamicallyCreateHandlers.bind(this);
		this.makeThumbnailList = this.makeThumbnailList.bind(this);
		this.toggleText = this.toggleText.bind(this);
		this.validateSubmission = this.validateSubmission.bind(this);
		this.statusHandler = this.statusHandler.bind(this);
		this.inputDisabler = this.inputDisabler.bind(this);

		// dynamically create refs if list of inputs includes a password input type; this is for toggling the password display text
		const inputs = this.props.inputs;
		if (inputs) {
			for (var i = 0; i < inputs.length; ++i) {
				// will be used for toggling password text display
				if (["password", "passwordConfirm", "passwordLogin", "generic"].includes(inputs[i].type)) {
					this[inputs[i].value + "Ref"] = React.createRef();
				}
	
				if (["password", "passwordConfirm", "passwordLogin", "email", "username"].includes(inputs[i].type)) {
					// will be used for input validation
					this[inputs[i].value + "ContainerRef"] = React.createRef();
				}
			}
		}

		this.modalRef = React.createRef();
		this.formRef = React.createRef();

		this.iconMap = {
			success: {
				svg: checkAsset,
				alt: messageMap("alerts.check", "image"),
				msg: "Success:"
			},
			warning: {
				svg: warningAsset,
				alt: messageMap("alerts.warning", "image"),
				msg: "Warning:"
			},
			error: {
				svg: cancelAsset,
				alt: messageMap("alerts.cancel", "image"),
				msg: "Error:"
			},
			information: {
				svg: informationAsset,
				alt: messageMap("alerts.information", "image"),
				msg: "Info:"
			}
		};
	}

	showToolTip(tooltipRef, innerHTML, ariaReference) {
		// I wasn't able to make forwardRef work, so this is my workaround
		if (this.state[tooltipRef] === "") {
			this.setState({
				[tooltipRef]: (
					<Tooltip verticalAlign={true} classStr={innerHTML.classStr}
										subheader={innerHTML.subheader} type={innerHTML.type}
										list={innerHTML.list} ariaReference={ariaReference}/>
				)
			});
		}
	}

	validateSubmission(e) {
		if (this.props.inputsToValidate) {
			let inputsToValidate = this.props.inputsToValidate,
					invalidIndex = -1;

			const stateObj = {
					keys: Object.keys(this.state),
					values: Object.values(this.state)
				},
				stateValues = stateObj.keys;

			// find match and update inputsToValidate
			for (var i = 0; i < inputsToValidate.length; ++i) {
				let match = binarySearch(stateObj, 0, stateValues.length, inputsToValidate[i].state);

				if (match) {
					inputsToValidate[i].defValue = match;
					if (!match.isValid) {
						invalidIndex = i;
						break;
					}
				}
				else {
					console.error("Didn't find input type: ", inputsToValidate[i], " in this.state: ", this.state);
				}
			}

			if (invalidIndex === -1) {
				this.props.submitHandler(this.state, this.statusHandler, this.inputDisabler);
			}
			else {
				const stateStr = inputsToValidate[invalidIndex].state;
				this[stateStr + "ContainerRef"].current.classList.contains("input-container-warning") ?
				this[stateStr + "ContainerRef"].current.classList.replace("input-container-warning", "input-container-error") :
				this[stateStr + "ContainerRef"].current.classList.add("input-container-error");

				this.setState({
					errorMsg: this.props.errorMsg
				});
			}
		}
		else  {
			this.props.submitHandler(this.state, this.statusHandler, this.inputDisabler);
		}
	}

	statusHandler(statusMsg) {
		this.setState({
			errorMsg: statusMsg ? statusMsg : this.props.errorMsg ? this.props.errorMsg : ""
		});
	}

	inputDisabler(disable) {
		if (this.formRef.current.classList) {
			if (disable === undefined || disable === null) {
				this.formRef.current.classList.add("block");
			}
			else {
				this.formRef.current.classList.remove("block");
			}
		}
	}

	hideToolTip(tooltipRef) {
		if (this.state[tooltipRef]) {
			this.setState({
				[tooltipRef]: ""
			});
		}
	}

	closeAlert() {
		this.setState({
			alert: null
		});
	}

	usernameVerifier(inputVal, target) {
		if (checkHasExplicitWords(inputVal)) {
			target.value = "";
			const alertContainerStyle = {
				"position": "absolute"
			};
			this.setState({
				alert: <Alert type="error" closeHandler={this.closeAlert}
											msg={messageMap("input.explicit.text", "validation")}
											alertContainerStyle={alertContainerStyle}></Alert>
			});

			return;
		}

		return userNameValidator(inputVal);
	}

	changeInput(event, stateType, optional, onChangeValidationRef, ariaReference) {
		const target = event.target,
					staticValue = target.attributes.statictype ? target.attributes.statictype.value : "",
					validatorMap = {
						password: passwordValidator,
						email: emailValidator,
						username: this.usernameVerifier,
						passwordConfirm: (str1, str2) => {return str1 === str2}
					};
		let validationObject = {},
				validator = validatorMap[staticValue];

		let isValid = validator && (staticValue !== "passwordConfirm" ? validator(target.value, target) : validator(target.value, this.state.signUpPass.value));

		if (this[stateType + "ContainerRef"] && validator) {
			if (typeof isValid == "object") {
				// TODO: check if this is a bug
				validationObject = isValid;
				isValid = isValid.passed;
			}

			if (!isValid && !this[stateType + "ContainerRef"].current.classList.contains("input-container-warning")) {
				this[stateType + "ContainerRef"].current.classList.add("input-container-warning");
			}
			else if (isValid) {
				this[stateType + "ContainerRef"].current.classList.remove("input-container-warning");
				this[stateType + "ContainerRef"].current.classList.remove("input-container-error");
			}
		}

		if (["password", "username"].includes(staticValue)) {
			if (!!onChangeValidationRef) {
				if (staticValue === "username" && isValid) {
					this[onChangeValidationRef].current.classList.remove("not-allowed");
				}
				else {
					this[onChangeValidationRef].current.classList.add("not-allowed");
				}
			}

			this.setState({
				[stateType + "Tooltip"]: <Tooltip ariaReference={ariaReference} verticalAlign={true} classStr={optional.classStr} subheader={optional.subheader} type={optional.type} list={optional.list} passValidProgress={validationObject}/>,
				[stateType]: {
					value: target.value,
					isValid: isValid
				}
			});
		}
		else {
			this.setState({
				[stateType]: {
					value: target.value,
					isValid: isValid
				}
			});
		}
	}

	toggleText(nodeRef, event) {
		if (this[nodeRef + "Ref"] !== null && this[nodeRef + "Ref"] !== undefined) {
			if (["password", "passwordConfirm", "passwordLogin"].includes(this[nodeRef + "Ref"].current.type)) {
				this[nodeRef + "Ref"].current.type = "text";
				event.target.src = revealPassAsset;
			}
			else {
				this[nodeRef + "Ref"].current.type = "password";
				event.target.src = hidePassAsset;
			}
		}
	}

	makeInputs(inputsArr) {
		let arrayLabels = [];
	
		Array.isArray(inputsArr) && inputsArr.forEach(inputEl => {
			const required = inputEl.required ? <span className="required">*</span> : "",
						img = inputEl.icon
									?
									<img className="icon-image" src={inputEl.icon} alt={inputEl.alt}></img>
									:
									"",
						inputClass = inputEl.icon ? "password-input" : "",
						containerStyles = inputEl.containerStyle,
						inputStyles = inputEl.inputStyle,
						additionalElems = inputEl.additionalElems ? this.createElements(inputEl.additionalElems) : "";

			let passwordIcon = ["password", "passwordConfirm", "passwordLogin"].includes(inputEl.type) ?
				<img className="password-icon" src={hidePassAsset} alt={inputEl.alt} 
							onClick={(e) => this.toggleText(inputEl.value, e)} onKeyPress={(e) => this.toggleText(inputEl.value, e)}
							role="button" tabIndex={0}></img>
				: "";

			arrayLabels.push(
				<div key={inputEl.label ? inputEl.label : inputEl.value} className="input-container"
							style={containerStyles} ref={this[inputEl.value + "ContainerRef"]}>
					{
						inputEl.label
						?
						<label className="label" htmlFor={inputEl.label} aria-label={inputEl.label}>{inputEl.label}</label>
						: ""
					}
					{required}
					<div className="input-sub-container">
						{img}
						<input className={inputClass} style={inputStyles} ref={this[inputEl.value + "Ref"]} 
										type={inputEl.type && inputEl.type.includes("password") ? "password" : ""} statictype={inputEl.type ? inputEl.type : ""} 
										id={inputEl.label ? inputEl.label : inputEl.value} aria-required={inputEl.required} 
										maxLength={inputEl.maxLength ? inputEl.maxLength : ""}
										onChange={(e) => this.changeInput(e, inputEl.value, inputEl.tooltip, inputEl.onChangeValidationRef, `${inputEl.type}Tooltip`)}
										aria-describedby={`${inputEl.type}Tooltip`}
										onFocus={() => this.showToolTip(inputEl.value + "Tooltip", inputEl.tooltip, `${inputEl.type}Tooltip`)}
										onBlur={() => this.hideToolTip(inputEl.value + "Tooltip")}
										onKeyPress={(e) => {
											inputEl.onEnter && e.key === "Enter" && this.props.submitHandler && this.validateSubmission();
										}}>
						</input>
						{this.state[inputEl.value + "Tooltip"]}
						{passwordIcon}
						<div>
							{additionalElems[0]}
						</div>
					</div>
				</div>
			)
		});
		return arrayLabels;
	}

	createElements(elemsArr) {
		let elements = [];

		elemsArr.forEach(e => {
			let props = {},
					children = e.children && Array.isArray(e.children) ? e.children : null;
			if (e.props && typeof e.props === "object") {
				for (const [key, value] of Object.entries(e.props)) {
					// look for on event handlers
					if (key.includes("on")) {
						props[key] = this.dynamicallyCreateHandlers(value, e[`${key}Args`]);
					}
					else if (key === "ref") {
						this[value] = React.createRef();
						props[key] = this[value];
					}
					else {
						props[key] = value;
					}
				}
			}

			elements.push(
				React.createElement(e.type, props, children)
			);
		});

		return elements;
	}

	dynamicallyCreateHandlers(fn, keyArgs) {
		let stateArgs = [];

		keyArgs.forEach(e => {
			if (e !== "event") {
				stateArgs.push(this.state[e]);
			}
		});

		if (keyArgs.includes("event")) {
			return ((e) => fn(e, ...stateArgs));
		}
		else {
			return (() => fn(...stateArgs));
		}
	}

	makeThumbnailList(thumbnailArr) {
		let thumbnailList = [];

		Array.isArray(thumbnailArr) && thumbnailArr.forEach(thumbnail => {
			const progressStyle = {
				"width": thumbnail.progress + "px"
			};
			const currentClass = thumbnail.current ? "current" : "";
			thumbnailList.push(
				<div className={"thumbnail-item " + currentClass}>
					<div>
						<img src={thumbnail.src} alt={thumbnail.alt}></img>
						<div className="progress-bar">
							<div className="progress" style={progressStyle}></div>
						</div>
					</div>
					<div className="thumbnail-desc">
						<div className="thumbnail-title">{thumbnail.title}</div>
						<div className="thumbnail-subtitle">{thumbnail.subtitle}</div>
					</div>
				</div>
			);
		});

		return thumbnailList;
	}

	render() {
		const formClasses = this.props.disableForms ? "form-container block" : "form-container";
		const reAttemptMsg = this.props.disabledMsg ? this.props.disabledMsg : '';
		const hideFormContainer = (this.state && this.state.errorMsg) || this.props.inputs 
															|| this.props.thumbnailList || this.props.footer 
															|| this.props.submitHandler || (this.props.closeType !== "xButton" && this.props.closeType !== "barClose");

		return (
			<div className={`modalClass ${this.props.modalClass}`} style={this.props.modalStyle}
						onClick={e => this.props.closeHandler && this.props.closeHandler(e, this.props.closeArgs)}
						onKeyPress={e => this.props.closeHandler && this.props.closeHandler(e, this.props.closeArgs)}>
					<div className="modal-block">
						{this.state && this.state.alert && this.state.alert}

						<div ref={this.modalRef} className="modal-container" style={this.props.modalContainerStyle}>

							{
								this.props.icon &&
								(
									<Fragment>
										<img className="icon-alert" style={this.props.icon.tooltip.tooltipStyle}
													src={this.iconMap[this.props.icon.type].svg}
													alt={this.iconMap[this.props.icon.type].alt}
													onMouseOver={() => this.setState({ iconTooltipDisplay: "iconTooltipDisplay"})}
													onFocus={() => this.setState({ iconTooltipDisplay: "iconTooltipDisplay"})}
													onMouseOut={() => this.setState({ iconTooltipDisplay: "hide"})}
													onBlur={() => this.setState({ iconTooltipDisplay: "hide"})}
													tabIndex={0} aria-describedby={`modal${this.props.icon.type}Tooltip`}
													></img>
										<Tooltip classStr={`tooltip-bottom-left ${this.state.iconTooltipDisplay}`}
															subheader={this.props.icon.tooltip.subheader}
															containerStyle={this.props.icon.tooltip.containerStyle}
															ariaReference={`modal${this.props.icon.type}Tooltip`}></Tooltip>
									</Fragment>
								)
							}

							{
								this.props.closeType === "xButton" && this.props.closeHandler &&
								<button className="close-button" style={this.props.closeButtonStyle} type="button" 
												onClick={e => this.props.closeHandler(e, this.props.closeArgs)}
												onKeyPress={e => this.props.closeHandler(e, this.props.closeArgs)}>
									<img className="icon" src={closeAsset} alt={messageMap("alerts.close", "image")}></img>
								</button>
							}

							{
								this.props.closeType === "barClose" && this.props.closeHandler &&
								<div className="bar-close">
									<button className="close-button" style={this.props.closeButtonStyle} type="button" 
												onClick={e => this.props.closeHandler(e, this.props.closeArgs)}
												onKeyPress={e => this.props.closeHandler(e, this.props.closeArgs)}>
										<img className="icon" src={closeAsset} alt={messageMap("alerts.close", "image")}></img>
									</button>
								</div>
							}

							<h1 className="title" style={this.props.titleStyle}>{this.props.title}</h1>

							{this.props.subHeader && 
								(
									<h2 className="subheader" style={this.props.subHeaderStyle}>
										{this.props.subHeader}
									</h2>
								)
							}

							{
								this.props.checkboxLabel &&
								(
									<div className="checkbox-container">
										<input type="checkbox" name="modalCheckbox"></input>
										<label htmlFor="modalCheckbox">{this.props.checkboxLabel}</label>
									</div>
								)
							}

							{
								this.props.textArea && 
								<textarea rows={this.props.textArea.rows} cols={this.props.textArea.columns}
													onChange={(e) => this.changeInput(e, this.props.stateProps[0].state)}
													defaultValue={this.props.textArea.value} style={this.props.textAreaStyle}>
								</textarea>
							}

							{this.props.children}

							{
								hideFormContainer
								?
								<div className={formClasses} ref={this.formRef}>
									{this.state && this.state.errorMsg}
									{
										reAttemptMsg && (
											<div className="error-msg w275">
												{reAttemptMsg}
											</div>
										)
									}

									{this.props.inputs && this.makeInputs(this.props.inputs)}

									{
										this.props.thumbnailList &&
										<div className="thumbnail-container">
											{ this.makeThumbnailList(this.props.thumbnailList) }
										</div>
									}

									<div className="footer" style={this.props.footerStyle}>
										{this.props.footer}
									</div>
									<div className="button-container">
										{
											this.props.submitHandler &&
											<button className="submit" aria-label={this.props.buttonAltText ? this.props.buttonAltText : "Submit Button"} 
															style={this.props.submitHandlerStyle}
															onClick={(e) => this.validateSubmission(e)} onKeyPress={(e) => this.validateSubmission(e)}>
												{
													this.props.submitText
													?
													this.props.submitText
													:
													messageMap("submit.text", "button")
												}
											</button>
										}
										{
											this.props.closeType !== "xButton" && this.props.closeHandler &&
											<button className="cancel" aria-label="Close modal button" 
															onClick={e => this.props.closeHandler(e, this.props.closeArgs)}
															onKeyPress={e => this.props.closeHandler(e, this.props.closeArgs)}>
												{messageMap("cancel.text", "button")}
											</button>
										}
										{
											this.props.customButtons
										}
									</div>
								</div>
								: ""
							}
						</div>
					</div>
			</div>
		);
	}
}

Modal.defaultProps = {
	closeType: "closeButton",
	modalClass: ""
};

Modal.propTypes = {
	modalStyle: PropTypes.object,
	modalContainerStyle: PropTypes.object,
	closeButtonStyle: PropTypes.object,
	submitHandlerStyle: PropTypes.object,
	titleStyle: PropTypes.object,
	subHeaderStyle: PropTypes.object,

	disableForms: PropTypes.bool,

	children: PropTypes.object,
	textArea: PropTypes.shape({
		rows: PropTypes.number.isRequired,
		columns: PropTypes.number.isRequired,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		])
	}),
	textAreaStyle: PropTypes.object,
	icon: PropTypes.shape({
		type: PropTypes.string.isRequired,
		tooltip: PropTypes.shape({
			subheader: PropTypes.string,
			containerStyle: PropTypes.object,
			tooltipStyle: PropTypes.object
		})
	}),

	stateProps: PropTypes.arrayOf(PropTypes.object),
	inputsToValidate: PropTypes.arrayOf(PropTypes.object),
	inputs: PropTypes.arrayOf(PropTypes.object),
	customButtons: PropTypes.arrayOf(PropTypes.node),

	errorMsg: PropTypes.node,
	footer: PropTypes.node,
	footerStyle: PropTypes.object,

	title: PropTypes.object,
	subHeader: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]),
	disabledMsg: PropTypes.string,
	buttonAltText: PropTypes.string,
	submitText: PropTypes.string,
	closeType: PropTypes.string,

	submitHandler: PropTypes.func,
	closeHandler: PropTypes.func.isRequired,
	closeArgs: PropTypes.object,

	// TBDs: currently not used by anything
	thumbnailList: PropTypes.array,
	checkboxLabel: PropTypes.string
};