export const SET_OWNER_ID = "SET_OWNER_ID";
export const REMOVE_OWNER_ID = "REMOVE_OWNER_ID";

export const SET_LOG_IN_TIMEOUT_START_TIME = "SET_LOG_IN_TIMEOUT_START_TIME";
export const CLEAR_LOG_IN_TIMEOUT = "CLEAR_LOG_IN_TIMEOUT";
export const LOG_IN_TIMEOUT = 300000;

export const SET_IP = "SET_IP";
export const SET_IP_LOCKED = "SET_IP_LOCKED";
export const SET_IP_CREATED_AT = "SET_IP_CREATED_AT";

export const UPDATE_SHINY_NEURONS_BALANCE = "UPDATE_SHINY_NEURONS_BALANCE";