import React from 'react';
import PropTypes from "prop-types";

/**
 * @description cards like this one: https://getbootstrap.com/docs/4.0/components/card/
 * @param {String} title? card title
 * @param {Object} titleStyle? custom title style
 * @param {String} type? each card type has a pre-defined behavior
 * @param {String} description? subtitle or card description to appear underneath the title
 * @param {Object} descriptionStyle? custom description style
 * @param {Object} linkProps? if it's a link use <Link />, else use <div /> = {
 * 		pathname?: string // page route to go to
 * 		param: {
 * 			chosenClassTitle?: string // specific className for title
 * 			category?: string // some type of category?
 * 		}
 * }
 * @param {String} img card image
 * @param {String} alt image description for screen readers
 * @param {Function} clickHandler click handler when card gets clicked
 */
export default function ClassCard(props) {
	const children = (
		<div className="card">
			<div className="img-container">
				<img className="img" src={props.img} alt={props.alt}></img>
			</div>

			<div className="contents">
				<div className="subject-name" style={props.titleStyle}>
					{props.title}
				</div>

				<div className="subject-description" style={props.descriptionStyle}>
					{props.description}
				</div>
			</div>
		</div>
	);

	return (
			props.linkProps
			?
			// <Link to={{
			// 	pathname: props.linkProps.pathname,
			// 	state: props.linkProps.params
			// }} className="class-card-container">
			// <Link to={props.linkProps.pathname} className="class-card-container">
			// 	{children}
			// </Link>
			<div className="class-card-container" tabIndex={0} onClick={() => props.clickHandler(props.linkProps.pathname, props.id)}
																									onKeyPress={() => props.clickHandler(props.linkProps.pathname, props.id)}>
				{children}
			</div>
			:
			<div className="class-card-container" tabIndex={0} onClick={props.clickHandler} onKeyPress={props.clickHandler}>
				{children}
			</div>
	);
}

ClassCard.propTypes = {
	titleStyle: PropTypes.object,
	descriptionStyle: PropTypes.object,

	img: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	title: PropTypes.string,
	description: PropTypes.string,

	linkProps: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
		params: PropTypes.shape({
			chosenClassTitle: PropTypes.string,
			category: PropTypes.string
		})
	})

};