import React from 'react';

import ClassCard from "templates/cards/ClassCard";

export function makeCards (subjectList, clickHandler, cardType) {
	let cardArray = [],
			textStyle;

	subjectList.forEach(subject => {
		const category = subject.category;
		let title;

		if (cardType === "subjectList") {
			title = `${category} Classes`;
		}
		else if (cardType === "classList") {
			title = subject.title;
			textStyle = {
				"fontSize": "1rem"
			};
		}

		let className = title.toLowerCase().replaceAll(" ", "-");
		const linkProps = {
			pathname: "/class-overview/" + className,
			params: {
				chosenClassTitle: subject.title,
				category: category
			}
		};

		cardArray.push(
			<ClassCard key={category} title={title} description={subject.description} 
				img={subject.img} alt={subject.alt}
				clickHandler={clickHandler}
				titleStyle={textStyle}
				linkProps={linkProps}
				id={subject.id} />
		);
	});

	return cardArray;
}